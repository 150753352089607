import { gql } from '@apollo/client';
import type { Hubble } from '@seek/hubble/lib/Hubble';
import { metrics } from '@seek/metrics-js';
import { matchRoutes, type LoaderFunction } from 'react-router';

import {
  RefineBarV2,
  StickySearchBar,
  REFINE_BAR_V2_FEATURE_FLAG,
} from 'src/config/experiments.ts';
import type { AnalyticsFacade } from 'src/modules/AnalyticsFacade';
import { setHubbleLoginId } from 'src/modules/hubble';
import { logger } from 'src/modules/logger';
import { jobDetailsPageRegex } from 'src/modules/routes-regexp';
import { parseCookies } from 'src/modules/safe-cookies';
import { isAuthenticated } from 'src/modules/seek-jobs-api-client/apis/candidate';
import { shouldUseSSA } from 'src/modules/server-side-auth/utils';
import appRoutesConfig from 'src/routes';
import type createStore from 'src/store/createStore';
import { locationChanged } from 'src/store/location';
import { clearNewSince } from 'src/store/results';
import {
  selectAuthenticated,
  selectServerAuthenticated,
  selectLocation,
  selectIsSrp,
  selectSolId,
} from 'src/store/selectors';
import { updateAuthenticated } from 'src/store/user';

import type { FeatureFlagFragment } from '../graphql/graphql.ts';

import { fetchDataForRoutes } from './fetchDataForRoutes';
import { routerRequestToUrlLocation } from './locationTransforms';

const ALLOWED_QUERY_KEYS = ['tags', 'jobId', 'type', 'pos', 'page'];

const fireAuthMetric = ({
  event,
  clientAuthenticated,
  serverAuthenticated,
}: {
  event: string;
  clientAuthenticated: boolean;
  serverAuthenticated: boolean;
}) => {
  metrics.count('auth.event', [
    `event:${event}`,
    `csa:${clientAuthenticated}`,
    `ssa:${serverAuthenticated}`,
  ]);
};

/** Returns an array of keys whose values changed between prevQuery and nextQuery */
function findChangedQueryParams(
  prevQuery: Record<string, string | undefined>,
  nextQuery: Record<string, string | undefined>,
): string[] {
  const allKeys = new Set([
    ...Object.keys(prevQuery || {}),
    ...Object.keys(nextQuery || {}),
  ]);
  const changedKeys: string[] = [];

  for (const key of allKeys) {
    if (prevQuery[key] !== nextQuery[key]) {
      changedKeys.push(key);
    }
  }
  return changedKeys;
}

/** Returns true if *every* changed key is in the ALLOWED_QUERY_KEYS list */
function onlyAllowedKeysChanged(changedKeys: string[]): boolean {
  return changedKeys.every((key) => ALLOWED_QUERY_KEYS.includes(key));
}

interface Options {
  analyticsFacade: AnalyticsFacade;
  store: ReturnType<typeof createStore>;
  apolloClient: Parameters<typeof fetchDataForRoutes>[0]['apolloClient'];
  visitorId: string;
  hubble: Hubble;
}

type LoaderFunctionContext = {
  initialLoad?: boolean;
};

// Used to get the Feature Flag from the cache
const FEATURE_FLAG_FRAGMENT = gql`
  fragment FeatureFlag on FeatureFlagEvaluation {
    value
  }
`;

export const createRouteLoaderFunction = (
  options: Options,
): LoaderFunction<LoaderFunctionContext> => {
  const { analyticsFacade, store, apolloClient, visitorId, hubble } = options;
  const { dispatch } = store;
  const loaderFunction: LoaderFunction<LoaderFunctionContext> = async ({
    request,
    context,
  }) => {
    metrics.count('pageload');
    const state = store.getState();
    const currentLocation = selectLocation(state);
    const isSRP = selectIsSrp(state);
    const nextLocation = routerRequestToUrlLocation(request);

    const featureFlag = apolloClient.cache.readFragment<FeatureFlagFragment>({
      id: `FeatureFlagEvaluation:${REFINE_BAR_V2_FEATURE_FLAG}`,
      fragment: FEATURE_FLAG_FRAGMENT,
    });

    const refineBarV2 = featureFlag?.value === RefineBarV2;

    const stickySearchBar = featureFlag?.value === StickySearchBar;

    const {
      pathname: currentPathname,
      query: {
        page: currentPageNumber,
        advertiserid: currentAdvertiserId,
      } = {},
    } = currentLocation;
    const {
      pathname: nextPathname,
      query: { page: nextPageNumber, advertiserid: nextAdvertiserId } = {},
    } = nextLocation;

    const isJobDetails = jobDetailsPageRegex.test(nextPathname!);

    const didPageChange =
      currentPathname !== nextPathname ||
      currentPageNumber !== nextPageNumber ||
      currentAdvertiserId !== nextAdvertiserId ||
      isJobDetails;

    const isHomepage = currentPathname === '/' || nextPathname === '/';
    if ((refineBarV2 || stickySearchBar) && isSRP && !isHomepage) {
      // If context?.initialLoad is true, this is most likely a refresh or external redirect so let the browser handle it
      // A refresh should scroll to the last scrollLocation, an external redirect should open from the top (SEEK header should be visible)
      if (!context?.initialLoad) {
        // If we're already on the SRP page and run a search, we should automatically scroll the page
        // so the SEEK header is not visible and the search bar is at the top
        const scrollIntoElement =
          document.querySelector('div[role="main"]') || window.document.body;
        scrollIntoElement.scrollIntoView();
      }
    } else if (didPageChange) {
      window.document.body.scrollIntoView();
    }

    /*
     * Compare the entire query objects to see if
     * *some* keys changed that are *not* in ALLOWED_QUERY_KEYS.
     * Also, if the pathname changed, always clear the stickiness
     */
    const changedKeys = findChangedQueryParams(
      currentLocation.query ?? {},
      nextLocation.query ?? {},
    );
    const pathnameChanged = currentPathname !== nextPathname;
    if (pathnameChanged) {
      // If the pathname changes, we lose stickiness in search
      dispatch(clearNewSince());
    } else if (changedKeys.length > 0 && !onlyAllowedKeysChanged(changedKeys)) {
      // If there are changed keys and any of them is disallowed, clear
      dispatch(clearNewSince());
    }

    // Continue with the usual route-matching logic
    const matchedRoutes = matchRoutes(
      appRoutesConfig(state.appConfig.site),
      nextPathname!,
    )?.map(({ route }) => route);
    const authenticated = selectAuthenticated(state);

    dispatch(locationChanged(nextLocation.href!, nextLocation));

    if (context?.initialLoad) {
      // Logging mismatch between cookie sol_id and client sol_id
      if (selectSolId(state) !== hubble.visitorId()) {
        metrics.count('solId.event', [`mismatch:true`]);
      }
      if (
        shouldUseSSA({
          cookies: parseCookies(),
          site: state.appConfig.site,
        })
      ) {
        const isServerAuthenticated = selectServerAuthenticated(state);
        const isClientAuthenticated = await isAuthenticated();
        fireAuthMetric({
          event: 'authMismatch',
          clientAuthenticated: isClientAuthenticated,
          serverAuthenticated: isServerAuthenticated,
        });
      }
    } else {
      /*
        If this handler is called during page load, we do not want
        to call the location changed analytics event as it will prematurely
        populate incorrect data layer properties (eg. previousSearchId).
        This is a stop gap solution in response to https://myseek.atlassian.net/browse/DCS-7030.

        We are using this short term solution as refactoring the boot sequence is a
        much bigger undertaking that requires a deeper investigation. An investigation ticket
        has been created here: https://myseek.atlassian.net/browse/DCS-7331
      */
      analyticsFacade.locationChanged({ pathname: nextPathname! });
    }

    const waitForAuth = async () => {
      if (!authenticated) {
        const resolvedAuthState = await isAuthenticated();
        fireAuthMetric({
          event: 'lastKnownSolIdNotSet',
          clientAuthenticated: resolvedAuthState,
          serverAuthenticated: false, // We know the user is not logged in on the server
        });
        const payload = { authenticated: resolvedAuthState };
        dispatch(updateAuthenticated(payload));
        analyticsFacade.userDetailsUpdated(payload);
        setHubbleLoginId(hubble, store);
        return resolvedAuthState;
      }
      return authenticated;
    };

    fetchDataForRoutes({
      analyticsFacade,
      matchedRoutes,
      location: nextLocation,
      dispatch,
      getState: store.getState,
      apolloClient,
      waitForAuth,
      visitorId,
    }).catch((err: Error) => {
      logger.error(err);
    });
  };

  return loaderFunction;
};
