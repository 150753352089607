import { useMelwaysLink } from '@seek/melways-react';
import { IconHistory } from 'braid-design-system';
import { stringify } from 'query-string';
import { forwardRef } from 'react';

import { useDispatch } from 'src/store/react';
import type { SearchQuerySummary } from 'src/store/recentSearches/types';
import { updateSearchSource } from 'src/store/results';

import { SavedRecentSearchItem } from '../SavedRecentSearchItem';

import { useRecentSearchFormatter } from './useRecentSearchFormatter';

export interface RecentSearchItemProps {
  recentSearch: SearchQuerySummary;
  index: number;
  setHighlightedSavedRecentItem: React.Dispatch<React.SetStateAction<number>>;
  highlightedSavedRecentItem: number;
}

export const RecentSearchItem = forwardRef<
  HTMLAnchorElement,
  RecentSearchItemProps
>(
  (
    {
      recentSearch,
      index,
      setHighlightedSavedRecentItem,
      highlightedSavedRecentItem,
    },
    ref,
  ) => {
    const dispatch = useDispatch();

    const recentSearchUrl = useMelwaysLink({
      path: `/jobs?${stringify(recentSearch.query)}`,
    });

    const searchItem = {
      label: useRecentSearchFormatter(recentSearch.query),
      href: recentSearchUrl,
    };

    return (
      <SavedRecentSearchItem
        searchItem={searchItem}
        icon={<IconHistory />}
        index={index}
        onClick={() => {
          // Update the search SOURCE for the JobSearch API/Smarter Search call so it's correctly set on the backend 'search_online_results_generated' tracking event
          dispatch(updateSearchSource('RECENT'));
        }}
        setHighlightedSavedRecentItem={setHighlightedSavedRecentItem}
        highlightedSavedRecentItem={highlightedSavedRecentItem}
        ref={ref}
      />
    );
  },
);
