import { useTranslations } from '@vocab/react';
import type { Store } from 'redux';

import type { ReplaceableKeyValues } from 'src/server/utils/titleOverride/type';
import type { ChaliceStore } from 'src/store/types';

import { appendSpacePrefix } from '../search-results-page/utils';

import translations from './.vocab';
import { getCountryTitle, useCountryTitle } from './country';
import {
  getLocationStateKeyValues,
  useLocationStateKeyValues,
} from './locationStateKeyValues';
import { getMonthYear, useMonthYear } from './monthYear';
import {
  getResultsStateKeyValues,
  useSearchResultsStateKeyValues,
} from './resultStateKeyValues';
import type { ReplaceableKeyValuesMode, VocabLocale } from './types';

export const useReplaceableKeyValues = ({
  mode,
}: {
  mode: ReplaceableKeyValuesMode;
}): ReplaceableKeyValues => {
  const { t } = useTranslations(translations);
  const country = useCountryTitle();
  const { month, fullMonth, year } = useMonthYear();
  const {
    brand,
    classDescription,
    commaFormattedClassDescription,
    jobCount,
    keywords,
    locationDescription,
    shortLocationName,
    workArrangementDescription,
    workTypeDescription,
  } = useSearchResultsStateKeyValues({ mode });
  const { pageNumberText } = useLocationStateKeyValues();
  const fallBackTitle = t('fallback-title', {
    country,
    month,
    year,
    pageNumberText,
    brand,
  });

  return {
    country,
    month,
    fullMonth,
    year,
    brand,
    classDescription,
    commaFormattedClassDescription,
    jobCount: jobCount.toString(),
    keywords,
    locationDescription,
    shortLocationName,
    workArrangementDescription,
    workTypeDescription,
    pageNumberText,
    fallBackTitle,
  };
};

export const getReplaceableKeyValues = async ({
  store,
}: {
  store: Store<ChaliceStore>;
}): Promise<ReplaceableKeyValues> => {
  const state = store.getState();
  const { locale } = state.appConfig;
  const messages = await translations.getMessages(locale as VocabLocale);
  const country = await getCountryTitle(locale as VocabLocale);
  const { month, fullMonth, year } = await getMonthYear(locale as VocabLocale);
  const {
    brand,
    classDescription,
    commaFormattedClassDescription,
    jobCount,
    keywords,
    locationDescription,
    shortLocationName,
    workArrangementDescription,
    workTypeDescription,
  } = await getResultsStateKeyValues({ store, mode: 'title' });
  const { pageNumberText: page } = await getLocationStateKeyValues({ store });
  const pageNumberText = appendSpacePrefix(
    parseInt(page, 10) > 1
      ? messages['page-number-text'].format({
          page,
        })
      : '',
  );
  const fallBackTitle = messages['fallback-title'].format({
    country,
    month,
    year,
    pageNumberText,
    brand,
  });

  return {
    country,
    month,
    fullMonth,
    year,
    brand,
    classDescription,
    commaFormattedClassDescription,
    jobCount: jobCount.toString(),
    keywords,
    locationDescription,
    shortLocationName,
    workArrangementDescription,
    workTypeDescription,
    pageNumberText,
    fallBackTitle,
  };
};
