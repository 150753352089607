import {
  ApolloLink,
  gql,
  type InMemoryCache,
  Observable,
} from '@apollo/client';
import { getOperationName } from '@apollo/client/utilities';

import type { ViewerFragment } from 'src/graphql/graphql';

import { CREATE_SAVED_JOB } from '../mutations/createSavedJob';
import { DELETE_SAVED_JOB } from '../mutations/deleteSavedJob';

const MUTATIONS_FIELD_NAME_MAP = {
  [getOperationName(CREATE_SAVED_JOB) || '']: 'savedJobs',
  [getOperationName(DELETE_SAVED_JOB) || '']: 'savedJobs',
};

// Used to get the viewer ID from the cache
const VIEWER_FRAGMENT = gql`
  fragment Viewer on Query {
    viewer {
      id
    }
  }
`;

/**
 * This custom ApolloLink is used to evict parts of the cache for certain mutations.
 */
export const makeLoggedInEvictCacheLink = (
  operationFieldNameMap: Record<string, string> = MUTATIONS_FIELD_NAME_MAP,
) =>
  new ApolloLink((operation, forward) => {
    const fieldName = operationFieldNameMap[operation.operationName];

    if (!fieldName || operation.getContext().disableEvictCache) {
      return forward(operation);
    }

    return new Observable((observer) => {
      const observable = forward(operation);
      const subscription = observable.subscribe({
        next(result) {
          try {
            if (!result.errors || result.errors.length <= 0) {
              const cache = operation.getContext().cache as InMemoryCache;
              const viewerData = cache.readFragment<ViewerFragment>({
                fragment: VIEWER_FRAGMENT,
              });

              if (viewerData?.viewer) {
                const id = cache.identify(viewerData.viewer);
                cache.evict({ id, fieldName });
                cache.gc();
              }
            }
          } catch {}

          observer.next(result);
        },
      });

      return () => subscription.unsubscribe();
    });
  });
