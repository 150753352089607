import type { SalaryType, SearchParams } from '@seek/chalice-types';
import {
  Box,
  RadioItem,
  RadioGroup,
  useResponsiveValue,
} from 'braid-design-system';
import { useCallback, useMemo, type FormEvent } from 'react';

import { Link } from 'src/components/NavLink/NavLink';
import { useDispatch } from 'src/store/react.ts';
import { useRunSearch } from 'src/store/search/useRunSearch';

import type { SalaryFields, SalaryRange } from '../../useSalaryRange';

import { salaryRangeParam } from './helper/salaryRangeParam';

import * as styles from '../SalaryField.css';

const ROBOTS_TAG = 'nofollow';
const DEFAULT_MAXIMUM_RANGE = '999';

export const SalaryFieldDropdown = ({
  salary,
  salarySelected,
  setSalarySelected,
  salaryType,
  setFilterSelection,
  activeDropdownField,
  setActiveDropdownField,
  filterSelection,
  handleSelection,
}: {
  salary: SalaryRange;
  salarySelected: SalaryFields;
  setSalarySelected: React.Dispatch<React.SetStateAction<SalaryFields>>;
  salaryType: SalaryType;
  setFilterSelection: React.Dispatch<React.SetStateAction<SearchParams>>;
  activeDropdownField: string | null;
  setActiveDropdownField: (field: string | null) => void;
  filterSelection: SearchParams;
  handleSelection: (value: string) => void;
}) => {
  const dispatch = useDispatch();
  const runSearch = useRunSearch();

  const isMobile =
    useResponsiveValue()({
      mobile: true,
      tablet: false,
    }) || false;

  const getInitialSalaryValueSelected = useCallback(
    (id: string) => {
      if (id === 'salaryFrom') {
        return salarySelected?.salaryFromValue || salary.ranges[0]?.value;
      }

      return salarySelected?.salaryToValue || DEFAULT_MAXIMUM_RANGE;
    },
    [salarySelected, salary.ranges],
  );

  const handleChange = useCallback(
    (e: FormEvent<HTMLInputElement>) => {
      const valueSelected = (e.target as HTMLInputElement).value;

      const selectedSalaryValue =
        valueSelected === DEFAULT_MAXIMUM_RANGE ? '' : valueSelected;

      const salaryField =
        salary.id === 'salaryFrom' ? 'salaryFromValue' : 'salaryToValue';

      const updatedSalarySelected = {
        ...salarySelected,
        [salaryField]: selectedSalaryValue,
      };

      const updatedFilterSelection = {
        ...filterSelection,
        salarytype: salaryType,
        salaryrange: salaryRangeParam({
          salaryRangeSelected: filterSelection?.salaryrange || '',
          salaryType: salary.id,
          value: selectedSalaryValue,
        }),
      };

      setSalarySelected(updatedSalarySelected);
      setFilterSelection(updatedFilterSelection);

      dispatch(
        runSearch({
          pathname: '/jobs',
          overrideQuery: updatedFilterSelection,
        }),
      );

      handleSelection(selectedSalaryValue);

      if (activeDropdownField === salary.id) {
        setActiveDropdownField(null);
      }
    },
    [
      salary.id,
      salarySelected,
      filterSelection,
      salaryType,
      setSalarySelected,
      setFilterSelection,
      dispatch,
      handleSelection,
      activeDropdownField,
      setActiveDropdownField,
      runSearch,
    ],
  );

  const radioItems = useMemo(
    () =>
      salary.ranges.map((option) => (
        <RadioItem
          disabled={!option.refineParams && !option.isActive}
          key={option.label}
          label={
            <Link
              location={{
                pathname: '/jobs',
                query: option.refineParams,
              }}
              rel={ROBOTS_TAG}
              disabledLinkNavigate
            >
              {option.label}
            </Link>
          }
          value={option.value || DEFAULT_MAXIMUM_RANGE}
        />
      )),
    [salary.ranges],
  );

  return (
    <Box
      data={{ automation: 'salaryFieldDropdown' }}
      display={activeDropdownField === salary.id ? 'flex' : 'none'}
      position="relative"
      zIndex="dropdown"
    >
      <Box
        position="absolute"
        background="surface"
        borderRadius="standard"
        padding="xsmall"
        width="full"
        boxShadow="medium"
        className={styles.dropDown}
      >
        <RadioGroup
          id={salary.id}
          onChange={handleChange}
          value={getInitialSalaryValueSelected(salary.id)}
          size={isMobile ? 'small' : 'standard'}
          aria-labelledby="salaryRange"
          data={{ automation: `refineSalaryRange${salary.label}` }}
        >
          {radioItems}
        </RadioGroup>
      </Box>
    </Box>
  );
};
