import type { SearchParams } from '@seek/chalice-types';
// @ts-expect-error non-ts file
import copy from 'shallow-copy';

const toArray = (string: any) => {
  if (!string) {
    return [];
  }
  let decodedString = '';
  try {
    decodedString = decodeURIComponent(string);
  } catch {}
  return decodedString.split(',');
};

export const includes = (
  current: SearchParams,
  key: keyof SearchParams,
  value: string,
) => toArray(current[key]).indexOf(value) > -1;

const add = (current: SearchParams, key: keyof SearchParams, value: string) => {
  const newParams = copy(current);

  const values = toArray(current[key]);
  values.push(value);
  newParams[key] = values.join(',');

  return newParams;
};

export const remove = (
  current: SearchParams,
  key: keyof SearchParams,
  valueToRemove: string | string[],
) => {
  const newParams = copy(current);
  const isArray = Array.isArray(valueToRemove);

  const values = toArray(current[key]);
  const filteredValues = values.filter((value) =>
    isArray ? valueToRemove.indexOf(value) === -1 : value !== valueToRemove,
  );

  newParams[key] = filteredValues.join(',');
  if (newParams[key].length === 0) {
    delete newParams[key];
  }

  return newParams;
};

export const toggle = (
  current: SearchParams,
  key: keyof SearchParams,
  value: string | string[],
) =>
  includes(current, key, value as string)
    ? remove(current, key, value)
    : add(current, key, value as string);
