import type { UrlLocation } from '@seek/chalice-types';
import {
  parse as parseQueryString,
  stringify as stringifyQueryString,
} from 'query-string';

export const routerRequestToUrlLocation = (request: Request): UrlLocation => {
  const { pathname, hostname, href, port, protocol, search } = new URL(
    request.url,
  );
  const hash = typeof window !== 'undefined' ? window.location.hash : '';

  return {
    hash,
    hostname,
    href,
    pathname,
    port,
    protocol,
    query: parseQueryString(search),
  };
};

export const urlLocationToRouterRequest = (location: UrlLocation): Request => {
  const { hash, pathname, hostname, port, protocol, query } = location;
  const search =
    query && Object.keys(query).length ? `?${stringifyQueryString(query)}` : '';
  const host = port ? `${hostname}:${port}` : hostname;
  // Ensure we don't double up on the hash
  const hashValue = hash ? `#${hash.replace('#', '')}` : '';

  return new Request(`${protocol}//${host}${pathname}${search}${hashValue}`);
};
