import { useMemo } from 'react';

import type { SavedSearchApacQuery } from 'src/graphql/graphql';
import useFetchSavedSearches from 'src/modules/hooks/SavedSearch';

import { MAX_SAVED_SEARCHES } from '../constants';

interface SavedSearchItem {
  id: string;
  name: string;
  countLabel: string;
  newToYouCountLabel?: string | null;
  query: SavedSearchApacQuery;
}

const resolveDisplayState = ({
  data,
  loading,
}: {
  data: SavedSearchItem[] | undefined | null;
  loading: boolean;
}): 'LOADING' | 'ERROR' | 'READY' => {
  if (loading) {
    return 'LOADING';
  }
  if (data === undefined || data === null) {
    return 'ERROR';
  }

  return 'READY';
};

export const formatSummary = (
  queryParams: SavedSearchApacQuery['parameters'],
): string => {
  const formattedSalary =
    queryParams.find((param) => param.type === 'salary')?.value ?? '';
  const formattedWorkType =
    queryParams.find((param) => param.type === 'filter.workType')?.value ?? '';
  const formattedWorkArrangement =
    queryParams.find((param) => param.type === 'workArrangement')?.value ?? '';

  return `${formattedSalary}${
    formattedWorkType && formattedSalary ? ', ' : ''
  }${formattedWorkType}${
    formattedWorkArrangement && (formattedSalary || formattedWorkType)
      ? ', '
      : ''
  }${formattedWorkArrangement}`;
};

const useSavedSearchesSearchBar = () => {
  const { savedSearches, loading } = useFetchSavedSearches();

  const totalSavedSearches = savedSearches?.length ?? 0;
  const trimmedSavedSearches = useMemo(
    () =>
      Boolean(savedSearches?.length)
        ? savedSearches!.slice(0, MAX_SAVED_SEARCHES)
        : savedSearches,
    [savedSearches],
  );
  const displayState = resolveDisplayState({
    data: trimmedSavedSearches,
    loading,
  });

  return {
    displayState,
    savedSearches: trimmedSavedSearches,
    totalSavedSearches,
    formatSummary,
  };
};

export default useSavedSearchesSearchBar;
