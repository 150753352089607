import type { Zone } from '@seek/audience-zones';
import type { Language, Locale } from '@seek/melways-sites';

import { useSelector } from 'src/store/react';
import {
  selectAlternateLinks,
  selectCompanySuggestion,
  selectSearchQuery,
} from 'src/store/selectors';
import { useLocalisedLinks, useMelwaysZone } from 'src/utils/melwaysHelper';

export interface HreflangLink {
  rel: 'alternate';
  href: string;
  hreflang: Language | Locale | 'x-default';
}

export const useLocalisedHrefLangLinks = () => {
  const zone = useMelwaysZone();
  const searchQuery = useSelector(selectSearchQuery);

  const alternateLinks = useSelector(selectAlternateLinks);
  const companySuggestion = useSelector(selectCompanySuggestion);

  const links = Object.entries(alternateLinks ?? []);

  const options = links.map(([language, link]) => ({
    path: link,
    language: (language as Language) ?? 'en',
    absolute: true,
  }));

  const qualifiedLinks = useLocalisedLinks(options);

  // SEO: EXCLUDE HREFLANG FOR:
  // TH(asia-3) and ID(asia-4) && non-company keyword searches
  const excludedZones: Zone[] = ['asia-3', 'asia-4'];

  const isKeywordSearch = searchQuery.keywords !== undefined;
  const isCompanySearch = companySuggestion !== undefined;

  if (excludedZones.includes(zone) && isKeywordSearch && !isCompanySearch) {
    return [];
  }

  return links.reduce<HreflangLink[]>((finalLinks, [lang], index) => {
    const language = lang as Language;
    const qualifiedLink = qualifiedLinks[index];

    const newAlternateLink: HreflangLink = {
      href: qualifiedLink,
      rel: 'alternate',
      hreflang: language,
    };
    const newAlternateLinks = [newAlternateLink];

    if (language === 'en') {
      newAlternateLinks.push({
        ...newAlternateLink,
        hreflang: 'x-default',
      });
    }

    return finalLinks.concat(newAlternateLinks);
  }, []);
};
