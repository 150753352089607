import { useTranslations } from '@vocab/react';
import { Box, Heading, HiddenVisually, Text } from 'braid-design-system';
import { useCallback, type ComponentProps, useState } from 'react';

import { TextLink } from 'src/components/NavLink/NavLink';

import translations from './.vocab';
import RefineChecklist from './RefineChecklist';

import * as styles from './Classifications.css';

interface ClassificationsProps {
  handleSelection: (value: string) => any;
  classifications: ComponentProps<typeof RefineChecklist>['options'];
  isCombinedFilter?: boolean;
}

const Classifications = (props: ClassificationsProps) => {
  const { t } = useTranslations(translations);
  const { handleSelection, classifications, isCombinedFilter } = props;
  const handleSelectionChange = useCallback(
    (option: { value: string }, isActive: boolean) => {
      if (isActive) {
        handleSelection(option.value);
      }
    },
    [handleSelection],
  );

  const [showAll, setShowAll] = useState(false);
  let displayClassifications;
  if (isCombinedFilter) {
    displayClassifications = showAll
      ? classifications
      : classifications?.slice(0, 4);
  } else {
    displayClassifications = classifications;
  }
  const expandableText = (
    <Text>
      <TextLink
        location=""
        onClick={() => setShowAll(!showAll)}
        disabledLinkNavigate
      >
        {showAll ? t('See less') : t('See all')}
      </TextLink>
    </Text>
  );

  return (
    <Box
      component="nav"
      role="navigation"
      aria-labelledby="RefineClassifications"
      overflow={isCombinedFilter ? 'hidden' : 'auto'}
      borderRadius="xlarge"
      paddingTop="small"
      className={
        isCombinedFilter
          ? styles.dropdownContainerCombined
          : styles.dropdownContainerSize
      }
    >
      <HiddenVisually>
        <Heading level="1" id="RefineClassifications">
          {t('Classification')}
        </Heading>
      </HiddenVisually>
      <RefineChecklist
        options={displayClassifications}
        path="/jobs"
        onSelectionChange={handleSelectionChange}
      />
      {isCombinedFilter && (
        <Box paddingTop="xsmall" paddingX="gutter">
          {expandableText}
        </Box>
      )}
    </Box>
  );
};

export default Classifications;
