import type { CountrySiteName } from '@seek/melways-sites';

import { isSSAEnabled } from 'src/config';
import type { CookieType } from 'src/types/cookie';

import { releasedSSASites, SERVER_SIDE_AUTH_COOKIE_NAME } from './constants';

export const shouldUseSSA = ({
  cookies,
  site = 'candidate-seek-au',
}: {
  cookies: CookieType;
  site?: CountrySiteName;
}) =>
  isSSAEnabled &&
  (cookies[SERVER_SIDE_AUTH_COOKIE_NAME] === 'true' ||
    releasedSSASites.includes(site));
