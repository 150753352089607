import { Box, PageBlock, Stack } from 'braid-design-system';
import { useEffect, useState } from 'react';
import { useInView } from 'react-intersection-observer';
import { Outlet, useLocation } from 'react-router';
// @ts-expect-error: non-ts file
import { provideHooks } from 'redial';

import { sendHotJarEvent } from 'src/hooks/useHotjarEvent';
import { isGooglebot } from 'src/modules/chalice-user-agent/googlebot.ts';
import type { TSeekExperiments } from 'src/store/experiments/types';
import {
  useFeatureFlagRefineBarV2Experiment,
  useSavedAndRecentSearchesFeatureFlag,
} from 'src/store/featureFlags/hooks.ts';
import { useSelector } from 'src/store/react';
import {
  getCounts,
  showPoeaCountryPicker,
  showPoeaCountryPickerFromNavigation,
  updateCriteria,
} from 'src/store/search';
import { selectPoeaCountryPicker } from 'src/store/search/selectors.ts';
import { selectIsFiltersExpanded } from 'src/store/selectors';
import type { RedialLocals } from 'src/types/RedialLocals';

import { isPoeaPathWithoutLocation } from '../Poea/utils.tsx';

import { DynamicSearchBar } from './DynamicSearchBar/DynamicSearchBar.tsx';
import RefineBar from './RefineBar/RefineBar';
import SearchBar from './SearchBar/SearchBar';

import * as styles from './Search.css';

const hooks = {
  first({ dispatch, path = '', query, zone }: RedialLocals) {
    return dispatch(
      updateCriteria({
        path,
        query,
        zone,
      }),
    );
  },
  defer({
    dispatch,
    country,
    path = '',
    query,
    cookies,
    zone,
    visitorId,
  }: RedialLocals) {
    return dispatch(
      getCounts({
        zone,
        country,
        path,
        query,
        cookies,
        solId: visitorId,
      }),
    );
  },

  pageLoad({ getState }: RedialLocals) {
    if (getState().search.poeaCountryPicker) {
      history.replaceState({}, '', '?countryPickerPage=true');
    }
  },

  fetch({
    dispatch,
    path = '',
    getState,
    referrer,
    analyticsFacade,
    zone,
  }: RedialLocals) {
    if (zone === 'asia-6') {
      const state = getState();
      const userAgent = state.user.userAgent;
      const urlHasCountryPickerQueryString = state.location?.url?.includes(
        'countryPickerPage=true',
      );
      const { poeaCountryPickerFromNavigation } = state.search;

      const isGoogleReferrerOrBot =
        referrer.includes('google') || isGooglebot(userAgent);

      const showCountryPicker =
        (isGoogleReferrerOrBot && isPoeaPathWithoutLocation(path)) ||
        urlHasCountryPickerQueryString ||
        poeaCountryPickerFromNavigation;

      if (showCountryPicker) {
        analyticsFacade.poeaCountryPickerPageLoaded({
          currentPage: 'country picker',
        });
        dispatch(showPoeaCountryPickerFromNavigation(false));
        return dispatch(showPoeaCountryPicker(true));
      }
      return dispatch(showPoeaCountryPicker(false));
    }
  },
};

export interface SearchProps {
  experiments?: TSeekExperiments;
}

/*
 * Only applicable for mobile web.
 *
 * This is to handle the state of the mobile expanded/collapsed view of the
 * search bar when the user is on the search page.
 *
 * Note: We should be able to clean this up once sticky search bar is fully rolled out.
 */
const useIsMobileExpandedView = () => {
  const location = useLocation();
  const [isMobileExpandedView, setIsMobileExpandedView] = useState(false);

  /*
   * Ensures that the search bar is always in collapsed state
   * when the user navigates to a new page, particularly SRP.
   */
  useEffect(() => {
    setIsMobileExpandedView(false);
  }, [location]);

  return { isMobileExpandedView, setIsMobileExpandedView };
};

const Search = () => {
  const filtersExpanded = useSelector(selectIsFiltersExpanded);
  const isCountryPicker = useSelector(selectPoeaCountryPicker);
  const [isSearchInView, setIsSearchInView] = useState(true);

  const showSavedAndRecentInSearch = useSavedAndRecentSearchesFeatureFlag();

  const { ref: searchInViewRef } = useInView({
    onChange: (inView) => {
      setIsSearchInView(() => inView);
    },
    initialInView: true,
  });
  const { dynamicPillsV2, stickySearchBar, refineBarV2 } =
    useFeatureFlagRefineBarV2Experiment();
  const { isMobileExpandedView, setIsMobileExpandedView } =
    useIsMobileExpandedView();

  useEffect(() => {
    if (stickySearchBar) sendHotJarEvent('stickySearch');
  }, [stickySearchBar]);

  useEffect(() => {
    if (dynamicPillsV2) {
      sendHotJarEvent('dynamicPillsV2');
    }
  }, [dynamicPillsV2]);

  useEffect(() => {
    if (refineBarV2) {
      sendHotJarEvent('refineBarV2');
    }
  }, [refineBarV2]);

  if (isCountryPicker) {
    return <Outlet />;
  }

  if (dynamicPillsV2 || stickySearchBar || refineBarV2) {
    return (
      <Box height="full">
        <DynamicSearchBar />
        <Outlet />
      </Box>
    );
  }

  return (
    <Box height="full">
      <Box
        position="relative"
        // This is to ensure this will not cover Header's language switcher
        zIndex={2}
        background="brand"
        paddingBottom={{
          mobile: 'medium',
          tablet: 'small',
        }}
        paddingX={{
          mobile: 'none',
          tablet: 'medium',
        }}
        className={{
          [styles.branding]: true,
          [styles.searchBarPadding]: true,
        }}
      >
        <PageBlock width={showSavedAndRecentInSearch ? 'large' : 'medium'}>
          <Box ref={searchInViewRef} data={{ automation: 'searchInView' }}>
            <Stack
              space={{
                mobile: filtersExpanded ? 'xsmall' : 'none',
                tablet: 'xsmall',
              }}
            >
              <SearchBar
                collapsed={filtersExpanded}
                isSearchInView={isSearchInView}
                isMobileExpandedView={isMobileExpandedView}
                setIsMobileExpandedView={setIsMobileExpandedView}
              />
              <RefineBar
                expanded={filtersExpanded}
                isSearchInView={isSearchInView}
                isMobileExpandedView={isMobileExpandedView}
              />
            </Stack>
          </Box>
        </PageBlock>
      </Box>
      <Outlet />
    </Box>
  );
};

export default provideHooks(hooks)(Search);
