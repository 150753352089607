import { Box, PageBlock, useResponsiveValue } from 'braid-design-system';
import debounce from 'lodash/debounce';
import { AnimatePresence, motion } from 'motion/react';
import {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
  type FormEventHandler,
} from 'react';
import { useLocation } from 'react-router';

import { getRefineSearchQuery } from 'src/components/Search/utils/utils';
import { useAnalyticsFacade } from 'src/modules/AnalyticsFacade';
import { useMetricsTimerContext } from 'src/modules/MetricsTimer/MetricsTimerContext';
import { isClientMobileWidth } from 'src/modules/responsive-helpers';
import { useFeatureFlagRefineBarV2Experiment } from 'src/store/featureFlags/hooks.ts';
import { useDispatch, useSelector } from 'src/store/react';
import {
  searchSubmit,
  whereSuggestionSelected,
  updateDynamicSearchBarType,
  updateDynamicSearchBarConfiguration,
} from 'src/store/search';
import { useRunSearch } from 'src/store/search/useRunSearch';
import {
  selectIsSrp,
  selectPrevPathname,
  selectResults,
  selectSearchQuery,
  selectWhereField,
} from 'src/store/selectors';

import { useSearchPath } from '../SearchBar/SearchBar';

import { ExpandedSearchBar } from './components/ExpandedSearchBar/ExpandedSearchBar';
import { MinimisedSearchBar } from './components/MinimisedSearchBar/MinimisedSearchBar';
import { MobileSearchBar } from './components/MobileSearchBar/MobileSearchBar';
import { HANDLE_SCROLL_TIMEOUT } from './constants';
import { useAnimationDuration } from './hooks/useAnimationDuration';

import * as styles from '../Search.css';

const useDynamicSearchBar = () => {
  const dispatch = useDispatch();
  const whereField = useSelector(selectWhereField);
  const searchQuery = getRefineSearchQuery(useSelector(selectSearchQuery));
  const metrics = useMetricsTimerContext();
  const analyticsFacade = useAnalyticsFacade();
  const { currentSearchPath, submitFormPath } = useSearchPath();
  const runSearch = useRunSearch();

  const immediateSearch = useCallback(
    (nextPathName: string = currentSearchPath) => {
      const action = runSearch({ pathname: nextPathName });
      dispatch(action);
    },
    [currentSearchPath, runSearch, dispatch],
  );

  const debouncedSearch = useMemo(
    () => debounce(immediateSearch, 100, { leading: true }),
    [immediateSearch],
  );

  const onWhereSuggestionSelected = useCallback(
    (payload: Parameters<typeof whereSuggestionSelected>[0]) => {
      dispatch(whereSuggestionSelected(payload));
      debouncedSearch();
    },
    [dispatch, debouncedSearch],
  );

  const onWhereInputBlur = useCallback(() => {
    const { where = '' } = searchQuery;

    if (whereField.trim() !== where.trim()) {
      debouncedSearch();
    }
  }, [debouncedSearch, searchQuery, whereField]);

  const onSubmit = useCallback<FormEventHandler>(
    (event) => {
      metrics.startTimer('SearchToJobListItem:Visible');
      metrics.startTimer('SearchToJobListItem:Visible:Control');
      event.preventDefault();

      analyticsFacade.searchFormSubmitted();

      // @ts-expect-error This was the original implementation!
      dispatch(searchSubmit());

      debouncedSearch.cancel();
      immediateSearch(submitFormPath);

      if (
        isClientMobileWidth() &&
        document.activeElement &&
        document.activeElement instanceof HTMLElement
      ) {
        // NOTE: this will trigger KeywordField.onKeywordBlur -> debounceSearch (which triggers after 1s)
        // so we need to cancel it for the scenario that we're navigating
        // from home to /jobs
        document.activeElement.blur();
        debouncedSearch.cancel();
      }
    },

    // metrics context does not need to be in the dependency array.
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      analyticsFacade,
      dispatch,
      debouncedSearch,
      immediateSearch,
      submitFormPath,
    ],
  );

  return {
    onWhereSuggestionSelected,
    onWhereInputBlur,
    onSubmit,
    debouncedSearch,
  };
};

export const DynamicSearchBar = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const prevPath = useSelector(selectPrevPathname);
  const [isMinSearchBar, setIsMinSearchBar] = useState(false);
  const [isSticky, setIsSticky] = useState(false);
  const [disableMinSearchBarTrigger, setDisableMinSearchBarTrigger] =
    useState(false);
  const isSRP = useSelector(selectIsSrp);
  const jobCardRef = useRef<HTMLElement | null>(null);
  const searchBarRef = useRef<HTMLElement | null>(null);
  const dynamicSearchBarRef = useRef<HTMLElement | null>(null);
  const animationTransition = useAnimationDuration();
  const isMobile = useResponsiveValue()({
    mobile: true,
    tablet: false,
  });
  const [
    searchBarAndFirstJobCardIntersectionHeight,
    setSearchBarAndFirstJobCardIntersectionHeight,
  ] = useState(0);
  const jobs = useSelector(selectResults)?.jobs;
  const {
    onWhereSuggestionSelected,
    onWhereInputBlur,
    onSubmit,
    debouncedSearch,
  } = useDynamicSearchBar();

  const { stickySearchBar, refineBarV2 } =
    useFeatureFlagRefineBarV2Experiment();

  const handleOnClickMinSearchBarPlaceholder = () => {
    setDisableMinSearchBarTrigger(true);
    setIsMinSearchBar(false);

    setTimeout(() => {
      setDisableMinSearchBarTrigger(false);
    }, HANDLE_SCROLL_TIMEOUT);
  };

  useEffect(() => {
    if (!stickySearchBar && !refineBarV2) {
      return;
    }

    // Transition from HomePage to SRP,
    // searchBar is scroll to the top and thus,
    // header is not visible
    if (prevPath === '/' && dynamicSearchBarRef) {
      dynamicSearchBarRef?.current?.scrollIntoView({
        behavior: 'smooth',
      });
      setIsMinSearchBar(false);
      setIsSticky(true);
      return;
    }

    if (isSRP && dynamicSearchBarRef.current) {
      const searchBarTop =
        dynamicSearchBarRef.current.getBoundingClientRect().top;

      if (searchBarTop <= 0) {
        setIsSticky(true);
      } else {
        setIsSticky(false);
      }
    }
  }, [isSRP, location, prevPath, stickySearchBar, refineBarV2]);

  useEffect(() => {
    dispatch(
      updateDynamicSearchBarConfiguration(isSticky ? 'STICKY' : 'CLASSIC'),
    );
  }, [isSticky, dispatch]);

  useEffect(() => {
    if (isSRP && jobs) {
      const firstJobCardElement = ENV.CLIENT
        ? document.getElementById('jobcard-1')
        : null;

      jobCardRef.current = firstJobCardElement;

      const handleScroll = () => {
        if (jobCardRef.current && dynamicSearchBarRef.current) {
          const jobCardTop = Math.floor(
            jobCardRef.current?.getBoundingClientRect()?.top,
          );
          const searchBarBottom = Math.floor(
            dynamicSearchBarRef.current?.getBoundingClientRect()?.bottom,
          );
          const searchBarTop = Math.floor(
            dynamicSearchBarRef.current?.getBoundingClientRect()?.top,
          );

          if (!jobCardTop || !searchBarBottom) {
            // The only time when either of these are 0 is when the page hasn't fully loaded so do nothing
            return;
          }

          const isFirstJobCardSearchbarIntersection =
            jobCardTop <= searchBarBottom;

          if (jobCardTop === searchBarBottom) {
            setSearchBarAndFirstJobCardIntersectionHeight(jobCardTop);
          }

          if (isFirstJobCardSearchbarIntersection) {
            if (!isMinSearchBar) {
              if (searchBarAndFirstJobCardIntersectionHeight >= jobCardTop) {
                setIsMinSearchBar(true);

                dispatch(updateDynamicSearchBarType('minimised'));
              }
            }
          }

          if (!isFirstJobCardSearchbarIntersection) {
            if (isMinSearchBar && searchBarTop > 0) {
              // If the search bar is already minimised at the top of the page, a new search was initialised from the minimised state
              // so we should leave it minimised
              if (searchBarAndFirstJobCardIntersectionHeight <= jobCardTop) {
                setIsMinSearchBar(false);

                dispatch(updateDynamicSearchBarType('expanded'));
              }
            }
          }

          if (searchBarTop <= 0) {
            setIsSticky(true);
          } else {
            setIsSticky(false);
          }
        }
      };

      // Disable scroll event listener when disableMinSearchBarTrigger is true
      if (disableMinSearchBarTrigger) {
        window.removeEventListener('scroll', handleScroll);
      } else {
        window.addEventListener('scroll', handleScroll);
      }

      return () => {
        window.removeEventListener('scroll', handleScroll);
      };
    }
  }, [
    searchBarAndFirstJobCardIntersectionHeight,
    isMinSearchBar,
    isSRP,
    isMobile,
    jobs,
    disableMinSearchBarTrigger,
    dispatch,
  ]);

  const stickySearchBarID = useMemo(() => {
    if (isMobile) {
      return 'mobileStickySearch';
    } else if (isMinSearchBar) {
      return 'minimisedStickySearch';
    }

    return 'expandedStickySearch';
  }, [isMobile, isMinSearchBar]);

  return (
    <Box
      className={{
        [styles.stickySearchBar]: isSRP && (stickySearchBar || refineBarV2),
      }}
      ref={searchBarRef}
      id="dynamicSearchBar"
      data={{ automation: 'dynamicSearchBar' }}
    >
      <AnimatePresence initial={false}>
        <form
          aria-labelledby="PerformASearch"
          role="search"
          name="SearchBar"
          id="SearchBar"
          method="get"
          action="/jobs"
          onSubmit={onSubmit}
        >
          <motion.div layout transition={{ duration: animationTransition }}>
            <Box
              ref={dynamicSearchBarRef}
              // This is to ensure this will not cover Header's language switcher
              zIndex={2}
              background="brand"
              paddingTop={{
                mobile: 'xsmall',
                tablet: isMinSearchBar ? 'none' : 'large',
              }}
              paddingBottom={{
                mobile: 'xsmall',
                tablet: isMinSearchBar ? 'none' : 'small',
              }}
              paddingLeft={{
                tablet: isMinSearchBar ? 'none' : 'small',
              }}
              paddingRight={{
                tablet: isMinSearchBar ? 'none' : 'small',
              }}
              id={stickySearchBarID}
            >
              <PageBlock width="large">
                {isMobile ? (
                  <MobileSearchBar
                    onWhereSuggestionSelected={onWhereSuggestionSelected}
                    onWhereInputBlur={onWhereInputBlur}
                    debouncedSearch={debouncedSearch}
                  />
                ) : (
                  <>
                    {!isMinSearchBar ? (
                      <ExpandedSearchBar
                        onWhereSuggestionSelected={onWhereSuggestionSelected}
                        onWhereInputBlur={onWhereInputBlur}
                        debouncedSearch={debouncedSearch}
                      />
                    ) : (
                      <MinimisedSearchBar
                        handleOnClickMinSearchBarPlaceholder={
                          handleOnClickMinSearchBarPlaceholder
                        }
                      />
                    )}
                  </>
                )}
              </PageBlock>
            </Box>
          </motion.div>
          <button
            type="submit"
            style={{ display: 'none' }}
            aria-hidden="true"
          />
        </form>
      </AnimatePresence>
    </Box>
  );
};
