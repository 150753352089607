import { AxiosError } from 'axios';
import cookie from 'js-cookie';
import findLast from 'lodash/findLast';
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { v4 } from 'uuid';

import type { RecommendedJobsChatContextInput } from 'src/graphql/graphql';
import { useCandidateDetails } from 'src/modules/hooks/useCandidateDetailsQuery';
import { logger } from 'src/modules/logger';

import {
  createSession,
  sendFeedback,
  sendMessage,
} from '../api/careerFeedAssistantApi';
import type {
  CareerFeedMessage,
  CareerFeedTextMessage,
  ErrorType,
  ProgressType,
} from '../types';

import { useCareerFeedTracking } from './useCareerFeedAITracking';

type CareerFeedAIAssistantContextType = {
  messages: CareerFeedMessage[];
  sessionId: string | null;
  candidateChatContext: RecommendedJobsChatContextInput | null;
  persistedCandidateChatContext: RecommendedJobsChatContextInput | null;
  invokeCreateSession: (jobIds: string[]) => void;
  invokeSendMessage: (
    message: string,
    skip: boolean,
  ) => Promise<ErrorType | null>;
  invokeSendFeedback: (sentiment: boolean) => void;
  isCreateSessionLoading: boolean;
  createSessionError: ErrorType | null;
  sendMessageError: ErrorType | null;
  lastCompletedAssistantResponse: CareerFeedTextMessage | undefined;
  isAssistantResponsePending: boolean;
  persistCandidateChatContext: () => void;
  clearCandidateChatContext: () => void;
  reset: () => void;
  progress: ProgressType;
  tracking: ReturnType<typeof useCareerFeedTracking>;
  sequence: number;
};

const CareerFeedAIAssistantContext = createContext<
  CareerFeedAIAssistantContextType | undefined
>(undefined);

const getErrorType = (e: AxiosError | Error | unknown): ErrorType => {
  if (e instanceof AxiosError) {
    if (
      e.status === 400 &&
      e.response?.data?.errorType === 'INPUT_OR_OUTPUT_BLOCKED'
    ) {
      return 'INPUT_OR_OUTPUT_BLOCKED';
    }

    if (e.status === 429) {
      return 'TOO_MANY_REQUESTS';
    }
  }

  return 'GENERIC_ERROR';
};

const CANDIDATE_CHAT_CONTEXT_COOKIE_NAME = 'candidateChatContext';

type CandidateChatContextFromCookieType = {
  candidateChatContext: CareerFeedAIAssistantContextType['candidateChatContext'];
  candidateId: string;
};
const useCandidateChatContextFromCookie = () => {
  const { id: currentCandidateId } = useCandidateDetails();

  const getValue = useCallback(
    ({
      candidateId,
      clear: clearFn,
    }: {
      candidateId: number | undefined;
      clear: () => void;
    }) => {
      const candidateChatContextString = cookie.get(
        CANDIDATE_CHAT_CONTEXT_COOKIE_NAME,
      );

      if (!candidateChatContextString) {
        return null;
      }

      try {
        const candidateChatContext = JSON.parse(
          candidateChatContextString,
        ) as CandidateChatContextFromCookieType;

        if (
          // We assume the same candidate if the candidateId is not ready
          !candidateId ||
          candidateChatContext.candidateId === String(candidateId)
        ) {
          return candidateChatContext;
        }

        clearFn();
        return null;
      } catch (e) {
        logger.error(
          {
            err: e as Error,
          },
          'GenAI: Error occurred while reading the candidate chat context from the cookie',
        );
        return null;
      }
    },
    [],
  );

  const clear = useCallback(() => {
    cookie.remove(CANDIDATE_CHAT_CONTEXT_COOKIE_NAME);

    setData(null);
  }, []);

  const [data, setData] = useState<CandidateChatContextFromCookieType | null>(
    getValue({ candidateId: currentCandidateId, clear }),
  );

  const set = useCallback(
    (value: RecommendedJobsChatContextInput) => {
      const newData: CandidateChatContextFromCookieType = {
        candidateChatContext: { ...value, timestamp: new Date().toISOString() },
        candidateId: String(currentCandidateId),
      };

      cookie.set(CANDIDATE_CHAT_CONTEXT_COOKIE_NAME, JSON.stringify(newData), {
        expires: 7,
      });

      setData(newData);
    },
    [currentCandidateId],
  );

  return { data, set, clear };
};

export const CareerFeedAIAssistantProvider: React.FC<{
  children: React.ReactNode;
  useAltModel: boolean;
}> = ({ children, useAltModel }) => {
  const [messages, setMessages] = useState<CareerFeedMessage[]>([]);
  const [sequence, setSequence] = useState<number>(0);
  const [sessionId, setSessionId] = useState<string | null>(null);
  const [isCreateSessionLoading, setCreateSessionLoading] = useState(false);
  const [createSessionError, setCreateSessionError] =
    useState<ErrorType | null>(null);
  const [sendMessageError, setSendMessageError] = useState<ErrorType | null>(
    null,
  );
  const [conversationFinished, setConversationFinished] = useState(false);
  const [collectGeneralRequirements, setCollectGeneralRequirements] =
    useState(false);
  const [candidateChatContext, setCandidateChatContext] =
    useState<RecommendedJobsChatContextInput | null>(null);
  const persistedCandidateChatContext = useCandidateChatContextFromCookie();
  const [currentSentiment, setCurrentSentiment] = useState<boolean | null>(
    null,
  );

  const lastCompletedAssistantResponse = useMemo(
    () =>
      findLast(
        messages,
        (message): message is CareerFeedTextMessage =>
          message.role === 'assistant' && !message.loading,
      ),
    [messages],
  );

  const isAssistantResponsePending = useMemo(
    () =>
      findLast(messages, (message) => message.role === 'assistant')?.loading ||
      false,
    [messages],
  );

  useEffect(() => {
    setCurrentSentiment(null);
  }, [sequence]);

  const reset = useCallback(() => {
    setMessages([]);
    setSessionId(null);
    setCreateSessionLoading(false);
    setCreateSessionError(null);
    setSendMessageError(null);
    setConversationFinished(false);
    setCandidateChatContext(null);
    setSequence(0);
  }, []);

  const progress = useMemo<ProgressType>(() => {
    // Since on the initial state, after user starts the conversation, we will push the first request to the `messages[]`, and wait for the `sessionId` to be set.
    /**
     * Scenario                  | sessionId | messages.length | expected progress
     * --------------------------|-----------|-----------------|-------------------
     * initial                   | null      | 0               | "initial"
     * starting the conversation | null      | 1               | "initial"
     * gathering refinement      | true      | 1               | "gatheringRefinement"
     */
    if (sessionId && messages.length) {
      if (collectGeneralRequirements) {
        return 'collectGeneralRequirementsAtLast';
      }

      if (conversationFinished) {
        return 'recsRefined';
      }

      return 'gatheringRefinement';
    }

    if (!sessionId && persistedCandidateChatContext?.data) {
      return 'recsRefined-withPersistedData';
    }

    // aka !sessionId
    return 'initial';
  }, [
    collectGeneralRequirements,
    conversationFinished,
    messages.length,
    persistedCandidateChatContext?.data,
    sessionId,
  ]);

  const tracking = useCareerFeedTracking({
    sessionId,
    currentMessage: lastCompletedAssistantResponse,
    sequence,
    currentSentiment,
    progress,
  });

  const invokeCreateSession = useCallback(
    async (jobIds: string[]) => {
      setCreateSessionError(null);

      if (!isCreateSessionLoading) {
        const messageId = v4();
        setMessages((prevMessages) => [
          ...prevMessages,
          { messageId, role: 'assistant', loading: true },
        ]);

        try {
          setCreateSessionLoading(true);
          const result = await createSession(
            {
              jobIds,
            },
            useAltModel,
          );

          setSessionId(result.sessionId);
          setMessages((prevMessages) =>
            prevMessages.map((prevMessage) => {
              if (prevMessage.messageId === messageId) {
                return {
                  ...prevMessage,
                  loading: false,
                  text: result.message,
                  suggestions: result.suggestions,
                };
              }

              return prevMessage;
            }),
          );
          setSequence((prevSequence) => prevSequence + 1);
        } catch (e) {
          logger.error(
            {
              err: e as Error,
            },
            'GenAI: Error occurred while creating the session',
          );
          setCreateSessionError(getErrorType(e));
          setMessages((prevMessages) =>
            prevMessages.filter(
              (prevMessage) => prevMessage.messageId !== messageId,
            ),
          );
        }

        setCreateSessionLoading(false);
      }
    },
    [isCreateSessionLoading, useAltModel],
  );

  const invokeSendMessage = useCallback(
    async (message: string, skip: boolean): Promise<ErrorType | null> => {
      if (!sessionId) {
        return null;
      }

      setSendMessageError(null);
      const assistantMessageId = v4();
      const userMessageId = v4();

      setMessages((prevMessages) => [
        ...prevMessages,
        {
          messageId: userMessageId,
          role: 'user',
          loading: false,
          text: message,
        },
        {
          messageId: assistantMessageId,
          role: 'assistant',
          loading: true,
        },
      ]);

      try {
        const result = await sendMessage(sessionId, message, useAltModel, skip);
        setMessages((prevMessages) =>
          prevMessages.map((prevMessage) => {
            if (prevMessage.messageId === assistantMessageId) {
              return {
                ...prevMessage,
                loading: false,
                text: result.message,
                suggestions: result.suggestions,
                collectGeneralRequirements: result.collectGeneralRequirements,
              };
            }

            return prevMessage;
          }),
        );
        setSequence((prevSequence) => prevSequence + 1);
        setCollectGeneralRequirements(
          Boolean(result.collectGeneralRequirements),
        );
        setConversationFinished(result.conversationFinished);
        setCandidateChatContext(result.candidateChatContext ?? null);
        return null;
      } catch (e) {
        logger.error(
          {
            err: e as Error,
          },
          'GenAI: Error occurred while sending the message',
        );
        const errorType = getErrorType(e);
        setSendMessageError(errorType);
        setMessages((prevMessages) =>
          prevMessages.filter(
            (prevMessage) =>
              prevMessage.messageId !== assistantMessageId &&
              prevMessage.messageId !== userMessageId,
          ),
        );

        return errorType;
      }
    },
    [sessionId, useAltModel],
  );

  const invokeSendFeedback = useCallback(
    async (sentiment: boolean) => {
      if (!sessionId) {
        return;
      }

      try {
        setCurrentSentiment(sentiment);
        await sendFeedback(sessionId, sentiment);
      } catch (e) {
        logger.error(
          {
            err: e as Error,
          },
          'GenAI: Error occurred while sending feedback',
        );
      }
    },
    [sessionId],
  );

  return (
    <CareerFeedAIAssistantContext.Provider
      value={{
        messages,
        sessionId,
        invokeCreateSession,
        invokeSendMessage,
        invokeSendFeedback,
        candidateChatContext,
        isCreateSessionLoading,
        createSessionError,
        sendMessageError,
        lastCompletedAssistantResponse,
        isAssistantResponsePending,
        persistCandidateChatContext: () =>
          persistedCandidateChatContext.set(candidateChatContext!),
        clearCandidateChatContext: persistedCandidateChatContext.clear,
        persistedCandidateChatContext:
          persistedCandidateChatContext.data?.candidateChatContext || null,
        reset,
        progress,
        tracking,
        sequence,
      }}
    >
      {children}
    </CareerFeedAIAssistantContext.Provider>
  );
};

export const useCareerFeedAIAssistant = () => {
  const context = useContext(CareerFeedAIAssistantContext);
  if (!context) {
    throw new Error(
      'useCareerFeedAIAssistant must be used within a CareerFeedAIAssistantProvider',
    );
  }
  return context;
};
