import type { Language } from '@seek/melways-sites';

import type { AppConfig } from 'src/config/types';

export const SET_LANGUAGE = 'SET_LANGUAGE';

export type AppConfigState = Pick<
  AppConfig,
  'brand' | 'site' | 'language' | 'locale' | 'country' | 'zoneFeatures' | 'zone'
>;

export type AppConfigAction = SetLanguageAction;

export interface SetLanguageAction {
  type: typeof SET_LANGUAGE;
  payload: {
    language: Language;
  };
}
