import type {
  Description,
  SearchParams,
  SearchResultV5,
  SearchResultSortMode,
  Facets,
  Pills,
  SearchResultLocationSuggestionV5,
  SearchResultRelatedSearch,
  SearchResultLocationV5,
  SearchResultJobV5,
} from '@seek/chalice-types';
import type { SolMetadata } from '@seek/sol-js';

import type { LoggedOutRecommendation } from 'src/modules/hooks/useLoggedOutRecommendations';
import type {
  SearchViewModelHeader,
  SearchViewModelSuggestions,
} from 'src/types/globals';

import type { Meta } from '../meta';
import type { SUBMIT_SEARCH } from '../search/types';

export const MORE_OPTIONS = 'MORE_OPTIONS';
export const GET_RESULTS_BEGIN = 'GET_RESULTS_BEGIN';
export const CLEAR_RESULTS = 'CLEAR_RESULTS';
export const CLEAR_RESULTS_MOBILE_VIEW = 'CLEAR_RESULTS_MOBILE_VIEW';
export const CLEAR_NEW_SINCE = 'CLEAR_NEW_SINCE';
export const GET_RESULTS_SUCCESS_V5 = 'GET_RESULTS_SUCCESS_V5';
export const CLEAR_DYNAMIC_PILLS = 'CLEAR_DYNAMIC_PILLS';
export const GET_RESULTS_ERROR = 'GET_RESULTS_ERROR';
export const GET_RESULTS_ERROR_CANCELLATION = 'GET_RESULTS_ERROR_CANCELLATION';
export const FETCH_SEARCH_RESULTS_ERROR = 'FETCH_SEARCH_RESULTS_ERROR';
export const RESULT_LINK_CLICKED = 'RESULT_LINK_CLICKED';
export const JORA_RESULT_LINK_CLICKED = 'JORA_RESULT_LINK_CLICKED';
export const EMPTY_RELATED_SEARCH: RelatedSearch[] = [];
export const SAVE_SOL_REFERENCES = 'SAVE_SOL_REFERENCES';
export const SAVE_SOL_REFERENCES_FOR_RECS = 'SAVE_SOL_REFERENCES_FOR_RECS';
export const SAVE_SOL_REFERENCES_FOR_LOGGED_OUT_HOME_RECS =
  'SAVE_SOL_REFERENCES_FOR_LOGGED_OUT_HOME_RECS';
export const SAVE_SOL_REFERENCES_FOR_LOGGED_IN_HOME_RECS =
  'SAVE_SOL_REFERENCES_FOR_LOGGED_IN_HOME_RECS';
export const SET_SELECTED_JOB_ID = 'SET_SELECTED_JOB_ID';
export const SET_TITLE_TAG_SHORT_LOCATION_NAME =
  'SET_TITLE_TAG_SHORT_LOCATION_NAME';
export const SET_TITLE_TAG_TEST_TITLE = 'SET_TITLE_TAG_TEST_TITLE';
export const UPDATE_SEARCH_SOURCE = 'UPDATE_SEARCH_SOURCE';

export interface SearchViewModelSummary {
  displayTotalCount: string;
  text: string;
}

export interface SearchViewModelCompanySuggestion {
  search: {
    keywords?: string;
    companyName?: string;
  };
  count: number;
}

interface Jobs {
  jobs: SearchResultJobV5[] | null;
}

export interface SearchViewModel extends Jobs {
  searchParams: SearchParams;
  isLoading: boolean;
  isZeroResults: boolean;
  summary: SearchViewModelSummary | null;
  header: SearchViewModelHeader | null;
  locationSuggestions: SearchViewModelSuggestions | null;
  companySuggestion?: SearchViewModelCompanySuggestion;
  canonicalCompany?: Description;
  hasHeaderBeforeJobs: boolean;
  pills?: Pills;
  isRelatedSearch?: boolean;
}

export interface RelatedSearch {
  keywords: string;
  totalJobs: number;
  type?: string;
}

export interface FacetType {
  id: string;
  count: number;
  label?: string;
}

export type SolReferenceKeyLookup = {
  hash: string;
  // Extending this type to include displayType for logged out recs since this is only to be use inside of Chalice
  isFeaturedOrDisplayKeyType:
    | SearchResultJobV5['isFeatured']
    | 'loggedOutHomeRecs'
    | 'loggedInHomeRecs'
    | 'competitivePlacement';
} & Pick<SearchResultJobV5, 'id'>;

export interface ResultsState {
  results: SearchViewModel | null;
  isLoading: boolean;
  isError: boolean;
  totalPages: number;
  source?: string;
  canonicalCompany?: Description;
  companySuggestion?: SearchViewModelCompanySuggestion;
  location?: SearchResultLocationV5;
  locationWhere: string | null;
  joraCrossLink?: any;
  newSince?: number;
  title: string;
  totalCount: number | null;
  totalCountNewToYou: number | null;
  jobIds: string[];
  selectedJobId?: string;
  lastPage?: number;
  sortMode: SearchResultSortMode[] | null;
  hidden: boolean;
  searchSource?: string;
  relatedSearches: RelatedSearch[];
  userQueryId?: string;
  uniqueSearchToken?: string;
  solMetadata?: {
    jobId: number;
    token: string;
    tags: { ['mordor__flights']: string };
    requestToken?: string;
    locationDistance?: number;
  };
  solMetadataString?: string;
  solReferenceKeys: SolReferenceKeyLookup[];
  isRadialFilterShown?: boolean;
  isRadialFilterNudgeShown?: boolean;
  facets?: Facets;
  pills?: Pills;
  titleTagShortLocationName?: string;
  titleTagTestTitle?: string;
  gptTargeting?: SearchResultV5['gptTargeting'];
}

interface GetResultsSuccessAction {
  payload: {
    query: SearchParams;
    currentPageNumber?: number;
    userQueryId: any;
  };
}

export interface GetResultsSuccessActionV5 extends GetResultsSuccessAction {
  type: typeof GET_RESULTS_SUCCESS_V5;
  payload: GetResultsSuccessAction['payload'] & {
    result: Partial<SearchResultV5>;
    locationSuggestions: SearchResultLocationSuggestionV5[];
    relatedSearches: SearchResultRelatedSearch[];
  };
}

export interface SaveSolReferencesAction {
  type: typeof SAVE_SOL_REFERENCES;
}

export interface SaveSolReferencesForLoggedOutRecsAction {
  type: typeof SAVE_SOL_REFERENCES_FOR_LOGGED_OUT_HOME_RECS;
  payload: { recommendations: LoggedOutRecommendation[] };
}

export interface SaveSolReferencesForRecsAction {
  type: typeof SAVE_SOL_REFERENCES_FOR_RECS;
  payload: {
    recommendations: Array<{
      job: { id: string };
      solMetadata: SolMetadata;
    }>;
  };
}

export interface SaveSolReferencesForLoggedInRecsAction {
  type: typeof SAVE_SOL_REFERENCES_FOR_LOGGED_IN_HOME_RECS;
  payload: {
    recommendations: Array<{
      job: { id: string };
      solMetadata: SolMetadata;
    }>;
  };
}

export interface SetSelectedJobIdAction {
  type: typeof SET_SELECTED_JOB_ID;
  payload: {
    jobId?: string;
  };
}

export interface SetTitleTagShortLocationName {
  type: typeof SET_TITLE_TAG_SHORT_LOCATION_NAME;
  payload: {
    titleTagShortLocationName: string;
  };
}

export interface SetTitleTagTestTitle {
  type: typeof SET_TITLE_TAG_TEST_TITLE;
  payload: {
    titleTagTestTitle: string;
  };
}

export interface UpdateisRelatedSearchAction {
  type: typeof UPDATE_SEARCH_SOURCE;
  payload: {
    searchSource?: string;
  };
}

export type ResultsAction =
  | {
      type: typeof CLEAR_NEW_SINCE;
    }
  | {
      type: typeof CLEAR_RESULTS;
      payload?: Record<string, unknown>;
    }
  | {
      type: typeof CLEAR_RESULTS_MOBILE_VIEW;
      payload?: Record<string, unknown>;
    }
  | GetResultsSuccessActionV5
  | {
      type: typeof CLEAR_DYNAMIC_PILLS;
    }
  | {
      type: typeof GET_RESULTS_BEGIN;
      payload: {
        query: SearchParams;
      };
    }
  | {
      type: typeof GET_RESULTS_ERROR;
      payload?: Record<string, unknown>;
    }
  | {
      type: typeof SUBMIT_SEARCH;
      payload?: Record<string, unknown>;
    }
  | {
      type: typeof MORE_OPTIONS;
      payload?: Record<string, unknown>;
      meta: Meta;
    }
  | {
      type: typeof RESULT_LINK_CLICKED;
    }
  | {
      type: typeof FETCH_SEARCH_RESULTS_ERROR;
    }
  | {
      type: typeof GET_RESULTS_ERROR_CANCELLATION;
    }
  | {
      type: typeof JORA_RESULT_LINK_CLICKED;
    }
  | SaveSolReferencesAction
  | SaveSolReferencesForLoggedOutRecsAction
  | SaveSolReferencesForLoggedInRecsAction
  | SaveSolReferencesForRecsAction
  | SetSelectedJobIdAction
  | SetTitleTagShortLocationName
  | SetTitleTagTestTitle
  | UpdateisRelatedSearchAction;
