import type { ApacCandidateHeader } from '@seek/apac-candidate-header-footer';
import { AUTHENTICATION_TYPES } from '@seek/apac-candidate-header-footer/types';
import type { ComponentProps } from 'react';

import useAuthenticated from 'src/hooks/useAuthenticated';
import { useCandidateDetails } from 'src/modules/hooks/useCandidateDetailsQuery';
import { useSetLanguage } from 'src/store/appConfig/hooks';
import { useSelector } from 'src/store/react';
import { selectIsJDP, selectUrl } from 'src/store/selectors';

import { useLanguageSwitcher } from './useLanguageSwitcher';

const useAuthenticationStatus = (): ComponentProps<
  typeof ApacCandidateHeader
>['authenticationStatus'] => {
  const authenticateState = useAuthenticated();

  switch (authenticateState) {
    case 'authenticated':
      return AUTHENTICATION_TYPES.AUTHENTICATED;
    case 'unauthenticated':
      return AUTHENTICATION_TYPES.UNAUTHENTICATED;
    case 'pending':
      return AUTHENTICATION_TYPES.AUTH_PENDING;
  }
};

export const useHeaderProps = () => {
  const authenticationStatus = useAuthenticationStatus();

  const { email, firstName } = useCandidateDetails();
  const isJDP = useSelector(selectIsJDP);
  const returnUrlForPostAuth = useSelector(selectUrl);
  const setLanguage = useSetLanguage();

  const userProfile = {
    firstName,
    lastName: '',
    email,
  };
  const onLanguageSwitch: ComponentProps<
    typeof ApacCandidateHeader
  >['onLanguageSwitch'] = (lang) => setLanguage(lang);

  const languageSwitcher = useLanguageSwitcher();

  return {
    authenticationStatus,
    isEnableDivider: isJDP,
    languageSwitcher,
    onLanguageSwitch,
    returnUrlForPostAuth: returnUrlForPostAuth || '',
    userProfile,
  };
};

export const useFooterProps = () => {
  const authenticationStatus = useAuthenticationStatus();

  return { authenticationStatus, widthMode: 'large' as const };
};
