import type { ZoneFeatures } from 'src/config/types';
import { isATargetDevice } from 'src/modules/app-install-banner';
import type { CookieType } from 'src/types/cookie';

import { toggleExperiment } from '../experiments/experimentHelpers';
import type { TSeekExperiments } from '../experiments/types';
import type { TypedAction, TypedThunkAction } from '../types';

import {
  OVERRIDE_FEATURE_FLAGS,
  UPDATE_FEATURE_FLAGS,
  type FeatureFlagsOverrideAction,
  type FeatureFlagsState,
} from './types';

export const initialState: FeatureFlagsState = {
  behaviouralCuesFilters: false,
  branchBannerPreview: false,
  isBranchEnabledFlag: false,
  showHomepageBanner: false,
  showFloatingSaveSearch: true,
  autoSelectSplitViewFirstJob: true,
  showMarketingAdSplitView: false,
  loggedOutRecs: false,
  remoteSearchFilter: false,
  remoteSearchFilterNewLabel: false,
  keywordAutosuggestV2: false,
  serpJobCardInfoDensity1: false,
  newJobCardDensity: false,
  hideCompanyLogo: false,
  matchedQualities: false,
  entryLevelBadge: false,
  homepageRecsBadgingStrongApplicant: false,
  homepageRecsBadgingExpiringSoonEarlyApplicant: false,
  homepageJobCardDensity: false,
  homepageGenAIChat: false,
  homepageGenAIChatAltModel: false,
  competitivePlacementEmbeddedJdv: false,
  competitivePlacement: false,
};

export const initialiseFeatureFlags = ({
  cookies = {},
  experiments,
  zoneFeatures: {
    SHOW_FLOATING_SAVE_SEARCH,
    AUTO_SELECT_SPLIT_VIEW_FIRST_JOB,
    SHOW_MARKETING_AD_SPLIT_VIEW,
    BEHAVIOURAL_CUES_FILTERS_ENABLED,
    LOGGED_OUT_RECS,
    REMOTE_SEARCH_FILTER,
    REMOTE_SEARCH_FILTER_NEW_LABEL,
    KEYWORD_AUTOSUGGEST_V2,
    NEW_JOB_CARD_DENSITY,
    SERP_JOBCARD_INFO_DENSITY_1,
    MATCHED_QUALITIES,
    ENTRY_LEVEL_BADGE,
    COMPETITIVE_PLACEMENT_EMBEDDED_JDV,
  },
  userAgent,
}: {
  cookies: CookieType;
  experiments: TSeekExperiments;
  zoneFeatures: ZoneFeatures;
  userAgent: string;
}): FeatureFlagsState => ({
  ...initialState,
  behaviouralCuesFilters:
    Boolean(
      cookies.BEHAVIOURAL_CUES_FILTERS_ENABLED ||
        BEHAVIOURAL_CUES_FILTERS_ENABLED,
    ) ||
    toggleExperiment({
      experimentId: 'behaviouralCuesFilters',
      experiments,
      cookieValue: cookies.BEHAVIOURAL_CUES_FILTERS_ENABLED,
    }),
  branchBannerPreview: Boolean(cookies.BRANCH_BANNER_PREVIEW),
  isBranchEnabledFlag:
    Boolean(cookies.ENABLE_BRANCH_BANNER) || isATargetDevice(userAgent),
  showFloatingSaveSearch: Boolean(
    cookies.SHOW_FLOATING_SAVE_SEARCH || SHOW_FLOATING_SAVE_SEARCH,
  ),
  autoSelectSplitViewFirstJob:
    Boolean(cookies.AUTO_SELECT_SPLIT_VIEW_FIRST_JOB) ||
    Boolean(AUTO_SELECT_SPLIT_VIEW_FIRST_JOB),
  showMarketingAdSplitView: Boolean(
    cookies.SHOW_MARKETING_AD_SPLIT_VIEW || SHOW_MARKETING_AD_SPLIT_VIEW,
  ),
  loggedOutRecs: Boolean(cookies.LOGGED_OUT_RECS || LOGGED_OUT_RECS),
  remoteSearchFilter:
    Boolean(cookies.REMOTE_SEARCH_FILTER || REMOTE_SEARCH_FILTER) ||
    toggleExperiment({
      experimentId: 'remoteSearchFilter',
      experiments,
      cookieValue: cookies.REMOTE_SEARCH_FILTER,
    }),
  remoteSearchFilterNewLabel: Boolean(REMOTE_SEARCH_FILTER_NEW_LABEL),
  keywordAutosuggestV2: Boolean(
    cookies.KEYWORD_AUTOSUGGEST_V2 || KEYWORD_AUTOSUGGEST_V2,
  ),
  serpJobCardInfoDensity1: Boolean(
    cookies.SERP_JOBCARD_INFO_DENSITY_1 ||
      SERP_JOBCARD_INFO_DENSITY_1 ||
      toggleExperiment({
        experimentId: 'serpJobCardInfoDensity1',
        experiments,
        cookieValue: cookies.SERP_JOBCARD_INFO_DENSITY_1,
      }),
  ),
  newJobCardDensity: Boolean(
    cookies.NEW_JOB_CARD_DENSITY || NEW_JOB_CARD_DENSITY,
  ),
  hideCompanyLogo: Boolean(cookies.HIDE_COMPANY_LOGO),
  matchedQualities: Boolean(
    cookies.MATCHED_QUALITIES ||
      MATCHED_QUALITIES ||
      toggleExperiment({
        experimentId: 'matchedQualities',
        experiments,
        cookieValue: cookies.MATCHED_QUALITIES,
      }),
  ),
  entryLevelBadge: Boolean(cookies.ENTRY_LEVEL_BADGE || ENTRY_LEVEL_BADGE),
  competitivePlacementEmbeddedJdv: Boolean(
    cookies.COMPETITIVE_PLACEMENT_EMBEDDED_JDV ||
      COMPETITIVE_PLACEMENT_EMBEDDED_JDV,
  ),
  competitivePlacement: true,
});

export const updateFeatureFlagsAfterAuth =
  (
    cookies: CookieType = {},
    experiments: TSeekExperiments,
    zoneFeatures: ZoneFeatures,
  ): TypedThunkAction =>
  (dispatch) => {
    dispatch({
      type: UPDATE_FEATURE_FLAGS,
      payload: {
        dynamicFlags: {
          homepageRecsBadgingStrongApplicant: Boolean(
            cookies.HOMEPAGE_RECS_BADGING_STRONG_APPLICANT ||
              zoneFeatures.HOMEPAGE_RECS_BADGING_STRONG_APPLICANT,
          ),
          homepageRecsBadgingExpiringSoonEarlyApplicant: Boolean(
            cookies.HOMEPAGE_RECS_BADGING_EXPIRING_SOON_EARLY_APPLICANT ||
              toggleExperiment({
                experimentId: 'homepageRecsBadgingExpiringSoonEarlyApplicant',
                experiments,
                cookieValue:
                  cookies.HOMEPAGE_RECS_BADGING_EXPIRING_SOON_EARLY_APPLICANT,
              }),
          ),
          homepageGenAIChat: Boolean(cookies.HOMEPAGE_GENAI_CHAT),
          homepageGenAIChatAltModel: Boolean(
            cookies.HOMEPAGE_GENAI_CHAT_ALT_MODEL,
          ),
        },
      },
    });
  };

export default function reducer(
  state = initialState,
  action: TypedAction,
): FeatureFlagsState {
  switch (action.type) {
    case UPDATE_FEATURE_FLAGS: {
      const { dynamicFlags } = action.payload;
      return {
        ...state,
        ...dynamicFlags,
      };
    }

    case OVERRIDE_FEATURE_FLAGS: {
      return {
        ...state,
        overrides: action.payload.overrides,
      };
    }

    default:
      return state;
  }
}

export const syncFeatureFlagsOverridesCookies = (
  overrides: CookieType,
): FeatureFlagsOverrideAction => ({
  type: OVERRIDE_FEATURE_FLAGS,
  payload: { overrides },
});
