import {
  useExperimentsEligibleProps,
  useIsExperimentsReady,
  type FeatureFlagProviderProps,
} from '@seek/candidate-feature-flag';
import { useEffect, useMemo } from 'react';

import { appName, version } from 'src/config';
import { experimentsToFetch } from 'src/config/experiments';
import { featureFlagsToFetch } from 'src/config/featureFlags';
import { useAnalyticsFacade } from 'src/modules/AnalyticsFacade';
import { useSelector } from 'src/store/react';
import {
  selectFeatureFlagOverrides,
  selectSolId,
  selectUserAgent,
} from 'src/store/selectors';

export const useFeatureFlagProviderProps = (): Omit<
  FeatureFlagProviderProps,
  'children'
> => {
  const solId = useSelector(selectSolId) || '';
  const featureFlagsCookies = useSelector(selectFeatureFlagOverrides);
  const userAgent = useSelector(selectUserAgent);

  return useMemo(
    () => ({
      shouldEnableOverride: true,
      overrideSource: featureFlagsCookies,
      featureFlagsConfig: featureFlagsToFetch,
      userAgent,
      solId,
      experimentsConfig: experimentsToFetch,
      customContext: {
        applicationContext: {
          key: solId,
          namespace: appName,
          build: version,
          version,
        },
      },
    }),
    [featureFlagsCookies, solId, userAgent],
  );
};

export const InitialiseExperiments = () => {
  const analyticsFacade = useAnalyticsFacade();
  const {
    tealiumEligibleProps: { experimentsEligible, hubbleExperiments },
  } = useExperimentsEligibleProps();
  const isExperimentsReady = useIsExperimentsReady();

  useEffect(() => {
    if (isExperimentsReady) {
      analyticsFacade.setExperiments({
        experimentsEligible,
        hubbleExperiments,
      });
    }
  }, [
    analyticsFacade,
    experimentsEligible,
    hubbleExperiments,
    isExperimentsReady,
  ]);

  return null;
};
