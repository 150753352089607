interface GetDisplayLabel {
  defaultDisplayLabel: string;
  activeSalaryRangeFromValue: string | null;
  activeSalaryRangeToValue: string | null;
  fromLabel?: string;
  toLabel?: string;
}

export const getDisplayLabel = ({
  defaultDisplayLabel,
  activeSalaryRangeFromValue,
  activeSalaryRangeToValue,
  fromLabel,
  toLabel,
}: GetDisplayLabel) => {
  if (activeSalaryRangeFromValue && activeSalaryRangeToValue) {
    return `${fromLabel} - ${toLabel}`;
  } else if (activeSalaryRangeFromValue) {
    return `From ${fromLabel}`;
  } else if (activeSalaryRangeToValue) {
    return `Up to ${toLabel}`;
  }

  return defaultDisplayLabel;
};
