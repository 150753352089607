import type {
  TestHeaders,
  TestTags,
} from 'src/utils/productionTesting/productionTesting';

import type { TypedAction } from '../types';

import {
  SET_SEEKER_ID,
  UPDATE_AUTHENTICATED,
  UPDATE_SERVER_AUTHENTICATED,
  UPDATE_TEST_HEADERS,
  UPDATE_TEST_TAGS,
  SET_TIMEZONE,
  type UserState,
} from './types';

export const initialState: UserState = {
  authenticated: undefined,
  userClientId: '',
  sessionId: '',
  testHeaders: {},
  timezone:
    // eslint-disable-next-line new-cap
    Intl.DateTimeFormat().resolvedOptions().timeZone || 'Australia/Melbourne',
  userAgent: '',
};

export default function reducer(
  state: UserState = initialState,
  action: TypedAction,
): UserState {
  switch (action.type) {
    case UPDATE_AUTHENTICATED:
      const { authenticated } = action.payload;

      return {
        ...state,
        authenticated,
        serverAuthenticated: authenticated,
      };

    case UPDATE_SERVER_AUTHENTICATED:
      const { serverAuthenticated } = action.payload;

      return {
        ...state,
        serverAuthenticated,
      };

    case SET_SEEKER_ID:
      const { id: seekerId } = action.payload;
      return {
        ...state,
        seekerId,
      };

    case UPDATE_TEST_HEADERS:
      const { testHeaders } = action.payload;

      return {
        ...state,
        testHeaders,
      };

    case UPDATE_TEST_TAGS:
      const { testTags } = action.payload;

      return {
        ...state,
        testTags,
      };

    case SET_TIMEZONE:
      const { timezone } = action.payload;

      return {
        ...state,
        timezone,
      };

    default:
      return state;
  }
}

export const updateAuthenticated = ({
  authenticated,
}: {
  authenticated: boolean;
}): TypedAction => ({
  type: UPDATE_AUTHENTICATED,
  payload: { authenticated },
});

export const updateServerAuthenticated = ({
  serverAuthenticated,
}: {
  serverAuthenticated: boolean;
}): TypedAction => ({
  type: UPDATE_SERVER_AUTHENTICATED,
  payload: { serverAuthenticated },
});

export const updateTestHeaders = (testHeaders: TestHeaders): TypedAction => ({
  type: UPDATE_TEST_HEADERS,
  payload: {
    testHeaders,
  },
});

export const updateEventCaptureTestTags = (
  testTags?: TestTags,
): TypedAction => ({
  type: UPDATE_TEST_TAGS,
  payload: {
    testTags,
  },
});

export const setTimezone = (timezone: string): TypedAction => ({
  type: SET_TIMEZONE,
  payload: {
    timezone,
  },
});
