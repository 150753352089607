import type { JobTag } from '@seek/chalice-types';
import { useTranslations } from '@vocab/react';

import {
  useIsHomepageRecsBadgingExpiringSoonEarlyApplicantEnabled,
  useIsHomepageRecsBadgingStrongApplicantEnabled,
} from 'src/store/featureFlags/hooks';

import translations from '../.vocab';

import { getRecommendationBadges } from './getRecommendationBadges';

export const useRecommendationBadges = (
  tags: JobTag[] | undefined,
  isExpired: boolean | undefined,
) => {
  const isHomepageRecsBadgingStrongApplicantEnabled =
    useIsHomepageRecsBadgingStrongApplicantEnabled();
  const isHomepageRecsBadgingExpiringSoonEarlyApplicantEnabled =
    useIsHomepageRecsBadgingExpiringSoonEarlyApplicantEnabled();

  const { t } = useTranslations(translations);

  const { badges, labels, viewedLabel } = getRecommendationBadges(
    tags,
    isHomepageRecsBadgingStrongApplicantEnabled,
    isHomepageRecsBadgingExpiringSoonEarlyApplicantEnabled,
  );

  if (isExpired) {
    badges.push({
      label: t('Expired'),
      tone: 'neutral',
      data: {
        automation: 'expiredBadge',
      },
    });
  }

  return { badges, labels, viewedLabel };
};
