import { Fragment, useEffect, useMemo } from 'react';
// @ts-expect-error: non-ts file
import { provideHooks } from 'redial';
import loadable from 'sku/@loadable/component';
import { v4 as uuid } from 'uuid';

import ExpiredJob from 'src/components/ExpiredJob/ExpiredJob';
import {
  RECOMMENDED_JOB_AI_VIEW_ORIGIN,
  RECOMMENDED_JOB_VIEW_ORIGIN,
} from 'src/components/HomePage/Dashboard/SignedInDashboard/DashboardRecommendations/RecommendedJobCard';
import PageLoader from 'src/components/PageLoader/PageLoader';
import PageNotFound from 'src/components/PageNotFound/PageNotFound';
import {
  SafetyHelmet,
  type SafetyHelmetProps,
} from 'src/components/SafetyHelmet/SafetyHelmet';
import getRecommendedBadgesForTracking from 'src/hooks/getRecommendedJobAnalyticsBadges';
import { useSaveJobFromQueryParam } from 'src/hooks/useSaveJob/utils/useSaveJobFromQueryParam';
import { useViewJob } from 'src/hooks/useViewedJobs/useViewedJobs';
import { logger } from 'src/modules/logger';
import { jobOrExpiredJobRegex } from 'src/modules/routes-regexp';
import {
  clearJobDetails,
  fetchUnifiedJob,
  fetchUnifiedJobPersonalised,
  jobDetailsPageLoaded,
  setJobDetailsCorrelationId,
} from 'src/store/jobdetails';
import { useSelector } from 'src/store/react';
import {
  selectIsJobDetailsPending,
  selectJobDetailsResult,
  selectLocation,
  selectUserAgent,
  selectUserTestHeaders,
  selectXRealIp,
} from 'src/store/selectors';
import type { RedialLocals } from 'src/types/RedialLocals';
import {
  getJobActionOrigin,
  getViewJobOriginRef,
} from 'src/utils/eventCapture/eventCaptureUtils';

import {
  createGetSolMetadataForJDP,
  trackJobDetailsLoaded,
} from '../hooks/trackJobDetailsLoaded';
import { useSetHubbleTags } from '../modules/hubble';
import { useJobDetailsHead } from '../modules/seo-header/job-details-page/head';

export const JobDetailsPageView = loadable(
  /* #__LOADABLE__ */ () =>
    import(
      /* webpackChunkName: "JobDetailsPage" */ 'src/components/JobDetailsPage/JobDetailsPage'
    ),
  {
    resolveComponent: (m) => m.default,
  },
);

const extractIdFromPath = (path?: string) => {
  if (!path) {
    return '';
  }
  const match = path.match(jobOrExpiredJobRegex);
  return match ? match[2] : '';
};

const hooks = {
  fetch: ({
    analyticsFacade,
    apolloClient,
    dispatch,
    getState,
    path,
    zone,
    languageCode,
    locale,
    country,
    serverProps: { xRealIp } = {},
  }: RedialLocals) => {
    const jobId = extractIdFromPath(path);
    const { sessionId } = getState().user;
    const existingJobId = getState().jobdetails.result?.job?.id;

    if (existingJobId && jobId !== String(existingJobId)) {
      dispatch(clearJobDetails());
    }

    dispatch(setJobDetailsCorrelationId(uuid()));

    return dispatch(
      fetchUnifiedJob({
        analyticsFacade,
        apolloClient,
        jobId,
        sessionId,
        zone,
        locale,
        languageCode,
        countryCode: country,
        xRealIp,
      }),
    );
  },
  defer: ({
    apolloClient,
    authenticated,
    dispatch,
    path,
    languageCode,
    getState,
    locale,
    zone,
    serverProps: { xRealIp } = {},
  }: RedialLocals) => {
    const jobId = extractIdFromPath(path);
    const { sessionId } = getState().user;

    if (authenticated) {
      return dispatch(
        fetchUnifiedJobPersonalised({
          apolloClient,
          id: jobId,
          sessionId,
          languageCode,
          locale,
          zone,
          xRealIp,
        }),
      );
    }
    return;
  },
  pageLoad: ({
    analyticsFacade,
    dispatch,
    getState,
    query,
    apolloClient,
    path,
    locale,
  }: RedialLocals) => {
    const state = getState();
    const id = extractIdFromPath(path);
    const currentPath = state.location.pathname;
    const prevPath = state.location.prevPathname;

    const jobDetails = state.jobdetails.result;

    const getSolMetadata = createGetSolMetadataForJDP({
      experiments: state.experiments,
      hash: state.location.hash,
      jobId: id,
      query,
    });

    /**
     * When the current pathname === previous pathname it a signal that the user has
     * accessed this job via an external source.
     */
    const jobListingPosition =
      currentPath === prevPath
        ? -1
        : (query?.pos ?? getSolMetadata().sectionRank);

    const ref = getViewJobOriginRef();
    const origin = getJobActionOrigin();

    const analyticsBadges = [
      RECOMMENDED_JOB_VIEW_ORIGIN,
      RECOMMENDED_JOB_AI_VIEW_ORIGIN,
    ].includes(query?.ref)
      ? getRecommendedBadgesForTracking({
          apolloClient,
          locale,
          jobId: jobDetails?.job?.id ?? '',
          isHomepageRecsBadgingStrongApplicantEnabled:
            state.featureFlags.homepageRecsBadgingStrongApplicant,
          isHomepageRecsBadgingExpiringSoonEarlyApplicantEnabled:
            state.featureFlags.homepageRecsBadgingExpiringSoonEarlyApplicant,
        })
      : [];

    trackJobDetailsLoaded(
      analyticsFacade,
      {
        correlationId: state.jobdetails.jobDetailsViewedCorrelationId || '',
        getSolMetadata,
        jobDetails,
        jobListingPosition,
        jobViewType: 'standalone',
        omnitureTracking: query?.tracking,
        jobViewOrigin: ref,
        jobActionOrigin: origin,
        jobTags: analyticsBadges,
      },
      state,
      apolloClient,
    );

    dispatch(jobDetailsPageLoaded());
  },
};

const ExpiredJobWithSafetyHelmet = (helmetProps: SafetyHelmetProps) => (
  <Fragment>
    <SafetyHelmet {...helmetProps} />
    <ExpiredJob />
  </Fragment>
);

const JobDetails = () => {
  const location = useSelector(selectLocation);
  const jobDetails = useSelector(selectJobDetailsResult);
  const jobPending = useSelector(selectIsJobDetailsPending);
  const testHeaders = useSelector(selectUserTestHeaders);
  const ipAddress = useSelector(selectXRealIp);
  const userAgent = useSelector(selectUserAgent);
  useSetHubbleTags(testHeaders);

  useSaveJobFromQueryParam();

  const viewJob = useViewJob(jobDetails?.job.id);
  useEffect(() => {
    viewJob();
  }, [viewJob]);

  const jobIsExpired = useMemo(() => {
    if (jobDetails?.job && jobDetails?.job?.status) {
      const isExpired =
        jobDetails?.job?.status !== 'Active' &&
        location.pathname.search('expiredjob') < 0;

      if (isExpired) {
        logger.info(
          {
            input: {
              jobId: jobDetails?.job.id,
              status: jobDetails?.job.status,
              ipAddress,
              userAgent,
            },
          },
          'Job is expired',
        );
      }

      return isExpired;
    }
    return false;
  }, [jobDetails?.job, location.pathname, userAgent, ipAddress]);

  const helmetProps = useJobDetailsHead({
    jobTitle: jobDetails?.job?.title || '',
    location: jobDetails?.job?.location?.label || '',
    abstract: jobDetails?.job?.abstract || '',
    normalisedRoleTitle:
      jobDetails?.seoInfo?.normalisedRoleTitle || jobDetails?.job?.title || '',
    broaderLocationName:
      jobDetails?.seoInfo?.broaderLocationName ||
      jobDetails?.job?.location?.label ||
      '',
    normalisedOrganisationName:
      jobDetails?.seoInfo?.normalisedOrganisationName || '',
  });

  if (!jobDetails) {
    return jobPending ? <PageLoader /> : <PageNotFound />;
  }

  if (jobIsExpired) {
    return <ExpiredJobWithSafetyHelmet {...helmetProps} />;
  }

  return (
    <Fragment>
      <SafetyHelmet {...helmetProps} />
      {jobDetails && <JobDetailsPageView jobDetails={jobDetails} />}
    </Fragment>
  );
};

export default provideHooks(hooks)(JobDetails);
