import { searchV5DarkProdUrl } from 'src/config';
import type { CookieType } from 'src/types/cookie';

type JobSearchPath = '/search' | '/counts' | '/count';

export const getJobSearchDarkProdUrl = ({
  cookies,
  path,
}: {
  cookies: CookieType;
  path: JobSearchPath;
}) => {
  if (Boolean(cookies.JOBSEARCH_DARK_PROD_ENABLED)) {
    if (Boolean(cookies.AUTH_TOKEN)) {
      // Personalised dark prod
      return `${searchV5DarkProdUrl}/me${path}`;
    }
    return `${searchV5DarkProdUrl}${path}`;
  }

  return;
};
