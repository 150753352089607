import { useEffect } from 'react';

export const sendHotJarEvent = (event: string) => {
  window.hj ||= function () {
    // eslint-disable-next-line prefer-rest-params
    (hj.q = hj.q || []).push(arguments);
  };

  window.hj('event', event);
};

export const useHotjarEvent = (event: string) => {
  useEffect(() => {
    sendHotJarEvent(event);
  }, [event]);
};
