import { useSelector } from 'react-redux';

import {
  selectFeatureFlag,
  selectIsJobDetailsPending,
  selectSelectedJobId,
  selectIsJobDetailsFetchFailure,
  selectJobDetailsIsExpired,
  selectXRealIp,
  selectUserAgent,
} from 'src/store/selectors';

const useJobDetail = () => {
  const autoSelectSplitViewFirstJob = useSelector(
    selectFeatureFlag('autoSelectSplitViewFirstJob'),
  );
  const isLoading = useSelector(selectIsJobDetailsPending);
  const isError = useSelector(selectIsJobDetailsFetchFailure);
  const isExpired = useSelector(selectJobDetailsIsExpired);
  const hasJobId = Boolean(useSelector(selectSelectedJobId));
  const ipAddress = useSelector(selectXRealIp);
  const userAgent = useSelector(selectUserAgent);

  return {
    isLoading,
    isError,
    isExpired,
    hasJobId,
    autoSelectSplitViewFirstJob,
    ipAddress,
    userAgent,
  };
};

export default useJobDetail;
