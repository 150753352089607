import { useTranslations } from '@vocab/react';
import {
  Badge,
  Box,
  Divider,
  Heading,
  IconFilter,
  Inline,
  Stack,
  useResponsiveValue,
} from 'braid-design-system';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { selectFeatureFlag } from 'src/store/selectors';

import { useClassificationsField } from '../Classifications/useClassificationsField';
import { useDateListedField } from '../DateListed/useDateListedField';
import { useSalaryRangeField } from '../SalaryRange/useSalaryRangeField';
import { useWorkArrangementsField } from '../WorkArrangements/useWorkArrangementsField';
import { useWorkTypesField } from '../WorkTypes/useWorkTypesField';

import translations from './.vocab';

import * as styles from './CombinedFields.css';

interface CombinedRefineFields {
  id: string;
  ariaLabel: string;
  label?: JSX.Element | string;
  summarySentence?: string;
  filterComponent: () => JSX.Element;
  isSelected: boolean;
}

export const useCombinedFields = (
  handleSelection: (fieldId: string) => void,
): CombinedRefineFields => {
  const { t } = useTranslations(translations);
  const remoteSearchFilterFeatureToggle = useSelector(
    selectFeatureFlag('remoteSearchFilter'),
  );
  const remoteSearchFilterNewFeatureToggle = useSelector(
    selectFeatureFlag('remoteSearchFilterNewLabel'),
  );
  const salaryRangeField = useSalaryRangeField(handleSelection);
  const workArrangementsField = useWorkArrangementsField(handleSelection);
  const dateListedField = useDateListedField(handleSelection);

  const workTypesField = useWorkTypesField(handleSelection);
  const classificationsField = useClassificationsField(handleSelection, true);

  const isMobile = useResponsiveValue()({
    mobile: true,
    tablet: false,
  });

  const fields = useMemo(
    () => [
      {
        condition: !isMobile,
        automation: workTypesField.id,
        heading: t('Work type'),
        component: workTypesField.filterComponent(),
        isSelected: !isMobile && workTypesField.isSelected,
        totalSelected: !isMobile && workTypesField.totalSelected,
      },
      {
        condition: true,
        automation: salaryRangeField.id,
        heading: t('Pay'),
        component: salaryRangeField.filterComponent(),
        isSelected: salaryRangeField.isSelected,
        totalSelected: salaryRangeField.totalSelected,
      },
      {
        condition: !isMobile,
        automation: classificationsField.id,
        heading: t('Classification'),
        component: classificationsField.filterComponent(),
        isSelected: !isMobile && classificationsField.isSelected,
        totalSelected: !isMobile && classificationsField.totalSelected,
      },
      {
        condition: remoteSearchFilterFeatureToggle,
        automation: workArrangementsField.id,
        heading: t('Remote options'),
        component: workArrangementsField.filterComponent(),
        isSelected: workArrangementsField.isSelected,
        totalSelected: workArrangementsField.totalSelected,
        badgeText: remoteSearchFilterNewFeatureToggle ? 'New' : undefined,
      },
      {
        condition: true,
        automation: dateListedField.id,
        heading: t('Listing time'),
        component: dateListedField.filterComponent(),
        isSelected: dateListedField.isSelected,
        totalSelected: dateListedField.totalSelected,
      },
    ],
    [
      salaryRangeField,
      dateListedField,
      remoteSearchFilterFeatureToggle,
      remoteSearchFilterNewFeatureToggle,
      workArrangementsField,
      isMobile,
      workTypesField,
      classificationsField,
      t,
    ],
  );

  // Calculate the total count of selected items from all fields
  const countSelectedItems = fields.reduce(
    (acc, item) => acc + (item.totalSelected || 0),
    0,
  );

  const displayLabel = (
    <>
      {countSelectedItems > 0 ? `${countSelectedItems} ` : ''}
      <IconFilter />
    </>
  );

  const isSelected = fields.some((field) => field.isSelected);

  return {
    id: 'CombinedFields',
    ariaLabel: t('Combined fields'),
    label: displayLabel,
    filterComponent: () => (
      <Box
        overflow="auto"
        className={styles.combinedContainer}
        padding="gutter"
        borderRadius="xlarge"
      >
        <Stack space="medium">
          {fields.map(
            ({ condition, heading, component, automation, badgeText }, index) =>
              condition && (
                <>
                  <Box key={index} data={{ automation: `${automation}` }}>
                    <Inline space="xxsmall" alignY="center">
                      <Heading level="4">{heading}</Heading>
                      {badgeText ? (
                        <Badge tone="positive">{badgeText}</Badge>
                      ) : (
                        <></>
                      )}
                    </Inline>
                    <Box paddingTop="gutter">{component}</Box>
                  </Box>
                  {index < fields.length - 1 && <Divider />}
                </>
              ),
          )}
        </Stack>
      </Box>
    ),
    isSelected,
  };
};
