import type { DeviceType } from '@seek/chalice-types';

import { getCurrentDevice } from 'src/modules/chalice-user-agent/device-detect';
import { logger } from 'src/modules/logger';

export const isSupportedIosVersion = (userAgent: string): boolean => {
  const minSupportedVersion = 13;
  const isIosDevice = /iPhone|iPad/i.test(userAgent);

  if (!isIosDevice) {
    return true;
  }

  const userAgentRegexMatch = userAgent.match(/OS (\d+)_(\d+)_?(\d+)?/);

  if (!userAgentRegexMatch) {
    logger.info(
      {
        input: {
          userAgent,
        },
      },
      'BranchIO: Failed iOS userAgent regex match',
    );
    return false;
  }

  const version = parseInt(userAgentRegexMatch[1], 10) || 12;
  return version >= minSupportedVersion;
};

const BRANCH_TARGET_DEVICES: DeviceType[] = ['iOS', 'Android', 'Desktop'];

export const isATargetDevice = (ua: string): boolean => {
  const currentDevice = getCurrentDevice(ua);
  return BRANCH_TARGET_DEVICES.includes(currentDevice);
};

export const canShowAppBanner = ({
  userAgent,
  isBranchEnabledFlag,
}: {
  userAgent: string;
  isBranchEnabledFlag: boolean;
}) => {
  const shouldShowBranchInterstitial =
    isBranchEnabledFlag && isSupportedIosVersion(userAgent);

  return {
    shouldShowBranchInterstitial,
  };
};
