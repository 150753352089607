import { assignInlineVars } from '@vanilla-extract/dynamic';
import { useTranslations } from '@vocab/react';
import {
  Box,
  HiddenVisually,
  IconChevron,
  Text,
  useResponsiveValue,
} from 'braid-design-system';
import {
  useCallback,
  useRef,
  useState,
  type FormEvent,
  type MouseEvent,
  useEffect,
  useMemo,
  type ReactNode,
} from 'react';

import { useDateListedField } from 'src/components/Search/RefineBar/DateListed/useDateListedField';
import { useSalaryTypeField } from 'src/components/Search/RefineBar/SalaryType/useSalaryTypeField';
import { useDispatch, useSelector } from 'src/store/react';
import { selectRefinement } from 'src/store/search';
import { selectFeatureFlag, selectIsHomePage } from 'src/store/selectors';

import translations from './.vocab';
import MoreOptions from './MoreOptions/MoreOptions';
import { Status } from './Status/Status';
import { useWorkArrangementsField } from './WorkArrangements/useWorkArrangementsField';
import { useWorkTypesField } from './WorkTypes/useWorkTypesField';

import * as styles from './RefineBar.css';

interface RefineBarProps {
  expanded: boolean;
  isSearchInView?: boolean;
  isMobileExpandedView: boolean;
  closeFilterOnScroll?: boolean;
}

export interface RefineField {
  id: string;
  ariaLabel: string;
  filterComponent: () => JSX.Element;
  label?: JSX.Element | string;
  summarySentence?: string;
  status?: { label: string };
  isSelected?: boolean;
}

export default function RefineBar(props: RefineBarProps) {
  const {
    expanded,
    isSearchInView,
    isMobileExpandedView,
    closeFilterOnScroll = false,
  } = props;
  const { t } = useTranslations(translations);
  const dispatch = useDispatch();
  const [panelPos, setPanelPos] = useState(0);
  const [activeFieldId, setActiveFieldId] = useState<string | null>(null);
  const containerRef = useRef<HTMLElement>(null);
  const isHomePage = useSelector(selectIsHomePage);
  const isMobile = useResponsiveValue()({
    mobile: true,
    tablet: false,
  });

  const updateActiveField = useCallback(
    (fieldId: string, { left, width }: DOMRect) => {
      const panelMaxWidth = 400; /* contentWidth.small */
      const viewportWidth = document.documentElement.clientWidth;
      const containerOffset =
        containerRef.current?.getBoundingClientRect().left || 0;
      const targetLeftPos = left - containerOffset;
      const exceedsViewportWidth = left + panelMaxWidth > viewportWidth;

      setPanelPos(
        exceedsViewportWidth
          ? targetLeftPos - (panelMaxWidth - width)
          : targetLeftPos,
      );
      setActiveFieldId(fieldId);
    },
    [],
  );

  useEffect(() => {
    if (isSearchInView === false) {
      setActiveFieldId(null);
    }
  }, [isSearchInView]);

  const [isMouseInPopUp, setMouseInPopup] = useState(false);
  const [trackPageScroll, setTrackPageScroll] = useState(false);

  const closeFilter = useCallback(() => {
    if (isMouseInPopUp) {
      // Only keep the pop up open if the user's cursor is hovering over it
      return;
    }

    setActiveFieldId(null);

    if (trackPageScroll) {
      setTrackPageScroll(false);
    }
  }, [trackPageScroll, isMouseInPopUp]);

  useEffect(() => {
    // When closeFilterOnScroll is enabled, close the open pop up when the user scrolls on the page
    if (!closeFilterOnScroll) {
      return;
    }

    if (trackPageScroll) {
      window.addEventListener('scroll', closeFilter);
    }

    return () => {
      window.removeEventListener('scroll', closeFilter);
    };
  }, [trackPageScroll, closeFilterOnScroll, closeFilter]);

  const handleSelection = useCallback(
    (type: string) => {
      const validTypes = ['WorkType', 'WorkArrangement', 'Classification'];

      if (!validTypes.includes(type)) {
        setActiveFieldId(null);
        return;
      }

      dispatch(selectRefinement());
    },
    [dispatch, setActiveFieldId],
  );

  const remoteSearchFilterFeatureToggle = useSelector(
    selectFeatureFlag('remoteSearchFilter'),
  );

  const workArrangementsField = useWorkArrangementsField(handleSelection);

  const workTypesField = useWorkTypesField(handleSelection);
  const salaryTypeField = useSalaryTypeField(handleSelection);
  const dateListedField = useDateListedField(handleSelection);

  const fields: RefineField[] = useMemo(
    () =>
      [
        workTypesField,
        ...(remoteSearchFilterFeatureToggle ? [workArrangementsField] : []),
        salaryTypeField,
        dateListedField,
      ].flat(),
    [
      workTypesField,
      remoteSearchFilterFeatureToggle,
      workArrangementsField,
      salaryTypeField,
      dateListedField,
    ],
  );

  // `fields` array shown above is recreated whenever a field object changes.
  // `memoisedFieldComponents` will detects these changes in the `fields` array and updates accordingly.
  const memoisedFieldComponents = useMemo(
    () =>
      fields.reduce<Record<string, ReactNode>>((acc, field) => {
        acc[field.id] = field.filterComponent();
        return acc;
      }, {}),
    [fields],
  );

  const isAnyFieldActive = fields.some(({ id }) => id === activeFieldId);
  const fieldName = 'RefineBar__Toggle';

  const isExpanded = !isHomePage && isMobile ? !isMobileExpandedView : expanded;

  return (
    <>
      <Box
        display={{
          mobile: 'none',
          tablet: isExpanded ? 'none' : 'flex',
        }}
        justifyContent="flexEnd"
        alignItems="center"
        paddingY={styles.summaryLabelVerticalPadding}
        aria-hidden={isExpanded}
      >
        <MoreOptions />
      </Box>

      <Box
        component="aside"
        role="navigation"
        aria-hidden={!isExpanded}
        aria-labelledby="refine-your-search"
        className={{
          [styles.root]: true,
          [styles.rootIsExpanded]: isExpanded,
          [styles.animationFadeIn]: true,
        }}
        position="relative"
        data-automation="refineBar"
        style={assignInlineVars({
          [styles.panelPosLeft]: `${panelPos}px`,
        })}
      >
        <HiddenVisually>
          <h1 id="refine-your-search">{t('Refine your search')}</h1>
        </HiddenVisually>

        <Box
          className={{
            [styles.summary]: true,
            [styles.summaryIsExpanded]: isExpanded,
          }}
        >
          <Box
            ref={containerRef}
            position="relative"
            display="flex"
            alignItems="center"
          >
            {fields.map((field, index) => {
              const isActiveOrSelected =
                activeFieldId === field.id || field.isSelected;
              return (
                <Box position="relative" key={field.id}>
                  <Box
                    component="input"
                    id={`RefineBar--${field.id}`}
                    className={styles.toggle}
                    name={fieldName}
                    type="radio"
                    role="button"
                    aria-expanded={isExpanded}
                    aria-label={t('refine by {field}', {
                      field: field.ariaLabel,
                    })}
                    checked={activeFieldId === field.id}
                    onChange={(event: FormEvent<HTMLInputElement>) => {
                      updateActiveField(
                        field.id,
                        event.currentTarget.getBoundingClientRect(),
                      );
                    }}
                  />

                  <Box
                    component="label"
                    className={{
                      [styles.summaryLabel]: true,
                      [styles.summaryLabelIsNotRefining]:
                        isAnyFieldActive && activeFieldId !== field.id,
                    }}
                    boxShadow="borderNeutral"
                    marginRight={
                      index < fields.length - 1 ? 'xxsmall' : undefined
                    }
                    htmlFor={`RefineBar--${field.id}`}
                    onClick={(event: MouseEvent<HTMLInputElement>) => {
                      updateActiveField(
                        field.id,
                        event.currentTarget.getBoundingClientRect(),
                      );

                      if (closeFilterOnScroll) {
                        setTrackPageScroll(true);
                      }
                    }}
                    data-automation={`toggle${field.id}Panel`}
                  >
                    <Box
                      component="span"
                      position="absolute"
                      borderRadius="full"
                      inset={0}
                      opacity={isActiveOrSelected ? undefined : 0}
                      transition="fast"
                      className={styles.summarySelectedBg}
                    />
                    <HiddenVisually>
                      {t('Show {field} refinements.', {
                        field: field.ariaLabel,
                      })}
                    </HiddenVisually>
                    <Box display="flex" position="relative">
                      <Text size="small">
                        <span
                          className={
                            isActiveOrSelected
                              ? styles.selectedSummary
                              : undefined
                          }
                        >
                          {field.summarySentence ? (
                            <span
                              aria-hidden
                            >{`${field.summarySentence} `}</span>
                          ) : (
                            ''
                          )}
                          {field.label}{' '}
                          <IconChevron
                            direction={
                              activeFieldId === field.id ? 'up' : 'down'
                            }
                          />
                        </span>
                      </Text>
                    </Box>
                    {field.status && (
                      <Box className={styles.status}>
                        <Status
                          label={field.status.label}
                          isActive={activeFieldId === field.id}
                          isSelected={field.isSelected ?? false}
                        />
                      </Box>
                    )}
                  </Box>
                </Box>
              );
            })}

            {/* Must follow individual refinement toggles to layer on top */}
            <Box
              component="label"
              display={isAnyFieldActive ? 'block' : 'none'}
              position="absolute"
              inset={0}
              zIndex={1}
              htmlFor="RefineBar--Done"
              onClick={() => setActiveFieldId(null)}
              data-automation="refineBarToggleClose"
            >
              <HiddenVisually>{t('Hide refinements')}</HiddenVisually>
            </Box>
          </Box>
        </Box>

        {/* Must follow the refine summary to layer on top */}
        <Box
          component="input"
          id="RefineBar--Done"
          opacity={0}
          position="absolute"
          cursor="pointer"
          type="radio"
          name={fieldName}
          onChange={() => setActiveFieldId(null)}
        />
        <Box
          component="label"
          position="fixed"
          inset={0}
          display={isAnyFieldActive ? 'block' : 'none'}
          htmlFor="RefineBar--Done"
          data-automation="refineBarClickOutClose"
          onClick={() => {
            setActiveFieldId(null);

            if (closeFilterOnScroll) {
              setTrackPageScroll(false);
            }
          }}
        >
          <HiddenVisually>{t('Hide refinements')}</HiddenVisually>
        </Box>

        {/* Must follow the click outside done label to layer on top */}
        {fields.map((field) => {
          const fieldComponent = memoisedFieldComponents[field.id];
          return (
            <Box
              key={field.id}
              background="surface"
              boxShadow="medium"
              display={activeFieldId === field.id ? 'block' : 'none'}
              className={styles.refinementPanel}
              onMouseEnter={() => {
                setMouseInPopup(true);
              }}
              onMouseLeave={() => {
                setMouseInPopup(false);
              }}
            >
              <Box
                position="absolute"
                inset={0}
                pointerEvents="none"
                borderRadius="xlarge"
                boxShadow="borderNeutralLight"
              />
              {fieldComponent}
            </Box>
          );
        })}
      </Box>
    </>
  );
}
