import { useExperimentValue } from '@seek/candidate-feature-flag';
import { isHomepagePath } from '@seek/seek-jobs-seo';
import { useEffect, useRef } from 'react';

import {
  type AutoFocusWhereExperiment,
  AUTO_FOCUS_WHERE_FEATURE_FLAG,
} from 'src/config/experiments';
import { useSelector } from 'src/store/react';
import { selectIsResultsLoading, selectPathname } from 'src/store/selectors';

export const useKeywordFieldProps = ({
  search,
  isTooltipShown = false,
}: {
  search: () => void;
  isTooltipShown: boolean;
}) => {
  const wasSearched = useRef(false);

  const resultsLoading = useSelector(selectIsResultsLoading);

  useEffect(() => {
    if (resultsLoading) wasSearched.current = true;
  }, [resultsLoading]);

  const autoFocusWhereOnSearchBlur =
    useExperimentValue<AutoFocusWhereExperiment>(AUTO_FOCUS_WHERE_FEATURE_FLAG);

  const currentPath = useSelector(selectPathname);
  const whereRef = useRef<HTMLInputElement | null>(null);

  const keywordDefocus = () => {
    if (isHomepagePath(currentPath) && autoFocusWhereOnSearchBlur)
      setTimeout(() => {
        if (!wasSearched.current) whereRef.current?.focus();

        wasSearched.current = false;
      }, 200);
    else {
      search();
    }
  };

  return {
    whereRef,
    keywordFieldProps: {
      onSuggestionSelected: keywordDefocus,
      onInputBlur: keywordDefocus,
      onReturn: keywordDefocus,
      onClear: autoFocusWhereOnSearchBlur ? () => {} : search,
      isTooltipShown,
    },
    keywordDefocus,
  };
};
