import type {
  FeatureFlagConfig,
  FeatureType,
} from '@seek/candidate-feature-flag';

// This will act as a kill switch for the GenAI chat on the homepage
export const LD_HOMEPAGE_GENAI_CHAT_FEATURE_FLAG = 'toggle-homepage-genai-chat';
const ldHomepageGenAIChatFeatureFlag = {
  key: LD_HOMEPAGE_GENAI_CHAT_FEATURE_FLAG,
  title: 'LD Homepage GenAI Chat',
  type: 'boolean',
} satisfies FeatureFlagConfig;
export type LDHomepageGenAIChatFeatureFlag = FeatureType<
  typeof ldHomepageGenAIChatFeatureFlag
>;

/**
 * All feature flags in this array will be fetched from launch darkly (via the candidate graph)
 * as chalice server-side renders
 */
export const featureFlagsToFetch: FeatureFlagConfig[] = [
  ldHomepageGenAIChatFeatureFlag,
];
