import { gql, useQuery } from '@apollo/client';
import type { JobTag } from '@seek/chalice-types';
import { useHubble } from '@seek/hubble';
import { type ComponentProps, useCallback, useMemo, useState } from 'react';

import {
  type GetHomeRecommendationsQuery,
  type GetHomeRecommendationsQueryVariables,
  type RecommendedGlobalJobFragmentFragment,
  GetHomeRecommendationsDocument,
} from 'src/graphql/graphql';
import { useTimezone } from 'src/hooks/useTimezone';
import { logger } from 'src/modules/logger';
import { useIsHomepageGenAIChatKillSwitchEnabled } from 'src/store/featureFlags/hooks';
import { useSelector } from 'src/store/react';
import {
  selectServerAuthenticated,
  selectSolId,
  selectHasInitialSolIdCookie,
} from 'src/store/selectors';
import type { ApolloCacheType } from 'src/types/apollo';
import { useMelwaysLocale, useMelwaysZone } from 'src/utils/melwaysHelper';

import { useCareerFeedAIAssistant } from '../CareerFeedAIAssistant/hooks/useCareerFeedAIAssistant';
import type JobCard from '../SharedComponent/JobCard/JobCard';

export const TOP_SECTION_LIMIT = 3;
export const RECOMMENDATIONS_LIMIT = 50;

export type RecommendedJobs = NonNullable<
  NonNullable<
    NonNullable<GetHomeRecommendationsQuery['viewer']>['recommendations']
  >['recommendedGlobalJobs']
>;

type RefreshReturnType =
  | { success: true; jobsCount: number }
  | { success: false; error: Error };

const mapRecommendedJobs = (
  jobs?: RecommendedJobs['globalJobs'],
): Array<ComponentProps<typeof JobCard>['jobInfo']> =>
  jobs?.map((job) => {
    const {
      job: { advertiser, id, listedAt, location, products, salary, title },
      solMetadata,
      tags,
      isSaved,
    } = job;

    return {
      advertiserName: advertiser.name,
      id,
      isSaved,
      listingDateLabel: listedAt?.label,
      locationLabel: location.label,
      salaryCurrencyLabel: salary?.currencyLabel ?? undefined,
      salaryLabel: salary?.label,
      solMetadata,
      srcLogo: products?.branding?.logo?.url,
      tags: (tags as JobTag[]) ?? undefined,
      title,
      bullets: products?.bullets ?? undefined,
    };
  }) || [];

/**
 * We're temporarily keeping this hook here until we're confident this approach is
 * feasible for both Data Tracking and Discover teams.
 * Other GQL queries or APIs that rely on the visitorId may end up needing to use this hook.
 *
 * @returns The visitorId from the Hubble context if on the client, or from the server `sol_id` cookie if on the server.
 */
export const useVisitorId = () => {
  const serverVisitorId = useSelector(selectSolId);
  const hasInitialSolId = useSelector(selectHasInitialSolIdCookie);
  const clientVisitorId = useHubble().visitorId();

  if (ENV.SERVER) {
    return hasInitialSolId ? serverVisitorId : undefined;
  }

  return clientVisitorId;
};

export const useHomeRecommendations = () => {
  const locale = useMelwaysLocale();
  const zone = useMelwaysZone();
  const visitorId = useVisitorId();
  const timezone = useTimezone();
  const isServerAuthenticated = useSelector(selectServerAuthenticated);
  const [isRefreshLoading, setRefreshLoading] = useState(false);
  const { persistedCandidateChatContext } = useCareerFeedAIAssistant();
  // This will make suer that the initial chat context is only used once, and the recs will not be updated when the context changes
  // When the context changed, we will call the `refresh()` separately to get the new recs on the AI Assistant
  const [initialChatContext] = useState(persistedCandidateChatContext ?? null);
  const isHomepageGenAIChatKillSwitchEnabled =
    useIsHomepageGenAIChatKillSwitchEnabled();

  const { data, loading, error, refetch } = useQuery(
    GetHomeRecommendationsDocument,
    {
      variables: {
        zone,
        visitorId,
        limit: RECOMMENDATIONS_LIMIT,
        timezone,
        locale,
        candidateChatContext: isHomepageGenAIChatKillSwitchEnabled
          ? null
          : initialChatContext,
      },
      ssr: isServerAuthenticated && Boolean(visitorId),
    },
  );

  const recommendedJobs = data?.viewer?.recommendations?.recommendedGlobalJobs;
  const jobs = useMemo(
    () => mapRecommendedJobs(recommendedJobs?.globalJobs),
    [recommendedJobs?.globalJobs],
  );
  const refresh = useCallback(
    async (
      args?: Partial<GetHomeRecommendationsQueryVariables>,
    ): Promise<RefreshReturnType> => {
      setRefreshLoading(true);
      try {
        const result = await refetch(args);
        setRefreshLoading(false);

        if (result.error) {
          return {
            success: false,
            error: result.error,
          };
        }

        return {
          success: true,
          jobsCount:
            result.data.viewer?.recommendations?.recommendedGlobalJobs
              ?.totalCount ?? 0,
        };
      } catch (e) {
        logger.error(
          {
            err: e as Error,
          },
          'Error while refreshing',
        );
        setRefreshLoading(false);
        return {
          success: false,
          error: e as Error,
        };
      }
    },
    [refetch],
  );

  return {
    rawRecommendedJobs: recommendedJobs,
    jobs,
    loading: loading || isRefreshLoading,
    isRefreshLoading,
    solMetadata: recommendedJobs?.solMetadata,
    error,
    refresh,
  };
};

export const useUpdateRecommendedGlobalJobCache = () => (jobId?: string) => {
  if (!jobId) {
    return undefined;
  }

  const update = (isSaved: boolean) => (cache: ApolloCacheType) => {
    const targetJob = cache.readFragment<RecommendedGlobalJobFragmentFragment>({
      id: `RecommendedGlobalJob:${jobId}`,
      fragment: gql`
        fragment RecommendedGlobalJobFragment on RecommendedGlobalJob {
          id
          isSaved
        }
      `,
    });

    if (!targetJob || targetJob.isSaved === isSaved) {
      return;
    }

    cache.modify({
      id: cache.identify(targetJob),
      fields: {
        isSaved(_originalValue: boolean) {
          return isSaved;
        },
      },
    });
  };

  return { setSaved: update(true), setUnsave: update(false) };
};
