import { isCancelled } from '@seek/ca-http-client';
import type {
  SearchParams,
  SearchResultJobV5,
  SearchResultLocationSuggestionV5,
  SearchResultLocationV5,
  SearchResultV5,
} from '@seek/chalice-types';
import { hashToUserQueryId } from '@seek/seek-jobs-analytics';
import { normaliseSearchQuery } from '@seek/seek-jobs-seo';
import { addJobDetailsMetadata, addTags } from '@seek/sol-js';
import type { AxiosError } from 'axios';
import get from 'lodash/get';

import isSuburb from 'src/modules/locations';
import { logger } from 'src/modules/logger';
// @ts-expect-error: non-ts file
import pixelPromise from 'src/modules/pixel-promise';
import { searchResultsViewModel } from 'src/modules/refine-job-search';
import { searchResultsPageRegex } from 'src/modules/routes-regexp';
import { isTimeoutError } from 'src/modules/seek-api-request';
import createApiClient from 'src/modules/seek-jobs-api-client';
import { SUBMIT_SEARCH } from 'src/store/search/types';

import { selectExperimentsAsSolTags } from '../experiments/experimentHelpers';
import { LOCATION_CHANGED } from '../location/types';
import type { ChaliceStore, TypedAction, TypedThunkAction } from '../types';
import { selectAuthenticated } from '../user/selectors';

// @ts-expect-error: non-ts file
import { getDecodedTokenWithoutIndex } from './results.token';
import { getIsFeatured, getPageSize } from './resultsHelpers';
import { selectSearchSource } from './selectors';
import {
  CLEAR_DYNAMIC_PILLS,
  CLEAR_NEW_SINCE,
  CLEAR_RESULTS,
  CLEAR_RESULTS_MOBILE_VIEW,
  FETCH_SEARCH_RESULTS_ERROR,
  GET_RESULTS_BEGIN,
  GET_RESULTS_ERROR,
  GET_RESULTS_ERROR_CANCELLATION,
  GET_RESULTS_SUCCESS_V5,
  type GetResultsSuccessActionV5,
  JORA_RESULT_LINK_CLICKED,
  MORE_OPTIONS,
  type RelatedSearch,
  RESULT_LINK_CLICKED,
  type ResultsState,
  SAVE_SOL_REFERENCES,
  SAVE_SOL_REFERENCES_FOR_LOGGED_IN_HOME_RECS,
  SAVE_SOL_REFERENCES_FOR_LOGGED_OUT_HOME_RECS,
  SAVE_SOL_REFERENCES_FOR_RECS,
  type SaveSolReferencesAction,
  type SaveSolReferencesForLoggedInRecsAction,
  type SaveSolReferencesForLoggedOutRecsAction,
  type SaveSolReferencesForRecsAction,
  type SearchViewModel,
  SET_SELECTED_JOB_ID,
  SET_TITLE_TAG_SHORT_LOCATION_NAME,
  SET_TITLE_TAG_TEST_TITLE,
  type SetSelectedJobIdAction,
  type SetTitleTagShortLocationName,
  type SetTitleTagTestTitle,
  type SolReferenceKeyLookup,
  UPDATE_SEARCH_SOURCE,
} from './types';

const api = createApiClient();

const EMPTY_RELATED_SEARCH: RelatedSearch[] = [];

export const SELECTED_JOB_ID_SEARCH_QUERY_KEY = 'jobId';

export const initialState: ResultsState = {
  results: null,
  isLoading: false,
  isError: false,
  source: '',
  companySuggestion: undefined,
  locationWhere: null,
  title: '',
  totalCountNewToYou: null,
  totalCount: null,
  totalPages: 0,
  jobIds: [],
  lastPage: 0,
  sortMode: null,
  solReferenceKeys: [],
  hidden: false,
  relatedSearches: EMPTY_RELATED_SEARCH,
  facets: {},
  titleTagShortLocationName: '',
  titleTagTestTitle: '',
  searchSource: '',
};

export interface CalculateResultsArgs {
  query?: SearchParams | any;
  results?: SearchResultJobV5[] | null;
  location?: SearchResultLocationV5;
  totalCount?: number;
  locationSuggestions?: SearchResultLocationSuggestionV5[];
}

const solKeyTypes = {
  SAVE_SOL_REFERENCES_FOR_RECS: 'competitivePlacement',
  SAVE_SOL_REFERENCES_FOR_LOGGED_IN_HOME_RECS: 'loggedInHomeRecs',
  SAVE_SOL_REFERENCES_FOR_LOGGED_OUT_HOME_RECS: 'loggedOutHomeRecs',
};

const calculateResults = (args: CalculateResultsArgs = {}): SearchViewModel => {
  const {
    query = {},
    results = null,
    location,
    totalCount,
    locationSuggestions,
  } = args;
  const page = query.page || '1';

  const { jobs, ...viewModel } = searchResultsViewModel({
    page,
    results,
    location,
    totalCount,
    locationSuggestions,
    searchParams: query,
  }) as SearchViewModel;

  return {
    ...viewModel,
    jobs: jobs && jobs.length > 0 ? jobs : null,
  };
};

export default function reducer(
  state: ResultsState = initialState,
  action: TypedAction,
): ResultsState {
  switch (action.type) {
    case MORE_OPTIONS: {
      return {
        ...state,
        hidden: true,
      };
    }

    case CLEAR_NEW_SINCE: {
      return {
        ...state,
        newSince: undefined,
      };
    }

    case CLEAR_DYNAMIC_PILLS: {
      return {
        ...state,
        pills: [],
      };
    }

    case CLEAR_RESULTS_MOBILE_VIEW: {
      return {
        ...state,
        jobIds: [],
        source: '',
        isError: false,
        companySuggestion: undefined,
        relatedSearches: EMPTY_RELATED_SEARCH,
        hidden: false,
      };
    }

    case CLEAR_RESULTS:
    case GET_RESULTS_BEGIN: {
      return {
        ...state,
        jobIds: [],
        source: '',
        isLoading: true,
        isError: false,
        companySuggestion: undefined,
        relatedSearches: EMPTY_RELATED_SEARCH,
        hidden: false,
      };
    }

    case SUBMIT_SEARCH: {
      return {
        ...state,
        isLoading: true,
        solReferenceKeys: [],
        titleTagShortLocationName: '', // Reset server rendered short location name when submit search in client to prevent showing wrong location description
        titleTagTestTitle: '', // Reset server rendered test title when submit search in client to prevent showing wrong test title
        searchSource: '', // Reset results of showing searchSource
      };
    }

    case LOCATION_CHANGED: {
      const { pathname, query } = action.payload.location;
      const isSearchResultPage = searchResultsPageRegex.test(pathname || '');
      const isHomepage = pathname === '/';
      const homePageUpdates = isHomepage
        ? {
            title: '', // Use default localised title for home page
            results: null, // Reset results to get a fresh list when returning to SERP
          }
        : {};
      return {
        ...state,
        ...homePageUpdates,
        selectedJobId: isSearchResultPage
          ? query?.[SELECTED_JOB_ID_SEARCH_QUERY_KEY]
          : undefined,
      };
    }

    case UPDATE_SEARCH_SOURCE: {
      const { searchSource } = action.payload;
      return {
        ...state,
        searchSource,
      };
    }

    case GET_RESULTS_SUCCESS_V5: {
      const { query, currentPageNumber, userQueryId, relatedSearches } =
        action.payload;

      const {
        data,
        totalCount = 0,
        totalCountNewToYou = 0,
        location,
        sortModes = null,
        canonicalCompany,
        joraCrossLink,
        info,
        solMetadata,
        facets,
        gptTargeting,
      } = action.payload.result;

      const suggestions = action.payload.result.suggestions;

      const locationSuggestions = suggestions?.location;
      const companySuggestion = suggestions?.company;
      const pills = suggestions?.pills;

      const locationWhere =
        location === undefined ||
        location.description === 'All Australia' ||
        location.description === 'All New Zealand'
          ? ''
          : location.description;

      const tracking = get(action.payload, 'result.data[0].tracking');
      const uniqueSearchToken = getDecodedTokenWithoutIndex(tracking);
      const { source, newSince } = info || {};
      const solMetadataString = JSON.stringify(solMetadata || {});
      const isRadialFilterShown = isSuburb(location);
      const isRadialFilterNudgeShown =
        currentPageNumber === 2 &&
        totalCount >= 50 &&
        isRadialFilterShown &&
        !query.hasOwnProperty('distance');
      const jobIds = data
        ? data.map((job: SearchResultJobV5) => `${job.id}`)
        : [];

      return {
        ...state,
        results: calculateResults({
          query,
          results: data,
          location,
          totalCount,
          locationSuggestions,
        }),
        jobIds,
        newSince,
        isLoading: false,
        isError: false,
        source,
        companySuggestion,
        canonicalCompany,
        joraCrossLink,
        location,
        locationWhere,
        totalCount,
        totalCountNewToYou,
        userQueryId,
        sortMode: sortModes,
        relatedSearches,
        uniqueSearchToken,
        solMetadata,
        solMetadataString,
        isRadialFilterShown,
        isRadialFilterNudgeShown,
        facets,
        pills,
        gptTargeting,
      };
    }

    case GET_RESULTS_ERROR: {
      return {
        ...state,
        results: calculateResults(),
        jobIds: [],
        source: '',
        isLoading: false,
        isError: true,
        title: 'Error loading results',
        lastPage: undefined,
        relatedSearches: EMPTY_RELATED_SEARCH,
      };
    }

    case SAVE_SOL_REFERENCES: {
      const jobs = state.results?.jobs || [];

      const refs = !ENV.SERVER
        ? addJobDetailsMetadata(jobs).map<SolReferenceKeyLookup>(
            (key, index) => {
              const { id, displayType, ...job } = jobs[index];
              const isFeatured = job.isFeatured;
              return {
                hash: key,
                isFeaturedOrDisplayKeyType: getIsFeatured({
                  displayType,
                  isFeatured,
                }),
                id,
              };
            },
          )
        : [];
      return {
        ...state,
        solReferenceKeys: refs,
      };
    }

    case SAVE_SOL_REFERENCES_FOR_RECS:
    case SAVE_SOL_REFERENCES_FOR_LOGGED_OUT_HOME_RECS:
    case SAVE_SOL_REFERENCES_FOR_LOGGED_IN_HOME_RECS: {
      const jobs = action.payload.recommendations?.map(
        ({ job, solMetadata }) => ({
          job,
          solMetadata: {
            ...solMetadata,
            jobId: job.id,
          },
        }),
      );

      const generateRefs = () =>
        addJobDetailsMetadata(jobs).map<SolReferenceKeyLookup>((key, index) => {
          const {
            job: { id },
          } = jobs[index];
          return {
            hash: key,
            isFeaturedOrDisplayKeyType: solKeyTypes[action.type] as
              | 'loggedOutHomeRecs'
              | 'loggedInHomeRecs'
              | 'competitivePlacement',
            id,
          };
        });

      const refs = !ENV.SERVER ? generateRefs() : [];

      return {
        ...state,
        solReferenceKeys: refs,
      };
    }

    case SET_SELECTED_JOB_ID: {
      const { jobId } = action.payload;
      return {
        ...state,
        selectedJobId: jobId,
      };
    }

    case SET_TITLE_TAG_SHORT_LOCATION_NAME: {
      const { titleTagShortLocationName } = action.payload;
      return {
        ...state,
        titleTagShortLocationName,
      };
    }

    case SET_TITLE_TAG_TEST_TITLE: {
      const { titleTagTestTitle } = action.payload;
      return {
        ...state,
        titleTagTestTitle,
      };
    }

    default: {
      return state;
    }
  }
}

export const moreOptions = (): TypedAction => ({
  type: MORE_OPTIONS,
  meta: {
    hotjar: 'More Options Clicked',
  },
});

const fetchCount =
  ({
    apiQuery = {},
    brand,
    country = 'AU',
    zone = 'anz-1',
    cookies,
    userQueryId,
    userAgent,
    tries = 2,
    timeout,
    visitorId,
    serverProps,
    isAuthenticated = false,
    newSince,
  }: any): TypedThunkAction<Promise<number>> =>
  (dispatch, getState) => {
    const state = getState();
    const {
      user: { testHeaders, userClientId, sessionId },
      appConfig: { zoneFeatures, locale },
    } = state;

    const pageSize = getPageSize(zoneFeatures);

    return api.jobs
      .count({
        brand,
        searchParams: { ...apiQuery, pageSize },
        country,
        zone,
        cookies,
        requestId: state.location.requestId,
        timeout,
        solId: visitorId,
        userQueryId,
        userClientId,
        userAgent,
        sessionId,
        serverProps,
        testHeaders,
        locale,
        isAuthenticated,
        newSince,
      })
      .catch((err: AxiosError) => {
        dispatch({
          type: FETCH_SEARCH_RESULTS_ERROR,
        });

        const retryUserQueryId = hashToUserQueryId({
          query: apiQuery.normalisedQuery,
        });

        if (get(err, 'response.status') === 400) {
          return {
            totalCount: 0,
            data: [],
          } as any;
        }

        if (isTimeoutError(err) || get(err, 'response.status', 0) >= 500) {
          if (tries === 1) {
            throw err;
          }

          return fetchCount({
            apiQuery,
            country,
            zone,
            cookies,
            userQueryId: retryUserQueryId,
            visitorId,
            tries: tries - 1,
            serverProps,
            isAuthenticated,
          })(dispatch, getState, null);
        }

        throw err;
      });
  };

const fetchSearchResults =
  ({
    apiQuery = {},
    brand,
    country = 'AU',
    zone = 'anz-1',
    cookies,
    userQueryId,
    tries = 2,
    timeout,
    userAgent,
    visitorId,
    serverProps,
    isAuthenticated = false,
    relatedSearchesCount,
    newSince,
  }: any): TypedThunkAction<Promise<SearchResultV5>> =>
  (dispatch, getState) => {
    const state = getState();
    const {
      user: { testHeaders, userClientId, sessionId },
      appConfig: { zoneFeatures, locale },
      search,
    } = state;
    const source = selectSearchSource(state);

    const pageSize = getPageSize(zoneFeatures);

    return api.jobs
      .search({
        brand,
        searchParams: { ...apiQuery, pageSize },
        country,
        zone,
        cookies,
        userQueryId,
        userClientId,
        sessionId,
        requestId: state.location.requestId,
        timeout,
        userAgent,
        seekerId: get(state, 'user.seekerId'),
        solId: visitorId,
        testHeaders,
        locale,
        serverProps,
        isAuthenticated,
        relatedSearchesCount,
        baseKeywords: search?.baseKeywords,
        source,
        newSince,
      })
      .catch((err: AxiosError) => {
        dispatch({
          type: FETCH_SEARCH_RESULTS_ERROR,
        });

        const retryUserQueryId = hashToUserQueryId({
          query: apiQuery.normalisedQuery,
        });

        if (get(err, 'response.status') === 400) {
          return {
            totalCount: 0,
            data: [],
          } as any;
        }

        if (isTimeoutError(err) || get(err, 'response.status', 0) >= 500) {
          if (tries === 1) {
            throw err;
          }

          return fetchSearchResults({
            apiQuery,
            country,
            zone,
            cookies,
            userQueryId: retryUserQueryId,
            visitorId,
            tries: tries - 1,
            serverProps,
            isAuthenticated,
            newSince,
          })(dispatch, getState, null);
        }

        throw err;
      });
  };

export const getResults =
  ({
    brand,
    country,
    zone,
    path,
    query,
    cookies,
    userAgent,
    visitorId,
    serverProps,
  }: any): TypedThunkAction<Promise<void>> =>
  (dispatch, getState) => {
    const state = getState();
    const normalisedQuery = normaliseSearchQuery({ path, query });

    // SSA: If doing 'new to you' search on server block and redo
    // on client to prevent server/client mismatch
    if (ENV.SERVER && normalisedQuery.tags === 'new') {
      return Promise.resolve();
    }

    const userQueryId = hashToUserQueryId({ query: normalisedQuery });
    const isAuthenticated = selectAuthenticated(state);
    const newSince = state.results.newSince;

    dispatch({
      type: GET_RESULTS_BEGIN,
      payload: {
        query: normalisedQuery,
      },
    });

    const { requestId } = getState().location;

    const searchBaseArgs = {
      apiQuery: normalisedQuery,
      brand,
      country,
      zone,
      cookies,
      userQueryId,
      requestId,
      userAgent,
      visitorId,
      serverProps,
      isAuthenticated,
      newSince,
    };

    const fetchSearchResultsPromise =
      searchBaseArgs.apiQuery.tags === 'new' && !state.user.authenticated
        ? Promise.resolve(null)
        : fetchSearchResults({
            ...searchBaseArgs,
            serverProps,
            relatedSearchesCount: 12,
          })(dispatch, getState, null);

    // SSA Do not do a count request for new to you if we are on the server
    const fetchCountNew = ENV.SERVER
      ? Promise.resolve(-1)
      : fetchCount({
          ...searchBaseArgs,
          serverProps,
          relatedSearchesCount: 12,
          apiQuery: {
            ...normalisedQuery,
            tags: 'new',
          },
        })(dispatch, getState, null);

    const fetchCountAll = fetchCount({
      ...searchBaseArgs,
      serverProps,
      apiQuery: {
        ...normalisedQuery,
        tags: null,
      },
    })(dispatch, getState, null);

    const fetchData = Promise.all([
      fetchSearchResultsPromise,
      fetchCountAll,
      fetchCountNew,
    ]);

    return fetchData
      .then(([result, fetchCountAllResult, fetchCountNewResult]) => {
        const currentPageNumber = getState().location.pageNumber;

        const experimentTags = selectExperimentsAsSolTags(getState());
        const mappedResult = {
          ...result,
          data: result
            ? result.data.map((jobData) => ({
                ...jobData,
                branding: jobData?.branding,
                solMetadata: addTags(jobData.solMetadata, experimentTags),
              }))
            : [],
        };

        const relatedSearches = mappedResult.suggestions?.relatedSearches
          ? mappedResult.suggestions.relatedSearches
          : [];
        const locationSuggestions = mappedResult.suggestions?.location
          ? mappedResult.suggestions.location
          : [];

        const action: GetResultsSuccessActionV5 = {
          type: GET_RESULTS_SUCCESS_V5,
          payload: {
            query: normalisedQuery,
            result: {
              ...mappedResult,
              totalCount: fetchCountAllResult,
              totalCountNewToYou: fetchCountNewResult,
              // ensure the experiments are added to solmetadata
              solMetadata: {
                ...addTags(mappedResult.solMetadata, {
                  ...experimentTags,
                }),
                query: normalisedQuery,
              },
            },
            currentPageNumber,
            userQueryId,
            relatedSearches,
            locationSuggestions,
          },
        };
        dispatch(action);
      })
      .catch((err) => {
        if (isCancelled(err)) {
          dispatch({
            type: GET_RESULTS_ERROR_CANCELLATION,
            error: true,
          });
        }
        logger.error(err, 'fetchData promise was cancelled');
        throw err;
      })
      .catch((err) => {
        dispatch({
          type: GET_RESULTS_ERROR,
          error: true,
          payload: err,
        });
        logger.error(err, 'Failed to fetch search data');
      });
  };

export const resultLinkClicked =
  (job: any): TypedThunkAction =>
  (dispatch) => {
    const { joraClickTrackingUrl } = job;

    dispatch({
      type: RESULT_LINK_CLICKED,
    });

    if (joraClickTrackingUrl) {
      dispatch({
        type: JORA_RESULT_LINK_CLICKED,
      });

      return pixelPromise(joraClickTrackingUrl);
    }
    return null;
  };

export const saveSolReferencesAction = (): SaveSolReferencesAction => ({
  type: SAVE_SOL_REFERENCES,
});

export const saveSolReferencesForLoggedOutHomeRecsAction = (
  recommendations: SaveSolReferencesForLoggedOutRecsAction['payload']['recommendations'],
): SaveSolReferencesForLoggedOutRecsAction => ({
  type: SAVE_SOL_REFERENCES_FOR_LOGGED_OUT_HOME_RECS,
  payload: { recommendations },
});

export const saveSolReferencesForLoggedInHomeRecsAction = (
  recommendations: SaveSolReferencesForLoggedInRecsAction['payload']['recommendations'],
): SaveSolReferencesForLoggedInRecsAction => ({
  type: SAVE_SOL_REFERENCES_FOR_LOGGED_IN_HOME_RECS,
  payload: { recommendations },
});

export const saveSolReferencesForRecsAction = (
  recommendations: SaveSolReferencesForRecsAction['payload']['recommendations'],
): SaveSolReferencesForRecsAction => ({
  type: SAVE_SOL_REFERENCES_FOR_RECS,
  payload: { recommendations },
});

export const setSelectedJobIdAction = (
  jobId: SetSelectedJobIdAction['payload']['jobId'],
): SetSelectedJobIdAction => ({
  type: SET_SELECTED_JOB_ID,
  payload: { jobId },
});

export const clearResults = (): TypedAction => ({
  type: CLEAR_RESULTS,
});

export const clearResultsMobileView = (): TypedAction => ({
  type: CLEAR_RESULTS_MOBILE_VIEW,
});

export const clearDynamicPills = (): TypedAction => ({
  type: CLEAR_DYNAMIC_PILLS,
});

export const clearNewSince = (): TypedAction => ({
  type: CLEAR_NEW_SINCE,
});

export { fetchSearchResults as fetchSearchResultsForTest };

export const createJobLinkSolReferenceSelector =
  ({
    jobId,
    isFeaturedOrDisplayKeyType,
  }: {
    jobId: SolReferenceKeyLookup['id'];
    isFeaturedOrDisplayKeyType: SolReferenceKeyLookup['isFeaturedOrDisplayKeyType'];
  }) =>
  (state: ChaliceStore) => {
    const match = state.results.solReferenceKeys.find(
      (ref) =>
        ref.id === jobId &&
        ref.isFeaturedOrDisplayKeyType === isFeaturedOrDisplayKeyType,
    );
    return match?.hash;
  };
export const createBatchJobLinkSolReferenceSelector =
  (
    propsArray?: Array<{
      jobId: SolReferenceKeyLookup['id'];
      isFeaturedOrDisplayKeyType: SolReferenceKeyLookup['isFeaturedOrDisplayKeyType'];
    }>,
  ) =>
  (state: ChaliceStore) =>
    propsArray?.map(({ jobId, isFeaturedOrDisplayKeyType }) => {
      const match = state.results?.solReferenceKeys?.find(
        (ref) =>
          ref.id === jobId &&
          ref.isFeaturedOrDisplayKeyType === isFeaturedOrDisplayKeyType,
      );
      return match?.hash || '';
    }) ?? [];
export const setTitleTagShortLocationName = (
  shortLocationName: string,
): SetTitleTagShortLocationName => ({
  type: SET_TITLE_TAG_SHORT_LOCATION_NAME,
  payload: { titleTagShortLocationName: shortLocationName },
});

export const setTitleTagTestTitle = (
  titleTagTestTitle: string,
): SetTitleTagTestTitle => ({
  type: SET_TITLE_TAG_TEST_TITLE,
  payload: { titleTagTestTitle },
});

export const updateSearchSource = (searchSource: string): TypedAction => ({
  type: UPDATE_SEARCH_SOURCE,
  payload: { searchSource },
});
