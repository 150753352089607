import gql from 'graphql-tag';

export const SEARCH_SAVED_AND_APPLIED_JOBS = gql`
  query SearchSavedAndAppliedJobs($jobIds: [String!]!) {
    viewer {
      searchSavedJobs(jobIds: $jobIds) {
        jobId
      }
      searchAppliedJobs(jobIds: $jobIds) {
        jobId
      }
    }
  }
`;
