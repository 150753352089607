import type { ChaliceStore } from '../types';

export const selectAuthenticated = (state: ChaliceStore) =>
  state.user.authenticated === true;
export const selectServerAuthenticated = (state: ChaliceStore) =>
  state.user.serverAuthenticated === true;
export const selectIsAuthenticationResolved = (state: ChaliceStore) =>
  state.user.authenticated !== undefined;
export const selectSessionId = (state: ChaliceStore) => state.user.sessionId;
export const selectUserClientId = (state: ChaliceStore) =>
  state.user.userClientId;
export const selectUserTestHeaders = (state: ChaliceStore) =>
  state.user.testHeaders;
export const selectEventCaptureTestTags = (state: ChaliceStore) =>
  state.user.testTags;
export const selectSolId = (state: ChaliceStore) => state.user.solId;
export const selectHasInitialSolIdCookie = (state: ChaliceStore) =>
  state.user.hasInitialSolIdCookie;
export const selectTimezone = (state: ChaliceStore) => state.user.timezone;
export const selectUserAgent = (state: ChaliceStore) => state.user.userAgent;
