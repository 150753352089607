
      import { createLanguage, createTranslationFile } from '@vocab/webpack/web';
      
    const translations = createTranslationFile({
      "en": createLanguage(
        require.resolveWeak("./translations.json-en-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDb21iaW5lZCBmaWVsZHMiOiJDb21iaW5lZCBmaWVsZHMiLCJDbGFzc2lmaWNhdGlvbiI6IkNsYXNzaWZpY2F0aW9uIiwiUGF5IjoiUGF5IiwiV29yayB0eXBlIjoiV29yayB0eXBlIiwiUmVtb3RlIG9wdGlvbnMiOiJSZW1vdGUgb3B0aW9ucyIsIkxpc3RpbmcgdGltZSI6Ikxpc3RpbmcgdGltZSJ9!"),
        () => import(
          /* webpackChunkName: "en-translations" */
          "./translations.json-en-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDb21iaW5lZCBmaWVsZHMiOiJDb21iaW5lZCBmaWVsZHMiLCJDbGFzc2lmaWNhdGlvbiI6IkNsYXNzaWZpY2F0aW9uIiwiUGF5IjoiUGF5IiwiV29yayB0eXBlIjoiV29yayB0eXBlIiwiUmVtb3RlIG9wdGlvbnMiOiJSZW1vdGUgb3B0aW9ucyIsIkxpc3RpbmcgdGltZSI6Ikxpc3RpbmcgdGltZSJ9!"
        )
      ),
"en-AU": createLanguage(
        require.resolveWeak("./translations.json-en-AU-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDb21iaW5lZCBmaWVsZHMiOiJDb21iaW5lZCBmaWVsZHMiLCJDbGFzc2lmaWNhdGlvbiI6IkNsYXNzaWZpY2F0aW9uIiwiUGF5IjoiUGF5IiwiV29yayB0eXBlIjoiV29yayB0eXBlIiwiUmVtb3RlIG9wdGlvbnMiOiJSZW1vdGUgb3B0aW9ucyIsIkxpc3RpbmcgdGltZSI6Ikxpc3RpbmcgdGltZSJ9!"),
        () => import(
          /* webpackChunkName: "en-AU-translations" */
          "./translations.json-en-AU-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDb21iaW5lZCBmaWVsZHMiOiJDb21iaW5lZCBmaWVsZHMiLCJDbGFzc2lmaWNhdGlvbiI6IkNsYXNzaWZpY2F0aW9uIiwiUGF5IjoiUGF5IiwiV29yayB0eXBlIjoiV29yayB0eXBlIiwiUmVtb3RlIG9wdGlvbnMiOiJSZW1vdGUgb3B0aW9ucyIsIkxpc3RpbmcgdGltZSI6Ikxpc3RpbmcgdGltZSJ9!"
        )
      ),
"en-NZ": createLanguage(
        require.resolveWeak("./translations.json-en-NZ-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDb21iaW5lZCBmaWVsZHMiOiJDb21iaW5lZCBmaWVsZHMiLCJDbGFzc2lmaWNhdGlvbiI6IkNsYXNzaWZpY2F0aW9uIiwiUGF5IjoiUGF5IiwiV29yayB0eXBlIjoiV29yayB0eXBlIiwiUmVtb3RlIG9wdGlvbnMiOiJSZW1vdGUgb3B0aW9ucyIsIkxpc3RpbmcgdGltZSI6Ikxpc3RpbmcgdGltZSJ9!"),
        () => import(
          /* webpackChunkName: "en-NZ-translations" */
          "./translations.json-en-NZ-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDb21iaW5lZCBmaWVsZHMiOiJDb21iaW5lZCBmaWVsZHMiLCJDbGFzc2lmaWNhdGlvbiI6IkNsYXNzaWZpY2F0aW9uIiwiUGF5IjoiUGF5IiwiV29yayB0eXBlIjoiV29yayB0eXBlIiwiUmVtb3RlIG9wdGlvbnMiOiJSZW1vdGUgb3B0aW9ucyIsIkxpc3RpbmcgdGltZSI6Ikxpc3RpbmcgdGltZSJ9!"
        )
      ),
"en-HK": createLanguage(
        require.resolveWeak("./translations.json-en-HK-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDb21iaW5lZCBmaWVsZHMiOiJDb21iaW5lZCBmaWVsZHMiLCJDbGFzc2lmaWNhdGlvbiI6IkNsYXNzaWZpY2F0aW9uIiwiUGF5IjoiUGF5IiwiV29yayB0eXBlIjoiV29yayB0eXBlIiwiUmVtb3RlIG9wdGlvbnMiOiJSZW1vdGUgb3B0aW9ucyIsIkxpc3RpbmcgdGltZSI6Ikxpc3RpbmcgdGltZSJ9!"),
        () => import(
          /* webpackChunkName: "en-HK-translations" */
          "./translations.json-en-HK-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDb21iaW5lZCBmaWVsZHMiOiJDb21iaW5lZCBmaWVsZHMiLCJDbGFzc2lmaWNhdGlvbiI6IkNsYXNzaWZpY2F0aW9uIiwiUGF5IjoiUGF5IiwiV29yayB0eXBlIjoiV29yayB0eXBlIiwiUmVtb3RlIG9wdGlvbnMiOiJSZW1vdGUgb3B0aW9ucyIsIkxpc3RpbmcgdGltZSI6Ikxpc3RpbmcgdGltZSJ9!"
        )
      ),
"en-TH": createLanguage(
        require.resolveWeak("./translations.json-en-TH-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDb21iaW5lZCBmaWVsZHMiOiJDb21iaW5lZCBmaWVsZHMiLCJDbGFzc2lmaWNhdGlvbiI6IkNsYXNzaWZpY2F0aW9uIiwiUGF5IjoiUGF5IiwiV29yayB0eXBlIjoiV29yayB0eXBlIiwiUmVtb3RlIG9wdGlvbnMiOiJSZW1vdGUgb3B0aW9ucyIsIkxpc3RpbmcgdGltZSI6Ikxpc3RpbmcgdGltZSJ9!"),
        () => import(
          /* webpackChunkName: "en-TH-translations" */
          "./translations.json-en-TH-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDb21iaW5lZCBmaWVsZHMiOiJDb21iaW5lZCBmaWVsZHMiLCJDbGFzc2lmaWNhdGlvbiI6IkNsYXNzaWZpY2F0aW9uIiwiUGF5IjoiUGF5IiwiV29yayB0eXBlIjoiV29yayB0eXBlIiwiUmVtb3RlIG9wdGlvbnMiOiJSZW1vdGUgb3B0aW9ucyIsIkxpc3RpbmcgdGltZSI6Ikxpc3RpbmcgdGltZSJ9!"
        )
      ),
"en-ID": createLanguage(
        require.resolveWeak("./translations.json-en-ID-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDb21iaW5lZCBmaWVsZHMiOiJDb21iaW5lZCBmaWVsZHMiLCJDbGFzc2lmaWNhdGlvbiI6IkNsYXNzaWZpY2F0aW9uIiwiUGF5IjoiUGF5IiwiV29yayB0eXBlIjoiV29yayB0eXBlIiwiUmVtb3RlIG9wdGlvbnMiOiJSZW1vdGUgb3B0aW9ucyIsIkxpc3RpbmcgdGltZSI6Ikxpc3RpbmcgdGltZSJ9!"),
        () => import(
          /* webpackChunkName: "en-ID-translations" */
          "./translations.json-en-ID-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDb21iaW5lZCBmaWVsZHMiOiJDb21iaW5lZCBmaWVsZHMiLCJDbGFzc2lmaWNhdGlvbiI6IkNsYXNzaWZpY2F0aW9uIiwiUGF5IjoiUGF5IiwiV29yayB0eXBlIjoiV29yayB0eXBlIiwiUmVtb3RlIG9wdGlvbnMiOiJSZW1vdGUgb3B0aW9ucyIsIkxpc3RpbmcgdGltZSI6Ikxpc3RpbmcgdGltZSJ9!"
        )
      ),
"en-SG": createLanguage(
        require.resolveWeak("./translations.json-en-SG-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDb21iaW5lZCBmaWVsZHMiOiJDb21iaW5lZCBmaWVsZHMiLCJDbGFzc2lmaWNhdGlvbiI6IkNsYXNzaWZpY2F0aW9uIiwiUGF5IjoiUGF5IiwiV29yayB0eXBlIjoiV29yayB0eXBlIiwiUmVtb3RlIG9wdGlvbnMiOiJSZW1vdGUgb3B0aW9ucyIsIkxpc3RpbmcgdGltZSI6Ikxpc3RpbmcgdGltZSJ9!"),
        () => import(
          /* webpackChunkName: "en-SG-translations" */
          "./translations.json-en-SG-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDb21iaW5lZCBmaWVsZHMiOiJDb21iaW5lZCBmaWVsZHMiLCJDbGFzc2lmaWNhdGlvbiI6IkNsYXNzaWZpY2F0aW9uIiwiUGF5IjoiUGF5IiwiV29yayB0eXBlIjoiV29yayB0eXBlIiwiUmVtb3RlIG9wdGlvbnMiOiJSZW1vdGUgb3B0aW9ucyIsIkxpc3RpbmcgdGltZSI6Ikxpc3RpbmcgdGltZSJ9!"
        )
      ),
"en-MY": createLanguage(
        require.resolveWeak("./translations.json-en-MY-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDb21iaW5lZCBmaWVsZHMiOiJDb21iaW5lZCBmaWVsZHMiLCJDbGFzc2lmaWNhdGlvbiI6IkNsYXNzaWZpY2F0aW9uIiwiUGF5IjoiUGF5IiwiV29yayB0eXBlIjoiV29yayB0eXBlIiwiUmVtb3RlIG9wdGlvbnMiOiJSZW1vdGUgb3B0aW9ucyIsIkxpc3RpbmcgdGltZSI6Ikxpc3RpbmcgdGltZSJ9!"),
        () => import(
          /* webpackChunkName: "en-MY-translations" */
          "./translations.json-en-MY-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDb21iaW5lZCBmaWVsZHMiOiJDb21iaW5lZCBmaWVsZHMiLCJDbGFzc2lmaWNhdGlvbiI6IkNsYXNzaWZpY2F0aW9uIiwiUGF5IjoiUGF5IiwiV29yayB0eXBlIjoiV29yayB0eXBlIiwiUmVtb3RlIG9wdGlvbnMiOiJSZW1vdGUgb3B0aW9ucyIsIkxpc3RpbmcgdGltZSI6Ikxpc3RpbmcgdGltZSJ9!"
        )
      ),
"en-PH": createLanguage(
        require.resolveWeak("./translations.json-en-PH-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDb21iaW5lZCBmaWVsZHMiOiJDb21iaW5lZCBmaWVsZHMiLCJDbGFzc2lmaWNhdGlvbiI6IkNsYXNzaWZpY2F0aW9uIiwiUGF5IjoiUGF5IiwiV29yayB0eXBlIjoiV29yayB0eXBlIiwiUmVtb3RlIG9wdGlvbnMiOiJSZW1vdGUgb3B0aW9ucyIsIkxpc3RpbmcgdGltZSI6Ikxpc3RpbmcgdGltZSJ9!"),
        () => import(
          /* webpackChunkName: "en-PH-translations" */
          "./translations.json-en-PH-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDb21iaW5lZCBmaWVsZHMiOiJDb21iaW5lZCBmaWVsZHMiLCJDbGFzc2lmaWNhdGlvbiI6IkNsYXNzaWZpY2F0aW9uIiwiUGF5IjoiUGF5IiwiV29yayB0eXBlIjoiV29yayB0eXBlIiwiUmVtb3RlIG9wdGlvbnMiOiJSZW1vdGUgb3B0aW9ucyIsIkxpc3RpbmcgdGltZSI6Ikxpc3RpbmcgdGltZSJ9!"
        )
      ),
"id": createLanguage(
        require.resolveWeak("./translations.json-id-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDb21iaW5lZCBmaWVsZHMiOiJDb21iaW5lZCBmaWVsZHMiLCJDbGFzc2lmaWNhdGlvbiI6IkNsYXNzaWZpY2F0aW9uIiwiUGF5IjoiUGF5IiwiV29yayB0eXBlIjoiV29yayB0eXBlIiwiUmVtb3RlIG9wdGlvbnMiOiJSZW1vdGUgb3B0aW9ucyIsIkxpc3RpbmcgdGltZSI6Ikxpc3RpbmcgdGltZSJ9!"),
        () => import(
          /* webpackChunkName: "id-translations" */
          "./translations.json-id-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDb21iaW5lZCBmaWVsZHMiOiJDb21iaW5lZCBmaWVsZHMiLCJDbGFzc2lmaWNhdGlvbiI6IkNsYXNzaWZpY2F0aW9uIiwiUGF5IjoiUGF5IiwiV29yayB0eXBlIjoiV29yayB0eXBlIiwiUmVtb3RlIG9wdGlvbnMiOiJSZW1vdGUgb3B0aW9ucyIsIkxpc3RpbmcgdGltZSI6Ikxpc3RpbmcgdGltZSJ9!"
        )
      ),
"th": createLanguage(
        require.resolveWeak("./translations.json-th-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDb21iaW5lZCBmaWVsZHMiOiJDb21iaW5lZCBmaWVsZHMiLCJDbGFzc2lmaWNhdGlvbiI6IkNsYXNzaWZpY2F0aW9uIiwiUGF5IjoiUGF5IiwiV29yayB0eXBlIjoiV29yayB0eXBlIiwiUmVtb3RlIG9wdGlvbnMiOiJSZW1vdGUgb3B0aW9ucyIsIkxpc3RpbmcgdGltZSI6Ikxpc3RpbmcgdGltZSJ9!"),
        () => import(
          /* webpackChunkName: "th-translations" */
          "./translations.json-th-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDb21iaW5lZCBmaWVsZHMiOiJDb21iaW5lZCBmaWVsZHMiLCJDbGFzc2lmaWNhdGlvbiI6IkNsYXNzaWZpY2F0aW9uIiwiUGF5IjoiUGF5IiwiV29yayB0eXBlIjoiV29yayB0eXBlIiwiUmVtb3RlIG9wdGlvbnMiOiJSZW1vdGUgb3B0aW9ucyIsIkxpc3RpbmcgdGltZSI6Ikxpc3RpbmcgdGltZSJ9!"
        )
      ),
"id-ID": createLanguage(
        require.resolveWeak("./translations.json-id-ID-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDb21iaW5lZCBmaWVsZHMiOiJDb21iaW5lZCBmaWVsZHMiLCJDbGFzc2lmaWNhdGlvbiI6IkNsYXNzaWZpY2F0aW9uIiwiUGF5IjoiUGF5IiwiV29yayB0eXBlIjoiV29yayB0eXBlIiwiUmVtb3RlIG9wdGlvbnMiOiJSZW1vdGUgb3B0aW9ucyIsIkxpc3RpbmcgdGltZSI6Ikxpc3RpbmcgdGltZSJ9!"),
        () => import(
          /* webpackChunkName: "id-ID-translations" */
          "./translations.json-id-ID-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDb21iaW5lZCBmaWVsZHMiOiJDb21iaW5lZCBmaWVsZHMiLCJDbGFzc2lmaWNhdGlvbiI6IkNsYXNzaWZpY2F0aW9uIiwiUGF5IjoiUGF5IiwiV29yayB0eXBlIjoiV29yayB0eXBlIiwiUmVtb3RlIG9wdGlvbnMiOiJSZW1vdGUgb3B0aW9ucyIsIkxpc3RpbmcgdGltZSI6Ikxpc3RpbmcgdGltZSJ9!"
        )
      ),
"th-TH": createLanguage(
        require.resolveWeak("./translations.json-th-TH-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDb21iaW5lZCBmaWVsZHMiOiJDb21iaW5lZCBmaWVsZHMiLCJDbGFzc2lmaWNhdGlvbiI6IkNsYXNzaWZpY2F0aW9uIiwiUGF5IjoiUGF5IiwiV29yayB0eXBlIjoiV29yayB0eXBlIiwiUmVtb3RlIG9wdGlvbnMiOiJSZW1vdGUgb3B0aW9ucyIsIkxpc3RpbmcgdGltZSI6Ikxpc3RpbmcgdGltZSJ9!"),
        () => import(
          /* webpackChunkName: "th-TH-translations" */
          "./translations.json-th-TH-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDb21iaW5lZCBmaWVsZHMiOiJDb21iaW5lZCBmaWVsZHMiLCJDbGFzc2lmaWNhdGlvbiI6IkNsYXNzaWZpY2F0aW9uIiwiUGF5IjoiUGF5IiwiV29yayB0eXBlIjoiV29yayB0eXBlIiwiUmVtb3RlIG9wdGlvbnMiOiJSZW1vdGUgb3B0aW9ucyIsIkxpc3RpbmcgdGltZSI6Ikxpc3RpbmcgdGltZSJ9!"
        )
      ),
"en-PSEUDO": createLanguage(
        require.resolveWeak("./translations.json-en-PSEUDO-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDb21iaW5lZCBmaWVsZHMiOiJbw4fDtsO2w7ZtzILDn8Osw6zDrOC4geC4teC5ieG6veG6veG6vcaMIMaSw6zDrMOs4bq94bq94bq9xprGjMWhXSIsIkNsYXNzaWZpY2F0aW9uIjoiW8OHxprEg8SDxaHFocOsw6zGksOsw6zDp8SDxIPhua%2FDrMOsw7bDtuC4geC4teC5iV0iLCJQYXkiOiJbxqTEg8SDw73DvV0iLCJXb3JrIHR5cGUiOiJbxbTDtsO2w7bFmcS3IOG5r8O9w73Dvcal4bq94bq94bq9XSIsIlJlbW90ZSBvcHRpb25zIjoiW8WY4bq94bq9bcyCw7bDtuG5r%2BG6veG6vSDDtsO2xqXhua%2FDrMOsw7bDtuC4geC4teC5icWhXSIsIkxpc3RpbmcgdGltZSI6IlvFgcOsw6zDrMWh4bmvw6zDrMOs4LiB4Li14LmJxKMg4bmvw6zDrMOsbcyC4bq94bq94bq9XSJ9!"),
        () => import(
          /* webpackChunkName: "en-PSEUDO-translations" */
          "./translations.json-en-PSEUDO-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDb21iaW5lZCBmaWVsZHMiOiJbw4fDtsO2w7ZtzILDn8Osw6zDrOC4geC4teC5ieG6veG6veG6vcaMIMaSw6zDrMOs4bq94bq94bq9xprGjMWhXSIsIkNsYXNzaWZpY2F0aW9uIjoiW8OHxprEg8SDxaHFocOsw6zGksOsw6zDp8SDxIPhua%2FDrMOsw7bDtuC4geC4teC5iV0iLCJQYXkiOiJbxqTEg8SDw73DvV0iLCJXb3JrIHR5cGUiOiJbxbTDtsO2w7bFmcS3IOG5r8O9w73Dvcal4bq94bq94bq9XSIsIlJlbW90ZSBvcHRpb25zIjoiW8WY4bq94bq9bcyCw7bDtuG5r%2BG6veG6vSDDtsO2xqXhua%2FDrMOsw7bDtuC4geC4teC5icWhXSIsIkxpc3RpbmcgdGltZSI6IlvFgcOsw6zDrMWh4bmvw6zDrMOs4LiB4Li14LmJxKMg4bmvw6zDrMOsbcyC4bq94bq94bq9XSJ9!"
        )
      )
      });
  
      export { translations as default };
    