import {
  Badge,
  Box,
  Column,
  Columns,
  Heading,
  Link,
  Stack,
  Text,
  useResponsiveValue,
} from 'braid-design-system';
import { forwardRef } from 'react';

import * as styles from './SavedRecentSearches.css';

export interface SavedRecentSearch {
  label: string;
  count?: string;
  summary?: string;
  href: string;
}

export interface SavedRecentSearchItemProps {
  searchItem: SavedRecentSearch;
  icon: JSX.Element;
  index: number;
  onClick?: () => void;
  setHighlightedSavedRecentItem: React.Dispatch<React.SetStateAction<number>>;
  highlightedSavedRecentItem: number;
}

export const SavedRecentSearchItem = forwardRef<
  HTMLAnchorElement,
  SavedRecentSearchItemProps
>(
  (
    {
      searchItem,
      icon,
      index,
      onClick,
      setHighlightedSavedRecentItem,
      highlightedSavedRecentItem,
    },
    ref,
  ) => {
    const { label, count, summary, href } = searchItem;

    const isMobile = useResponsiveValue()({
      mobile: true,
      tablet: false,
    });
    const isWide = useResponsiveValue()({
      mobile: false,
      wide: true,
    });

    if (!href) {
      return null;
    }

    return (
      <Link
        href={href}
        onClick={onClick}
        ref={ref}
        tabIndex={-1}
        aria-label={label}
      >
        <Box
          width="full"
          className={{
            [styles.menuItem]: true,
            [styles.searchItemHighlighted]:
              index === highlightedSavedRecentItem,
          }}
          cursor="pointer"
          onMouseEnter={() => {
            setHighlightedSavedRecentItem(index);
          }}
          onMouseLeave={() => {
            setHighlightedSavedRecentItem(-1);
          }}
        >
          {/* Collapse these columns when the keyword input box is too small (i.e. only for desktop and tablet, not mobile as all three input boxes are collapsed instead */}
          <Columns
            space="small"
            alignY="center"
            collapseBelow={isMobile ? undefined : 'wide'}
          >
            {/* We have to conditionally render the icon below instead of using hideBelow = wide because the spacing is still applied when the columns are collapsed 
            even if the column is hidden which causes unwanted white space */}
            {isWide ? (
              <Column width="content">
                <Heading level="4" component="span">
                  <span className={styles.searchItemIcon}>{icon}</span>
                </Heading>
              </Column>
            ) : null}
            <Column>
              <Stack space="xxsmall">
                <Text
                  size="standard"
                  tone="neutral"
                  baseline={false}
                  maxLines={summary ? 1 : 2}
                >
                  {label}
                </Text>
                {summary ? (
                  <Text size="small" tone="secondary" maxLines={1}>
                    {summary}
                  </Text>
                ) : null}
              </Stack>
            </Column>
            {count ? (
              <Column width="content">
                <Badge tone="positive">{count}</Badge>
              </Column>
            ) : null}
          </Columns>
        </Box>
      </Link>
    );
  },
);
