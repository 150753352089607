import { getJobDetailsMetadata, type SolMetadata } from '@seek/sol-js';
import mapKeys from 'lodash/fp/mapKeys';
import identity from 'lodash/identity';
import pickBy from 'lodash/pickBy';
import { parse } from 'query-string';
import { useCallback, useMemo } from 'react';

import { useSelector } from 'src/store/react';
import { selectHash, selectQuery } from 'src/store/selectors';

interface Options {
  id: string;
  solMetadataReference?: string;
}

type TrackingInfoType = [string, SolMetadata];

export const useGetSolHash = (): (({
  id,
  solMetadataReference,
}: Options) => TrackingInfoType) => {
  // After the react router v6 upgrade, the hash will be empty in the client route, so we need to get it from the window object
  const windowHash = typeof window !== 'undefined' ? window.location?.hash : '';
  const hash = useSelector(selectHash) || windowHash;
  const query = useSelector(selectQuery);

  const getTrackingInfo = useCallback<
    ({ id, solMetadataReference }: Options) => TrackingInfoType
  >(
    ({ id, solMetadataReference }: Options) => {
      const lowercasedQuery = mapKeys(
        (k) => k.toLowerCase(),
        pickBy(query, identity),
      );

      const token = lowercasedQuery.token;

      const getSolToken = (hashString = '') => {
        const q = parse(hashString.replace('#', ''));
        return q?.sol;
      };
      const resolvedSolRef =
        solMetadataReference || lowercasedQuery.sol || getSolToken(hash);

      const tokenOrRef = token ? `?token=${token}` : `?sol=${resolvedSolRef}`;

      const urlString = `/job/${id}/apply${
        token || resolvedSolRef ? tokenOrRef : ''
      }`;

      const solMetaData =
        typeof window !== 'undefined'
          ? getJobDetailsMetadata({
              jobId: id,
              key: resolvedSolRef,
              token,
            })
          : {};

      return [urlString, solMetaData];
    },
    [query, hash],
  );

  return ({ id, solMetadataReference }: Options) =>
    getTrackingInfo({ id, solMetadataReference });
};

export const useSolHash = ({ id, solMetadataReference }: Options) => {
  const getSolHash = useGetSolHash();
  return useMemo(
    () => getSolHash({ id, solMetadataReference }),
    [getSolHash, id, solMetadataReference],
  );
};
