import {
  FeatureFlagProvider,
  FeatureFlagsController,
} from '@seek/candidate-feature-flag';
import { useMelwaysInfo } from '@seek/melways-react';
import { useTranslations, VocabProvider } from '@vocab/react';
import {
  Box,
  BraidProvider,
  Text,
  TextLinkButton,
  ToastProvider,
} from 'braid-design-system';
import seekJobsTheme from 'braid-design-system/themes/seekJobs';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Outlet } from 'react-router';
// @ts-expect-error: non-ts file
import { provideHooks } from 'redial';

import Footer from 'src/components/Footer/Footer';
import { GlobalNotificationBanner } from 'src/components/GlobalNotificationBanner';
import { GoogleOneTapWrapper } from 'src/components/GoogleOneTapWrapper/GoogleOneTapWrapper';
import Header from 'src/components/Header/Header';
import { PreviewBranchName } from 'src/components/PreviewBranchName/PreviewBranchName';
import { ScreenReaderAnnouncer } from 'src/components/ScreenReaderAnnouncer/ScreenReaderAnnouncer';
import SharedHead from 'src/components/SharedHead/SharedHead';
import { useInitialiseSignedInExperiments } from 'src/hooks/useInitialiseSignedInExperiments';
import BranchBanner from 'src/modules/BranchBanner/BranchBanner';
import { useFetchCandidateDetails } from 'src/modules/hooks/useFetchCandidateDetails';
import { scrollTo } from 'src/modules/scroll-with-callback';
import { locationHashChanged } from 'src/store/location';
import { selectHostname } from 'src/store/selectors';
import { setAlternateLinks, setCanonicalUrl } from 'src/store/seo';
import type { RedialLocals } from 'src/types/RedialLocals';
import { updateUserTestData } from 'src/utils/productionTesting/productionTesting';

import { CustomLinkForBraid } from '../components/NavLink/NavLink';

import translations from './.vocab';
import {
  InitialiseExperiments,
  useFeatureFlagProviderProps,
} from './featureFlagProviderUtils';

import * as styles from './App.css';

const hooks = {
  seo({ dispatch, country, path, query }: RedialLocals) {
    return Promise.all([
      dispatch(setCanonicalUrl({ country, path: path ?? '', query })),
      dispatch(setAlternateLinks({ country, path: path ?? '', query })),
    ]);
  },
  pageLoad({ dispatch }: RedialLocals) {
    dispatch(locationHashChanged(window.location.hash));
  },
};

const UseScreenReaderSkipLink = () => {
  const { t } = useTranslations(translations);
  const noop = () => {};

  const scrollToStartOfContent = ENV.CLIENT
    ? () => {
        const startOfContent = document.getElementById('start-of-content');
        scrollTo({
          top: startOfContent?.getBoundingClientRect().top ?? 0,
          behavior: 'smooth',
        });
        startOfContent?.focus();
      }
    : noop;

  return (
    <Text>
      <Box className={styles.screenReaderSkipLink}>
        <TextLinkButton
          onClick={(e) => {
            e.preventDefault();
            scrollToStartOfContent();
          }}
        >
          {t('Skip to content')}
        </TextLinkButton>
      </Box>
    </Text>
  );
};

const useInitialiseTestHeadersAndTags = () => {
  const dispatch = useDispatch();
  const hostName = useSelector(selectHostname);

  useEffect(() => {
    updateUserTestData(dispatch, hostName);
  }, [dispatch, hostName]);
};

const App = () => {
  const { locale } = useMelwaysInfo();

  useFetchCandidateDetails();
  useInitialiseTestHeadersAndTags();
  useInitialiseSignedInExperiments();

  return (
    <VocabProvider language={locale}>
      <FeatureFlagProvider {...useFeatureFlagProviderProps()}>
        <BraidProvider theme={seekJobsTheme} linkComponent={CustomLinkForBraid}>
          <InitialiseExperiments />
          <FeatureFlagsController />
          <GoogleOneTapWrapper />
          <PreviewBranchName />
          <GlobalNotificationBanner />
          <ToastProvider>
            <BranchBanner />
            <SharedHead />
            <UseScreenReaderSkipLink />
            <ScreenReaderAnnouncer />
            <Header />
            <div role="main">
              <Outlet />
            </div>
            <Box paddingTop="xxlarge">
              <Footer />
            </Box>
          </ToastProvider>
        </BraidProvider>
      </FeatureFlagProvider>
    </VocabProvider>
  );
};

export default provideHooks(hooks)(App);

export const hooksForTests = hooks;
