import type { Zone } from '@seek/audience-zones';

import type { TypedAction } from '../types';

import {
  UPDATE_AFTER_AUTH,
  type ExperimentsState,
  type TFeatureExperiments,
  type TSeekExperiments,
  type UpdateExperimentsAction,
} from './types';

const REMOTE_SEARCH_FILTER_GROUP_SALT = 'REMOTE_SEARCH_FILTER';
const SERP_JOB_CARD_INFO_DENSITY_1_GROUP_SALT = 'SERP_JOB_CARD_INFO_DENSITY_1';
const MATCHED_QUALITIES_GROUP_SALT = 'MATCHED_QUALITIES';
const HOMEPAGE_GENAI_CHAT_SALT = 'HOMEPAGE_GENAI_CHAT_SALT';

/**
 * This is the experiment will be added to `store: experiments` on the Server Side for all users.
 */
export const FEATURE_EXPERIMENTS: TFeatureExperiments = {
  remoteSearchFilter: {
    name: 'remote_search_filter',
    percentage: 0, // 0:0:0 => variant/control/outsider
    enableTrackingForZeroPercent: false,
    num: 4,
    targetZones: new Set<Zone>([
      'anz-1',
      'anz-2',
      'asia-1',
      'asia-3',
      'asia-4',
      'asia-5',
      'asia-6',
      'asia-7',
    ]),
    groupSalt: REMOTE_SEARCH_FILTER_GROUP_SALT,
  },
  behaviouralCuesFilters: {
    name: 'behavioural_cues_filters',
    percentage: 0, // 0:0:0 => variant/control/outsider
    enableTrackingForZeroPercent: false,
    num: 61,
    targetZones: new Set<Zone>([
      'anz-1',
      'anz-2',
      'asia-1',
      'asia-3',
      'asia-4',
      'asia-5',
      'asia-6',
      'asia-7',
    ]),
    groupSalt: REMOTE_SEARCH_FILTER_GROUP_SALT,
  },
  // This hide job card summary(teaser)
  serpJobCardInfoDensity1: {
    name: 'serp_job_card_info_density_1',
    percentage: 0, // 0:0:0 => variant/control/outsider
    enableTrackingForZeroPercent: false,
    num: 60,
    targetZones: new Set<Zone>([
      'anz-1',
      'anz-2',
      'asia-1',
      'asia-3',
      'asia-4',
      'asia-5',
      'asia-6',
      'asia-7',
    ]),
    groupSalt: SERP_JOB_CARD_INFO_DENSITY_1_GROUP_SALT,
  },
  matchedQualities: {
    name: 'matched_qualities',
    percentage: 100, // 100:0:0 => variant/control/outsider
    enableTrackingForZeroPercent: false,
    num: 72,
    targetZones: new Set<Zone>([
      'anz-1',
      'anz-2',
      'asia-1',
      'asia-3',
      'asia-4',
      'asia-5',
      'asia-6',
      'asia-7',
    ]),
    groupSalt: MATCHED_QUALITIES_GROUP_SALT,
  },
};

/**
 * This is the experiment will be added to `store: experiments` after user authentication on Client Side.
 *
 * For logged out users, this experiment will not be available.
 */
export const FEATURE_EXPERIMENTS_AFTER_AUTH: TFeatureExperiments = {
  homepageRecsBadgingExpiringSoonEarlyApplicant: {
    name: 'web_badge_expiring_soon',
    percentage: 50, // 50:50:0 => variant/control/outsider
    enableTrackingForZeroPercent: false,
    num: 70,
    targetZones: new Set<Zone>([
      'anz-1',
      'anz-2',
      'asia-1',
      'asia-3',
      'asia-4',
      'asia-5',
      'asia-6',
      'asia-7',
    ]),
  },
  homepageGenAIChat: {
    name: 'web_gen_ai_chat_in_feed_1%',
    percentage: 1, // 1:1:98 variant/control/outsider
    enableTrackingForZeroPercent: false,
    num: 68,
    targetZones: new Set<Zone>(['anz-1']),
    groupSalt: HOMEPAGE_GENAI_CHAT_SALT,
  },
};

export const experimentsAfterAuthIntialised = (
  experiments: TSeekExperiments,
): UpdateExperimentsAction => ({
  type: UPDATE_AFTER_AUTH,
  payload: experiments,
});

export const initialState: ExperimentsState = {};

export const reducer = (state: ExperimentsState = {}, action: TypedAction) => {
  switch (action.type) {
    case UPDATE_AFTER_AUTH: {
      return {
        ...state,
        ...action.payload,
      };
    }

    default:
      return state;
  }
};
