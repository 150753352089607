
      import { createLanguage, createTranslationFile } from '@vocab/webpack/web';
      
    const translations = createTranslationFile({
      "en": createLanguage(
        require.resolveWeak("./translations.json-en-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJSZWNlbnQgc2VhcmNoZXMiOiJSZWNlbnQgc2VhcmNoZXMiLCJTYXZlZCBzZWFyY2hlcyI6IlNhdmVkIHNlYXJjaGVzIiwiU2VlIGFsbCAoe2NvdW50fSkiOiJTZWUgYWxsICh7Y291bnR9KSAiLCJ7ZGlzcGxheUNvdW50TGFiZWx9IG5ldyI6IntkaXNwbGF5Q291bnRMYWJlbH0gbmV3Iiwie2ZpcnN0RmFjZXR9IGluIHtzZWNvbmRGYWNldH0iOiJ7Zmlyc3RGYWNldH0gaW4ge3NlY29uZEZhY2V0fSIsIkFsbCBqb2JzIjoiQWxsIGpvYnMiLCJBdXN0cmFsaWEiOiJBdXN0cmFsaWEiLCJIb25nIEtvbmciOiJIb25nIEtvbmciLCJJbmRvbmVzaWEiOiJJbmRvbmVzaWEiLCJNYWxheXNpYSI6Ik1hbGF5c2lhIiwiTmV3IFplYWxhbmQiOiJOZXcgWmVhbGFuZCIsIlBoaWxpcHBpbmVzIjoiUGhpbGlwcGluZXMiLCJTaW5nYXBvcmUiOiJTaW5nYXBvcmUiLCJUaGFpbGFuZCI6IlRoYWlsYW5kIiwie2NsYXNzaWZpY2F0aW9uc0NvdW50fSBjbGFzc2lmaWNhdGlvbnMiOiJ7Y2xhc3NpZmljYXRpb25zQ291bnR9IGNsYXNzaWZpY2F0aW9ucyJ9!"),
        () => import(
          /* webpackChunkName: "en-translations" */
          "./translations.json-en-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJSZWNlbnQgc2VhcmNoZXMiOiJSZWNlbnQgc2VhcmNoZXMiLCJTYXZlZCBzZWFyY2hlcyI6IlNhdmVkIHNlYXJjaGVzIiwiU2VlIGFsbCAoe2NvdW50fSkiOiJTZWUgYWxsICh7Y291bnR9KSAiLCJ7ZGlzcGxheUNvdW50TGFiZWx9IG5ldyI6IntkaXNwbGF5Q291bnRMYWJlbH0gbmV3Iiwie2ZpcnN0RmFjZXR9IGluIHtzZWNvbmRGYWNldH0iOiJ7Zmlyc3RGYWNldH0gaW4ge3NlY29uZEZhY2V0fSIsIkFsbCBqb2JzIjoiQWxsIGpvYnMiLCJBdXN0cmFsaWEiOiJBdXN0cmFsaWEiLCJIb25nIEtvbmciOiJIb25nIEtvbmciLCJJbmRvbmVzaWEiOiJJbmRvbmVzaWEiLCJNYWxheXNpYSI6Ik1hbGF5c2lhIiwiTmV3IFplYWxhbmQiOiJOZXcgWmVhbGFuZCIsIlBoaWxpcHBpbmVzIjoiUGhpbGlwcGluZXMiLCJTaW5nYXBvcmUiOiJTaW5nYXBvcmUiLCJUaGFpbGFuZCI6IlRoYWlsYW5kIiwie2NsYXNzaWZpY2F0aW9uc0NvdW50fSBjbGFzc2lmaWNhdGlvbnMiOiJ7Y2xhc3NpZmljYXRpb25zQ291bnR9IGNsYXNzaWZpY2F0aW9ucyJ9!"
        )
      ),
"en-AU": createLanguage(
        require.resolveWeak("./translations.json-en-AU-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJSZWNlbnQgc2VhcmNoZXMiOiJSZWNlbnQgc2VhcmNoZXMiLCJTYXZlZCBzZWFyY2hlcyI6IlNhdmVkIHNlYXJjaGVzIiwiU2VlIGFsbCAoe2NvdW50fSkiOiJTZWUgYWxsICh7Y291bnR9KSAiLCJ7ZGlzcGxheUNvdW50TGFiZWx9IG5ldyI6IntkaXNwbGF5Q291bnRMYWJlbH0gbmV3Iiwie2ZpcnN0RmFjZXR9IGluIHtzZWNvbmRGYWNldH0iOiJ7Zmlyc3RGYWNldH0gaW4ge3NlY29uZEZhY2V0fSIsIkFsbCBqb2JzIjoiQWxsIGpvYnMiLCJBdXN0cmFsaWEiOiJBdXN0cmFsaWEiLCJIb25nIEtvbmciOiJIb25nIEtvbmciLCJJbmRvbmVzaWEiOiJJbmRvbmVzaWEiLCJNYWxheXNpYSI6Ik1hbGF5c2lhIiwiTmV3IFplYWxhbmQiOiJOZXcgWmVhbGFuZCIsIlBoaWxpcHBpbmVzIjoiUGhpbGlwcGluZXMiLCJTaW5nYXBvcmUiOiJTaW5nYXBvcmUiLCJUaGFpbGFuZCI6IlRoYWlsYW5kIiwie2NsYXNzaWZpY2F0aW9uc0NvdW50fSBjbGFzc2lmaWNhdGlvbnMiOiJ7Y2xhc3NpZmljYXRpb25zQ291bnR9IGNsYXNzaWZpY2F0aW9ucyJ9!"),
        () => import(
          /* webpackChunkName: "en-AU-translations" */
          "./translations.json-en-AU-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJSZWNlbnQgc2VhcmNoZXMiOiJSZWNlbnQgc2VhcmNoZXMiLCJTYXZlZCBzZWFyY2hlcyI6IlNhdmVkIHNlYXJjaGVzIiwiU2VlIGFsbCAoe2NvdW50fSkiOiJTZWUgYWxsICh7Y291bnR9KSAiLCJ7ZGlzcGxheUNvdW50TGFiZWx9IG5ldyI6IntkaXNwbGF5Q291bnRMYWJlbH0gbmV3Iiwie2ZpcnN0RmFjZXR9IGluIHtzZWNvbmRGYWNldH0iOiJ7Zmlyc3RGYWNldH0gaW4ge3NlY29uZEZhY2V0fSIsIkFsbCBqb2JzIjoiQWxsIGpvYnMiLCJBdXN0cmFsaWEiOiJBdXN0cmFsaWEiLCJIb25nIEtvbmciOiJIb25nIEtvbmciLCJJbmRvbmVzaWEiOiJJbmRvbmVzaWEiLCJNYWxheXNpYSI6Ik1hbGF5c2lhIiwiTmV3IFplYWxhbmQiOiJOZXcgWmVhbGFuZCIsIlBoaWxpcHBpbmVzIjoiUGhpbGlwcGluZXMiLCJTaW5nYXBvcmUiOiJTaW5nYXBvcmUiLCJUaGFpbGFuZCI6IlRoYWlsYW5kIiwie2NsYXNzaWZpY2F0aW9uc0NvdW50fSBjbGFzc2lmaWNhdGlvbnMiOiJ7Y2xhc3NpZmljYXRpb25zQ291bnR9IGNsYXNzaWZpY2F0aW9ucyJ9!"
        )
      ),
"en-NZ": createLanguage(
        require.resolveWeak("./translations.json-en-NZ-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJSZWNlbnQgc2VhcmNoZXMiOiJSZWNlbnQgc2VhcmNoZXMiLCJTYXZlZCBzZWFyY2hlcyI6IlNhdmVkIHNlYXJjaGVzIiwiU2VlIGFsbCAoe2NvdW50fSkiOiJTZWUgYWxsICh7Y291bnR9KSAiLCJ7ZGlzcGxheUNvdW50TGFiZWx9IG5ldyI6IntkaXNwbGF5Q291bnRMYWJlbH0gbmV3Iiwie2ZpcnN0RmFjZXR9IGluIHtzZWNvbmRGYWNldH0iOiJ7Zmlyc3RGYWNldH0gaW4ge3NlY29uZEZhY2V0fSIsIkFsbCBqb2JzIjoiQWxsIGpvYnMiLCJBdXN0cmFsaWEiOiJBdXN0cmFsaWEiLCJIb25nIEtvbmciOiJIb25nIEtvbmciLCJJbmRvbmVzaWEiOiJJbmRvbmVzaWEiLCJNYWxheXNpYSI6Ik1hbGF5c2lhIiwiTmV3IFplYWxhbmQiOiJOZXcgWmVhbGFuZCIsIlBoaWxpcHBpbmVzIjoiUGhpbGlwcGluZXMiLCJTaW5nYXBvcmUiOiJTaW5nYXBvcmUiLCJUaGFpbGFuZCI6IlRoYWlsYW5kIiwie2NsYXNzaWZpY2F0aW9uc0NvdW50fSBjbGFzc2lmaWNhdGlvbnMiOiJ7Y2xhc3NpZmljYXRpb25zQ291bnR9IGNsYXNzaWZpY2F0aW9ucyJ9!"),
        () => import(
          /* webpackChunkName: "en-NZ-translations" */
          "./translations.json-en-NZ-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJSZWNlbnQgc2VhcmNoZXMiOiJSZWNlbnQgc2VhcmNoZXMiLCJTYXZlZCBzZWFyY2hlcyI6IlNhdmVkIHNlYXJjaGVzIiwiU2VlIGFsbCAoe2NvdW50fSkiOiJTZWUgYWxsICh7Y291bnR9KSAiLCJ7ZGlzcGxheUNvdW50TGFiZWx9IG5ldyI6IntkaXNwbGF5Q291bnRMYWJlbH0gbmV3Iiwie2ZpcnN0RmFjZXR9IGluIHtzZWNvbmRGYWNldH0iOiJ7Zmlyc3RGYWNldH0gaW4ge3NlY29uZEZhY2V0fSIsIkFsbCBqb2JzIjoiQWxsIGpvYnMiLCJBdXN0cmFsaWEiOiJBdXN0cmFsaWEiLCJIb25nIEtvbmciOiJIb25nIEtvbmciLCJJbmRvbmVzaWEiOiJJbmRvbmVzaWEiLCJNYWxheXNpYSI6Ik1hbGF5c2lhIiwiTmV3IFplYWxhbmQiOiJOZXcgWmVhbGFuZCIsIlBoaWxpcHBpbmVzIjoiUGhpbGlwcGluZXMiLCJTaW5nYXBvcmUiOiJTaW5nYXBvcmUiLCJUaGFpbGFuZCI6IlRoYWlsYW5kIiwie2NsYXNzaWZpY2F0aW9uc0NvdW50fSBjbGFzc2lmaWNhdGlvbnMiOiJ7Y2xhc3NpZmljYXRpb25zQ291bnR9IGNsYXNzaWZpY2F0aW9ucyJ9!"
        )
      ),
"en-HK": createLanguage(
        require.resolveWeak("./translations.json-en-HK-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJSZWNlbnQgc2VhcmNoZXMiOiJSZWNlbnQgc2VhcmNoZXMiLCJTYXZlZCBzZWFyY2hlcyI6IlNhdmVkIHNlYXJjaGVzIiwiU2VlIGFsbCAoe2NvdW50fSkiOiJTZWUgYWxsICh7Y291bnR9KSAiLCJ7ZGlzcGxheUNvdW50TGFiZWx9IG5ldyI6IntkaXNwbGF5Q291bnRMYWJlbH0gbmV3Iiwie2ZpcnN0RmFjZXR9IGluIHtzZWNvbmRGYWNldH0iOiJ7Zmlyc3RGYWNldH0gaW4ge3NlY29uZEZhY2V0fSIsIkFsbCBqb2JzIjoiQWxsIGpvYnMiLCJBdXN0cmFsaWEiOiJBdXN0cmFsaWEiLCJIb25nIEtvbmciOiJIb25nIEtvbmciLCJJbmRvbmVzaWEiOiJJbmRvbmVzaWEiLCJNYWxheXNpYSI6Ik1hbGF5c2lhIiwiTmV3IFplYWxhbmQiOiJOZXcgWmVhbGFuZCIsIlBoaWxpcHBpbmVzIjoiUGhpbGlwcGluZXMiLCJTaW5nYXBvcmUiOiJTaW5nYXBvcmUiLCJUaGFpbGFuZCI6IlRoYWlsYW5kIiwie2NsYXNzaWZpY2F0aW9uc0NvdW50fSBjbGFzc2lmaWNhdGlvbnMiOiJ7Y2xhc3NpZmljYXRpb25zQ291bnR9IGNsYXNzaWZpY2F0aW9ucyJ9!"),
        () => import(
          /* webpackChunkName: "en-HK-translations" */
          "./translations.json-en-HK-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJSZWNlbnQgc2VhcmNoZXMiOiJSZWNlbnQgc2VhcmNoZXMiLCJTYXZlZCBzZWFyY2hlcyI6IlNhdmVkIHNlYXJjaGVzIiwiU2VlIGFsbCAoe2NvdW50fSkiOiJTZWUgYWxsICh7Y291bnR9KSAiLCJ7ZGlzcGxheUNvdW50TGFiZWx9IG5ldyI6IntkaXNwbGF5Q291bnRMYWJlbH0gbmV3Iiwie2ZpcnN0RmFjZXR9IGluIHtzZWNvbmRGYWNldH0iOiJ7Zmlyc3RGYWNldH0gaW4ge3NlY29uZEZhY2V0fSIsIkFsbCBqb2JzIjoiQWxsIGpvYnMiLCJBdXN0cmFsaWEiOiJBdXN0cmFsaWEiLCJIb25nIEtvbmciOiJIb25nIEtvbmciLCJJbmRvbmVzaWEiOiJJbmRvbmVzaWEiLCJNYWxheXNpYSI6Ik1hbGF5c2lhIiwiTmV3IFplYWxhbmQiOiJOZXcgWmVhbGFuZCIsIlBoaWxpcHBpbmVzIjoiUGhpbGlwcGluZXMiLCJTaW5nYXBvcmUiOiJTaW5nYXBvcmUiLCJUaGFpbGFuZCI6IlRoYWlsYW5kIiwie2NsYXNzaWZpY2F0aW9uc0NvdW50fSBjbGFzc2lmaWNhdGlvbnMiOiJ7Y2xhc3NpZmljYXRpb25zQ291bnR9IGNsYXNzaWZpY2F0aW9ucyJ9!"
        )
      ),
"en-TH": createLanguage(
        require.resolveWeak("./translations.json-en-TH-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJSZWNlbnQgc2VhcmNoZXMiOiJSZWNlbnQgc2VhcmNoZXMiLCJTYXZlZCBzZWFyY2hlcyI6IlNhdmVkIHNlYXJjaGVzIiwiU2VlIGFsbCAoe2NvdW50fSkiOiJTZWUgYWxsICh7Y291bnR9KSAiLCJ7ZGlzcGxheUNvdW50TGFiZWx9IG5ldyI6IntkaXNwbGF5Q291bnRMYWJlbH0gbmV3Iiwie2ZpcnN0RmFjZXR9IGluIHtzZWNvbmRGYWNldH0iOiJ7Zmlyc3RGYWNldH0gaW4ge3NlY29uZEZhY2V0fSIsIkFsbCBqb2JzIjoiQWxsIGpvYnMiLCJBdXN0cmFsaWEiOiJBdXN0cmFsaWEiLCJIb25nIEtvbmciOiJIb25nIEtvbmciLCJJbmRvbmVzaWEiOiJJbmRvbmVzaWEiLCJNYWxheXNpYSI6Ik1hbGF5c2lhIiwiTmV3IFplYWxhbmQiOiJOZXcgWmVhbGFuZCIsIlBoaWxpcHBpbmVzIjoiUGhpbGlwcGluZXMiLCJTaW5nYXBvcmUiOiJTaW5nYXBvcmUiLCJUaGFpbGFuZCI6IlRoYWlsYW5kIiwie2NsYXNzaWZpY2F0aW9uc0NvdW50fSBjbGFzc2lmaWNhdGlvbnMiOiJ7Y2xhc3NpZmljYXRpb25zQ291bnR9IGNsYXNzaWZpY2F0aW9ucyJ9!"),
        () => import(
          /* webpackChunkName: "en-TH-translations" */
          "./translations.json-en-TH-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJSZWNlbnQgc2VhcmNoZXMiOiJSZWNlbnQgc2VhcmNoZXMiLCJTYXZlZCBzZWFyY2hlcyI6IlNhdmVkIHNlYXJjaGVzIiwiU2VlIGFsbCAoe2NvdW50fSkiOiJTZWUgYWxsICh7Y291bnR9KSAiLCJ7ZGlzcGxheUNvdW50TGFiZWx9IG5ldyI6IntkaXNwbGF5Q291bnRMYWJlbH0gbmV3Iiwie2ZpcnN0RmFjZXR9IGluIHtzZWNvbmRGYWNldH0iOiJ7Zmlyc3RGYWNldH0gaW4ge3NlY29uZEZhY2V0fSIsIkFsbCBqb2JzIjoiQWxsIGpvYnMiLCJBdXN0cmFsaWEiOiJBdXN0cmFsaWEiLCJIb25nIEtvbmciOiJIb25nIEtvbmciLCJJbmRvbmVzaWEiOiJJbmRvbmVzaWEiLCJNYWxheXNpYSI6Ik1hbGF5c2lhIiwiTmV3IFplYWxhbmQiOiJOZXcgWmVhbGFuZCIsIlBoaWxpcHBpbmVzIjoiUGhpbGlwcGluZXMiLCJTaW5nYXBvcmUiOiJTaW5nYXBvcmUiLCJUaGFpbGFuZCI6IlRoYWlsYW5kIiwie2NsYXNzaWZpY2F0aW9uc0NvdW50fSBjbGFzc2lmaWNhdGlvbnMiOiJ7Y2xhc3NpZmljYXRpb25zQ291bnR9IGNsYXNzaWZpY2F0aW9ucyJ9!"
        )
      ),
"en-ID": createLanguage(
        require.resolveWeak("./translations.json-en-ID-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJSZWNlbnQgc2VhcmNoZXMiOiJSZWNlbnQgc2VhcmNoZXMiLCJTYXZlZCBzZWFyY2hlcyI6IlNhdmVkIHNlYXJjaGVzIiwiU2VlIGFsbCAoe2NvdW50fSkiOiJTZWUgYWxsICh7Y291bnR9KSAiLCJ7ZGlzcGxheUNvdW50TGFiZWx9IG5ldyI6IntkaXNwbGF5Q291bnRMYWJlbH0gbmV3Iiwie2ZpcnN0RmFjZXR9IGluIHtzZWNvbmRGYWNldH0iOiJ7Zmlyc3RGYWNldH0gaW4ge3NlY29uZEZhY2V0fSIsIkFsbCBqb2JzIjoiQWxsIGpvYnMiLCJBdXN0cmFsaWEiOiJBdXN0cmFsaWEiLCJIb25nIEtvbmciOiJIb25nIEtvbmciLCJJbmRvbmVzaWEiOiJJbmRvbmVzaWEiLCJNYWxheXNpYSI6Ik1hbGF5c2lhIiwiTmV3IFplYWxhbmQiOiJOZXcgWmVhbGFuZCIsIlBoaWxpcHBpbmVzIjoiUGhpbGlwcGluZXMiLCJTaW5nYXBvcmUiOiJTaW5nYXBvcmUiLCJUaGFpbGFuZCI6IlRoYWlsYW5kIiwie2NsYXNzaWZpY2F0aW9uc0NvdW50fSBjbGFzc2lmaWNhdGlvbnMiOiJ7Y2xhc3NpZmljYXRpb25zQ291bnR9IGNsYXNzaWZpY2F0aW9ucyJ9!"),
        () => import(
          /* webpackChunkName: "en-ID-translations" */
          "./translations.json-en-ID-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJSZWNlbnQgc2VhcmNoZXMiOiJSZWNlbnQgc2VhcmNoZXMiLCJTYXZlZCBzZWFyY2hlcyI6IlNhdmVkIHNlYXJjaGVzIiwiU2VlIGFsbCAoe2NvdW50fSkiOiJTZWUgYWxsICh7Y291bnR9KSAiLCJ7ZGlzcGxheUNvdW50TGFiZWx9IG5ldyI6IntkaXNwbGF5Q291bnRMYWJlbH0gbmV3Iiwie2ZpcnN0RmFjZXR9IGluIHtzZWNvbmRGYWNldH0iOiJ7Zmlyc3RGYWNldH0gaW4ge3NlY29uZEZhY2V0fSIsIkFsbCBqb2JzIjoiQWxsIGpvYnMiLCJBdXN0cmFsaWEiOiJBdXN0cmFsaWEiLCJIb25nIEtvbmciOiJIb25nIEtvbmciLCJJbmRvbmVzaWEiOiJJbmRvbmVzaWEiLCJNYWxheXNpYSI6Ik1hbGF5c2lhIiwiTmV3IFplYWxhbmQiOiJOZXcgWmVhbGFuZCIsIlBoaWxpcHBpbmVzIjoiUGhpbGlwcGluZXMiLCJTaW5nYXBvcmUiOiJTaW5nYXBvcmUiLCJUaGFpbGFuZCI6IlRoYWlsYW5kIiwie2NsYXNzaWZpY2F0aW9uc0NvdW50fSBjbGFzc2lmaWNhdGlvbnMiOiJ7Y2xhc3NpZmljYXRpb25zQ291bnR9IGNsYXNzaWZpY2F0aW9ucyJ9!"
        )
      ),
"en-SG": createLanguage(
        require.resolveWeak("./translations.json-en-SG-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJSZWNlbnQgc2VhcmNoZXMiOiJSZWNlbnQgc2VhcmNoZXMiLCJTYXZlZCBzZWFyY2hlcyI6IlNhdmVkIHNlYXJjaGVzIiwiU2VlIGFsbCAoe2NvdW50fSkiOiJTZWUgYWxsICh7Y291bnR9KSAiLCJ7ZGlzcGxheUNvdW50TGFiZWx9IG5ldyI6IntkaXNwbGF5Q291bnRMYWJlbH0gbmV3Iiwie2ZpcnN0RmFjZXR9IGluIHtzZWNvbmRGYWNldH0iOiJ7Zmlyc3RGYWNldH0gaW4ge3NlY29uZEZhY2V0fSIsIkFsbCBqb2JzIjoiQWxsIGpvYnMiLCJBdXN0cmFsaWEiOiJBdXN0cmFsaWEiLCJIb25nIEtvbmciOiJIb25nIEtvbmciLCJJbmRvbmVzaWEiOiJJbmRvbmVzaWEiLCJNYWxheXNpYSI6Ik1hbGF5c2lhIiwiTmV3IFplYWxhbmQiOiJOZXcgWmVhbGFuZCIsIlBoaWxpcHBpbmVzIjoiUGhpbGlwcGluZXMiLCJTaW5nYXBvcmUiOiJTaW5nYXBvcmUiLCJUaGFpbGFuZCI6IlRoYWlsYW5kIiwie2NsYXNzaWZpY2F0aW9uc0NvdW50fSBjbGFzc2lmaWNhdGlvbnMiOiJ7Y2xhc3NpZmljYXRpb25zQ291bnR9IGNsYXNzaWZpY2F0aW9ucyJ9!"),
        () => import(
          /* webpackChunkName: "en-SG-translations" */
          "./translations.json-en-SG-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJSZWNlbnQgc2VhcmNoZXMiOiJSZWNlbnQgc2VhcmNoZXMiLCJTYXZlZCBzZWFyY2hlcyI6IlNhdmVkIHNlYXJjaGVzIiwiU2VlIGFsbCAoe2NvdW50fSkiOiJTZWUgYWxsICh7Y291bnR9KSAiLCJ7ZGlzcGxheUNvdW50TGFiZWx9IG5ldyI6IntkaXNwbGF5Q291bnRMYWJlbH0gbmV3Iiwie2ZpcnN0RmFjZXR9IGluIHtzZWNvbmRGYWNldH0iOiJ7Zmlyc3RGYWNldH0gaW4ge3NlY29uZEZhY2V0fSIsIkFsbCBqb2JzIjoiQWxsIGpvYnMiLCJBdXN0cmFsaWEiOiJBdXN0cmFsaWEiLCJIb25nIEtvbmciOiJIb25nIEtvbmciLCJJbmRvbmVzaWEiOiJJbmRvbmVzaWEiLCJNYWxheXNpYSI6Ik1hbGF5c2lhIiwiTmV3IFplYWxhbmQiOiJOZXcgWmVhbGFuZCIsIlBoaWxpcHBpbmVzIjoiUGhpbGlwcGluZXMiLCJTaW5nYXBvcmUiOiJTaW5nYXBvcmUiLCJUaGFpbGFuZCI6IlRoYWlsYW5kIiwie2NsYXNzaWZpY2F0aW9uc0NvdW50fSBjbGFzc2lmaWNhdGlvbnMiOiJ7Y2xhc3NpZmljYXRpb25zQ291bnR9IGNsYXNzaWZpY2F0aW9ucyJ9!"
        )
      ),
"en-MY": createLanguage(
        require.resolveWeak("./translations.json-en-MY-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJSZWNlbnQgc2VhcmNoZXMiOiJSZWNlbnQgc2VhcmNoZXMiLCJTYXZlZCBzZWFyY2hlcyI6IlNhdmVkIHNlYXJjaGVzIiwiU2VlIGFsbCAoe2NvdW50fSkiOiJTZWUgYWxsICh7Y291bnR9KSAiLCJ7ZGlzcGxheUNvdW50TGFiZWx9IG5ldyI6IntkaXNwbGF5Q291bnRMYWJlbH0gbmV3Iiwie2ZpcnN0RmFjZXR9IGluIHtzZWNvbmRGYWNldH0iOiJ7Zmlyc3RGYWNldH0gaW4ge3NlY29uZEZhY2V0fSIsIkFsbCBqb2JzIjoiQWxsIGpvYnMiLCJBdXN0cmFsaWEiOiJBdXN0cmFsaWEiLCJIb25nIEtvbmciOiJIb25nIEtvbmciLCJJbmRvbmVzaWEiOiJJbmRvbmVzaWEiLCJNYWxheXNpYSI6Ik1hbGF5c2lhIiwiTmV3IFplYWxhbmQiOiJOZXcgWmVhbGFuZCIsIlBoaWxpcHBpbmVzIjoiUGhpbGlwcGluZXMiLCJTaW5nYXBvcmUiOiJTaW5nYXBvcmUiLCJUaGFpbGFuZCI6IlRoYWlsYW5kIiwie2NsYXNzaWZpY2F0aW9uc0NvdW50fSBjbGFzc2lmaWNhdGlvbnMiOiJ7Y2xhc3NpZmljYXRpb25zQ291bnR9IGNsYXNzaWZpY2F0aW9ucyJ9!"),
        () => import(
          /* webpackChunkName: "en-MY-translations" */
          "./translations.json-en-MY-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJSZWNlbnQgc2VhcmNoZXMiOiJSZWNlbnQgc2VhcmNoZXMiLCJTYXZlZCBzZWFyY2hlcyI6IlNhdmVkIHNlYXJjaGVzIiwiU2VlIGFsbCAoe2NvdW50fSkiOiJTZWUgYWxsICh7Y291bnR9KSAiLCJ7ZGlzcGxheUNvdW50TGFiZWx9IG5ldyI6IntkaXNwbGF5Q291bnRMYWJlbH0gbmV3Iiwie2ZpcnN0RmFjZXR9IGluIHtzZWNvbmRGYWNldH0iOiJ7Zmlyc3RGYWNldH0gaW4ge3NlY29uZEZhY2V0fSIsIkFsbCBqb2JzIjoiQWxsIGpvYnMiLCJBdXN0cmFsaWEiOiJBdXN0cmFsaWEiLCJIb25nIEtvbmciOiJIb25nIEtvbmciLCJJbmRvbmVzaWEiOiJJbmRvbmVzaWEiLCJNYWxheXNpYSI6Ik1hbGF5c2lhIiwiTmV3IFplYWxhbmQiOiJOZXcgWmVhbGFuZCIsIlBoaWxpcHBpbmVzIjoiUGhpbGlwcGluZXMiLCJTaW5nYXBvcmUiOiJTaW5nYXBvcmUiLCJUaGFpbGFuZCI6IlRoYWlsYW5kIiwie2NsYXNzaWZpY2F0aW9uc0NvdW50fSBjbGFzc2lmaWNhdGlvbnMiOiJ7Y2xhc3NpZmljYXRpb25zQ291bnR9IGNsYXNzaWZpY2F0aW9ucyJ9!"
        )
      ),
"en-PH": createLanguage(
        require.resolveWeak("./translations.json-en-PH-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJSZWNlbnQgc2VhcmNoZXMiOiJSZWNlbnQgc2VhcmNoZXMiLCJTYXZlZCBzZWFyY2hlcyI6IlNhdmVkIHNlYXJjaGVzIiwiU2VlIGFsbCAoe2NvdW50fSkiOiJTZWUgYWxsICh7Y291bnR9KSAiLCJ7ZGlzcGxheUNvdW50TGFiZWx9IG5ldyI6IntkaXNwbGF5Q291bnRMYWJlbH0gbmV3Iiwie2ZpcnN0RmFjZXR9IGluIHtzZWNvbmRGYWNldH0iOiJ7Zmlyc3RGYWNldH0gaW4ge3NlY29uZEZhY2V0fSIsIkFsbCBqb2JzIjoiQWxsIGpvYnMiLCJBdXN0cmFsaWEiOiJBdXN0cmFsaWEiLCJIb25nIEtvbmciOiJIb25nIEtvbmciLCJJbmRvbmVzaWEiOiJJbmRvbmVzaWEiLCJNYWxheXNpYSI6Ik1hbGF5c2lhIiwiTmV3IFplYWxhbmQiOiJOZXcgWmVhbGFuZCIsIlBoaWxpcHBpbmVzIjoiUGhpbGlwcGluZXMiLCJTaW5nYXBvcmUiOiJTaW5nYXBvcmUiLCJUaGFpbGFuZCI6IlRoYWlsYW5kIiwie2NsYXNzaWZpY2F0aW9uc0NvdW50fSBjbGFzc2lmaWNhdGlvbnMiOiJ7Y2xhc3NpZmljYXRpb25zQ291bnR9IGNsYXNzaWZpY2F0aW9ucyJ9!"),
        () => import(
          /* webpackChunkName: "en-PH-translations" */
          "./translations.json-en-PH-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJSZWNlbnQgc2VhcmNoZXMiOiJSZWNlbnQgc2VhcmNoZXMiLCJTYXZlZCBzZWFyY2hlcyI6IlNhdmVkIHNlYXJjaGVzIiwiU2VlIGFsbCAoe2NvdW50fSkiOiJTZWUgYWxsICh7Y291bnR9KSAiLCJ7ZGlzcGxheUNvdW50TGFiZWx9IG5ldyI6IntkaXNwbGF5Q291bnRMYWJlbH0gbmV3Iiwie2ZpcnN0RmFjZXR9IGluIHtzZWNvbmRGYWNldH0iOiJ7Zmlyc3RGYWNldH0gaW4ge3NlY29uZEZhY2V0fSIsIkFsbCBqb2JzIjoiQWxsIGpvYnMiLCJBdXN0cmFsaWEiOiJBdXN0cmFsaWEiLCJIb25nIEtvbmciOiJIb25nIEtvbmciLCJJbmRvbmVzaWEiOiJJbmRvbmVzaWEiLCJNYWxheXNpYSI6Ik1hbGF5c2lhIiwiTmV3IFplYWxhbmQiOiJOZXcgWmVhbGFuZCIsIlBoaWxpcHBpbmVzIjoiUGhpbGlwcGluZXMiLCJTaW5nYXBvcmUiOiJTaW5nYXBvcmUiLCJUaGFpbGFuZCI6IlRoYWlsYW5kIiwie2NsYXNzaWZpY2F0aW9uc0NvdW50fSBjbGFzc2lmaWNhdGlvbnMiOiJ7Y2xhc3NpZmljYXRpb25zQ291bnR9IGNsYXNzaWZpY2F0aW9ucyJ9!"
        )
      ),
"id": createLanguage(
        require.resolveWeak("./translations.json-id-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJSZWNlbnQgc2VhcmNoZXMiOiJQZW5jYXJpYW4gdGVyYmFydSIsIlNhdmVkIHNlYXJjaGVzIjoiUGVuY2FyaWFuIHRlcnNpbXBhbiIsIlNlZSBhbGwgKHtjb3VudH0pIjoiTGloYXQgc2VtdWEgKHtjb3VudH0pIiwie2Rpc3BsYXlDb3VudExhYmVsfSBuZXciOiJ7ZGlzcGxheUNvdW50TGFiZWx9IGJhcnUiLCJ7Zmlyc3RGYWNldH0gaW4ge3NlY29uZEZhY2V0fSI6IntmaXJzdEZhY2V0fSBkYXJpIHtzZWNvbmRGYWNldH0iLCJBbGwgam9icyI6IlNlbXVhIHBla2VyamFhbiIsIkF1c3RyYWxpYSI6IkF1c3RyYWxpYSIsIkhvbmcgS29uZyI6IkhvbmcgS29uZyIsIkluZG9uZXNpYSI6IkluZG9uZXNpYSIsIk1hbGF5c2lhIjoiTWFsYXlzaWEiLCJOZXcgWmVhbGFuZCI6IlNlbGFuZGlhIEJhcnUiLCJQaGlsaXBwaW5lcyI6IkZpbGlwaW5hIiwiU2luZ2Fwb3JlIjoiU2luZ2FwdXJhIiwiVGhhaWxhbmQiOiJUaGFpbGFuZCIsIntjbGFzc2lmaWNhdGlvbnNDb3VudH0gY2xhc3NpZmljYXRpb25zIjoie2NsYXNzaWZpY2F0aW9uc0NvdW50fSBrbGFzaWZpa2FzaSJ9!"),
        () => import(
          /* webpackChunkName: "id-translations" */
          "./translations.json-id-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJSZWNlbnQgc2VhcmNoZXMiOiJQZW5jYXJpYW4gdGVyYmFydSIsIlNhdmVkIHNlYXJjaGVzIjoiUGVuY2FyaWFuIHRlcnNpbXBhbiIsIlNlZSBhbGwgKHtjb3VudH0pIjoiTGloYXQgc2VtdWEgKHtjb3VudH0pIiwie2Rpc3BsYXlDb3VudExhYmVsfSBuZXciOiJ7ZGlzcGxheUNvdW50TGFiZWx9IGJhcnUiLCJ7Zmlyc3RGYWNldH0gaW4ge3NlY29uZEZhY2V0fSI6IntmaXJzdEZhY2V0fSBkYXJpIHtzZWNvbmRGYWNldH0iLCJBbGwgam9icyI6IlNlbXVhIHBla2VyamFhbiIsIkF1c3RyYWxpYSI6IkF1c3RyYWxpYSIsIkhvbmcgS29uZyI6IkhvbmcgS29uZyIsIkluZG9uZXNpYSI6IkluZG9uZXNpYSIsIk1hbGF5c2lhIjoiTWFsYXlzaWEiLCJOZXcgWmVhbGFuZCI6IlNlbGFuZGlhIEJhcnUiLCJQaGlsaXBwaW5lcyI6IkZpbGlwaW5hIiwiU2luZ2Fwb3JlIjoiU2luZ2FwdXJhIiwiVGhhaWxhbmQiOiJUaGFpbGFuZCIsIntjbGFzc2lmaWNhdGlvbnNDb3VudH0gY2xhc3NpZmljYXRpb25zIjoie2NsYXNzaWZpY2F0aW9uc0NvdW50fSBrbGFzaWZpa2FzaSJ9!"
        )
      ),
"th": createLanguage(
        require.resolveWeak("./translations.json-th-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJSZWNlbnQgc2VhcmNoZXMiOiLguIHguLLguKPguITguYnguJnguKvguLLguKXguYjguLLguKrguLjguJQiLCJTYXZlZCBzZWFyY2hlcyI6IuC4muC4seC4meC4l%2BC4tuC4geC4geC4suC4o%2BC4hOC5ieC4meC4q%2BC4siIsIlNlZSBhbGwgKHtjb3VudH0pIjoi4LiU4Li54LiX4Lix4LmJ4LiH4Lir4Lih4LiUICh7Y291bnR9KSIsIntkaXNwbGF5Q291bnRMYWJlbH0gbmV3Ijoie2Rpc3BsYXlDb3VudExhYmVsfSDguYPguKvguKHguYgiLCJ7Zmlyc3RGYWNldH0gaW4ge3NlY29uZEZhY2V0fSI6IntmaXJzdEZhY2V0fSDguYPguJkge3NlY29uZEZhY2V0fSIsIkFsbCBqb2JzIjoi4LiV4Liz4LmB4Lir4LiZ4LmI4LiH4LiH4Liy4LiZ4LiX4Lix4LmJ4LiH4Lir4Lih4LiUIiwiQXVzdHJhbGlhIjoi4Lit4Lit4Liq4LmA4LiV4Lij4LmA4Lil4Li14LiiIiwiSG9uZyBLb25nIjoi4Liu4LmI4Lit4LiH4LiB4LiHIiwiSW5kb25lc2lhIjoi4Lit4Li04LiZ4LmC4LiU4LiZ4Li14LmA4LiL4Li14LiiIiwiTWFsYXlzaWEiOiLguKHguLLguYDguKXguYDguIvguLXguKIiLCJOZXcgWmVhbGFuZCI6IuC4meC4tOC4p%2BC4i%2BC4teC5geC4peC4meC4lOC5jCIsIlBoaWxpcHBpbmVzIjoi4Lif4Li04Lil4Li04Lib4Lib4Li04LiZ4Liq4LmMIiwiU2luZ2Fwb3JlIjoi4Liq4Li04LiH4LiE4LmC4Lib4Lij4LmMIiwiVGhhaWxhbmQiOiLguYTguJfguKIiLCJ7Y2xhc3NpZmljYXRpb25zQ291bnR9IGNsYXNzaWZpY2F0aW9ucyI6IntjbGFzc2lmaWNhdGlvbnNDb3VudH0g4Lib4Lij4Liw4LmA4Lig4LiX4LiH4Liy4LiZIn0%3D!"),
        () => import(
          /* webpackChunkName: "th-translations" */
          "./translations.json-th-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJSZWNlbnQgc2VhcmNoZXMiOiLguIHguLLguKPguITguYnguJnguKvguLLguKXguYjguLLguKrguLjguJQiLCJTYXZlZCBzZWFyY2hlcyI6IuC4muC4seC4meC4l%2BC4tuC4geC4geC4suC4o%2BC4hOC5ieC4meC4q%2BC4siIsIlNlZSBhbGwgKHtjb3VudH0pIjoi4LiU4Li54LiX4Lix4LmJ4LiH4Lir4Lih4LiUICh7Y291bnR9KSIsIntkaXNwbGF5Q291bnRMYWJlbH0gbmV3Ijoie2Rpc3BsYXlDb3VudExhYmVsfSDguYPguKvguKHguYgiLCJ7Zmlyc3RGYWNldH0gaW4ge3NlY29uZEZhY2V0fSI6IntmaXJzdEZhY2V0fSDguYPguJkge3NlY29uZEZhY2V0fSIsIkFsbCBqb2JzIjoi4LiV4Liz4LmB4Lir4LiZ4LmI4LiH4LiH4Liy4LiZ4LiX4Lix4LmJ4LiH4Lir4Lih4LiUIiwiQXVzdHJhbGlhIjoi4Lit4Lit4Liq4LmA4LiV4Lij4LmA4Lil4Li14LiiIiwiSG9uZyBLb25nIjoi4Liu4LmI4Lit4LiH4LiB4LiHIiwiSW5kb25lc2lhIjoi4Lit4Li04LiZ4LmC4LiU4LiZ4Li14LmA4LiL4Li14LiiIiwiTWFsYXlzaWEiOiLguKHguLLguYDguKXguYDguIvguLXguKIiLCJOZXcgWmVhbGFuZCI6IuC4meC4tOC4p%2BC4i%2BC4teC5geC4peC4meC4lOC5jCIsIlBoaWxpcHBpbmVzIjoi4Lif4Li04Lil4Li04Lib4Lib4Li04LiZ4Liq4LmMIiwiU2luZ2Fwb3JlIjoi4Liq4Li04LiH4LiE4LmC4Lib4Lij4LmMIiwiVGhhaWxhbmQiOiLguYTguJfguKIiLCJ7Y2xhc3NpZmljYXRpb25zQ291bnR9IGNsYXNzaWZpY2F0aW9ucyI6IntjbGFzc2lmaWNhdGlvbnNDb3VudH0g4Lib4Lij4Liw4LmA4Lig4LiX4LiH4Liy4LiZIn0%3D!"
        )
      ),
"id-ID": createLanguage(
        require.resolveWeak("./translations.json-id-ID-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJSZWNlbnQgc2VhcmNoZXMiOiJQZW5jYXJpYW4gdGVyYmFydSIsIlNhdmVkIHNlYXJjaGVzIjoiUGVuY2FyaWFuIHRlcnNpbXBhbiIsIlNlZSBhbGwgKHtjb3VudH0pIjoiTGloYXQgc2VtdWEgKHtjb3VudH0pIiwie2Rpc3BsYXlDb3VudExhYmVsfSBuZXciOiJ7ZGlzcGxheUNvdW50TGFiZWx9IGJhcnUiLCJ7Zmlyc3RGYWNldH0gaW4ge3NlY29uZEZhY2V0fSI6IntmaXJzdEZhY2V0fSBkYXJpIHtzZWNvbmRGYWNldH0iLCJBbGwgam9icyI6IlNlbXVhIHBla2VyamFhbiIsIkF1c3RyYWxpYSI6IkF1c3RyYWxpYSIsIkhvbmcgS29uZyI6IkhvbmcgS29uZyIsIkluZG9uZXNpYSI6IkluZG9uZXNpYSIsIk1hbGF5c2lhIjoiTWFsYXlzaWEiLCJOZXcgWmVhbGFuZCI6IlNlbGFuZGlhIEJhcnUiLCJQaGlsaXBwaW5lcyI6IkZpbGlwaW5hIiwiU2luZ2Fwb3JlIjoiU2luZ2FwdXJhIiwiVGhhaWxhbmQiOiJUaGFpbGFuZCIsIntjbGFzc2lmaWNhdGlvbnNDb3VudH0gY2xhc3NpZmljYXRpb25zIjoie2NsYXNzaWZpY2F0aW9uc0NvdW50fSBrbGFzaWZpa2FzaSJ9!"),
        () => import(
          /* webpackChunkName: "id-ID-translations" */
          "./translations.json-id-ID-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJSZWNlbnQgc2VhcmNoZXMiOiJQZW5jYXJpYW4gdGVyYmFydSIsIlNhdmVkIHNlYXJjaGVzIjoiUGVuY2FyaWFuIHRlcnNpbXBhbiIsIlNlZSBhbGwgKHtjb3VudH0pIjoiTGloYXQgc2VtdWEgKHtjb3VudH0pIiwie2Rpc3BsYXlDb3VudExhYmVsfSBuZXciOiJ7ZGlzcGxheUNvdW50TGFiZWx9IGJhcnUiLCJ7Zmlyc3RGYWNldH0gaW4ge3NlY29uZEZhY2V0fSI6IntmaXJzdEZhY2V0fSBkYXJpIHtzZWNvbmRGYWNldH0iLCJBbGwgam9icyI6IlNlbXVhIHBla2VyamFhbiIsIkF1c3RyYWxpYSI6IkF1c3RyYWxpYSIsIkhvbmcgS29uZyI6IkhvbmcgS29uZyIsIkluZG9uZXNpYSI6IkluZG9uZXNpYSIsIk1hbGF5c2lhIjoiTWFsYXlzaWEiLCJOZXcgWmVhbGFuZCI6IlNlbGFuZGlhIEJhcnUiLCJQaGlsaXBwaW5lcyI6IkZpbGlwaW5hIiwiU2luZ2Fwb3JlIjoiU2luZ2FwdXJhIiwiVGhhaWxhbmQiOiJUaGFpbGFuZCIsIntjbGFzc2lmaWNhdGlvbnNDb3VudH0gY2xhc3NpZmljYXRpb25zIjoie2NsYXNzaWZpY2F0aW9uc0NvdW50fSBrbGFzaWZpa2FzaSJ9!"
        )
      ),
"th-TH": createLanguage(
        require.resolveWeak("./translations.json-th-TH-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJSZWNlbnQgc2VhcmNoZXMiOiLguIHguLLguKPguITguYnguJnguKvguLLguKXguYjguLLguKrguLjguJQiLCJTYXZlZCBzZWFyY2hlcyI6IuC4muC4seC4meC4l%2BC4tuC4geC4geC4suC4o%2BC4hOC5ieC4meC4q%2BC4siIsIlNlZSBhbGwgKHtjb3VudH0pIjoi4LiU4Li54LiX4Lix4LmJ4LiH4Lir4Lih4LiUICh7Y291bnR9KSIsIntkaXNwbGF5Q291bnRMYWJlbH0gbmV3Ijoie2Rpc3BsYXlDb3VudExhYmVsfSDguYPguKvguKHguYgiLCJ7Zmlyc3RGYWNldH0gaW4ge3NlY29uZEZhY2V0fSI6IntmaXJzdEZhY2V0fSDguYPguJkge3NlY29uZEZhY2V0fSIsIkFsbCBqb2JzIjoi4LiV4Liz4LmB4Lir4LiZ4LmI4LiH4LiH4Liy4LiZ4LiX4Lix4LmJ4LiH4Lir4Lih4LiUIiwiQXVzdHJhbGlhIjoi4Lit4Lit4Liq4LmA4LiV4Lij4LmA4Lil4Li14LiiIiwiSG9uZyBLb25nIjoi4Liu4LmI4Lit4LiH4LiB4LiHIiwiSW5kb25lc2lhIjoi4Lit4Li04LiZ4LmC4LiU4LiZ4Li14LmA4LiL4Li14LiiIiwiTWFsYXlzaWEiOiLguKHguLLguYDguKXguYDguIvguLXguKIiLCJOZXcgWmVhbGFuZCI6IuC4meC4tOC4p%2BC4i%2BC4teC5geC4peC4meC4lOC5jCIsIlBoaWxpcHBpbmVzIjoi4Lif4Li04Lil4Li04Lib4Lib4Li04LiZ4Liq4LmMIiwiU2luZ2Fwb3JlIjoi4Liq4Li04LiH4LiE4LmC4Lib4Lij4LmMIiwiVGhhaWxhbmQiOiLguYTguJfguKIiLCJ7Y2xhc3NpZmljYXRpb25zQ291bnR9IGNsYXNzaWZpY2F0aW9ucyI6IntjbGFzc2lmaWNhdGlvbnNDb3VudH0g4Lib4Lij4Liw4LmA4Lig4LiX4LiH4Liy4LiZIn0%3D!"),
        () => import(
          /* webpackChunkName: "th-TH-translations" */
          "./translations.json-th-TH-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJSZWNlbnQgc2VhcmNoZXMiOiLguIHguLLguKPguITguYnguJnguKvguLLguKXguYjguLLguKrguLjguJQiLCJTYXZlZCBzZWFyY2hlcyI6IuC4muC4seC4meC4l%2BC4tuC4geC4geC4suC4o%2BC4hOC5ieC4meC4q%2BC4siIsIlNlZSBhbGwgKHtjb3VudH0pIjoi4LiU4Li54LiX4Lix4LmJ4LiH4Lir4Lih4LiUICh7Y291bnR9KSIsIntkaXNwbGF5Q291bnRMYWJlbH0gbmV3Ijoie2Rpc3BsYXlDb3VudExhYmVsfSDguYPguKvguKHguYgiLCJ7Zmlyc3RGYWNldH0gaW4ge3NlY29uZEZhY2V0fSI6IntmaXJzdEZhY2V0fSDguYPguJkge3NlY29uZEZhY2V0fSIsIkFsbCBqb2JzIjoi4LiV4Liz4LmB4Lir4LiZ4LmI4LiH4LiH4Liy4LiZ4LiX4Lix4LmJ4LiH4Lir4Lih4LiUIiwiQXVzdHJhbGlhIjoi4Lit4Lit4Liq4LmA4LiV4Lij4LmA4Lil4Li14LiiIiwiSG9uZyBLb25nIjoi4Liu4LmI4Lit4LiH4LiB4LiHIiwiSW5kb25lc2lhIjoi4Lit4Li04LiZ4LmC4LiU4LiZ4Li14LmA4LiL4Li14LiiIiwiTWFsYXlzaWEiOiLguKHguLLguYDguKXguYDguIvguLXguKIiLCJOZXcgWmVhbGFuZCI6IuC4meC4tOC4p%2BC4i%2BC4teC5geC4peC4meC4lOC5jCIsIlBoaWxpcHBpbmVzIjoi4Lif4Li04Lil4Li04Lib4Lib4Li04LiZ4Liq4LmMIiwiU2luZ2Fwb3JlIjoi4Liq4Li04LiH4LiE4LmC4Lib4Lij4LmMIiwiVGhhaWxhbmQiOiLguYTguJfguKIiLCJ7Y2xhc3NpZmljYXRpb25zQ291bnR9IGNsYXNzaWZpY2F0aW9ucyI6IntjbGFzc2lmaWNhdGlvbnNDb3VudH0g4Lib4Lij4Liw4LmA4Lig4LiX4LiH4Liy4LiZIn0%3D!"
        )
      ),
"en-PSEUDO": createLanguage(
        require.resolveWeak("./translations.json-en-PSEUDO-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJSZWNlbnQgc2VhcmNoZXMiOiJbxZjhur3hur3hur3Dp%2BG6veG6veG6veC4geC4teC5ieG5ryDFoeG6veG6veG6vcSDxIPEg8WZw6fhuKnhur3hur3hur3FoV0iLCJTYXZlZCBzZWFyY2hlcyI6IlvFoMSDxIPEg%2BG5veG6veG6veG6vcaMIMWh4bq94bq94bq9xIPEg8SDxZnDp%2BG4qeG6veG6veG6vcWhXSIsIlNlZSBhbGwgKHtjb3VudH0pIjoiW8Wg4bq94bq94bq94bq94bq94bq9IMSDxIPEg8aaxpogKHtjb3VudH0pIF0iLCJ7ZGlzcGxheUNvdW50TGFiZWx9IG5ldyI6Ilt7ZGlzcGxheUNvdW50TGFiZWx9IOC4geC4teC5ieG6veG6veG6vcW1XSIsIntmaXJzdEZhY2V0fSBpbiB7c2Vjb25kRmFjZXR9IjoiW3tmaXJzdEZhY2V0fSDDrMOsw6zguIHguLXguYkge3NlY29uZEZhY2V0fV0iLCJBbGwgam9icyI6IlvhuqzGmsaaIMS1w7bDtsO2w7bDtsOfxaFdIiwiQXVzdHJhbGlhIjoiW%2BG6rMeax5rFoeG5r8WZxIPEg8aaw6zDrMSDxINdIiwiSG9uZyBLb25nIjoiW%2BG4qMO2w7bDtuC4geC4teC5icSjIOG4sMO2w7bDtuC4geC4teC5icSjXSIsIkluZG9uZXNpYSI6IlvDj%2BC4geC4teC5icaMw7bDtuC4geC4teC5ieG6veG6vcWhw6zDrMSDxINdIiwiTWFsYXlzaWEiOiJb4bmCxIPEg8aaxIPEg8O9w73FocOsw6zEg8SDXSIsIk5ldyBaZWFsYW5kIjoiW8OR4bq94bq94bq9xbUgxrXhur3hur3hur3Eg8SDxIPGmsSDxIPEg%2BC4geC4teC5icaMXSIsIlBoaWxpcHBpbmVzIjoiW8ak4bipw6zDrMOsxprDrMOsw6zGpcalw6zDrMOs4LiB4Li14LmJ4bq94bq94bq9xaFdIiwiU2luZ2Fwb3JlIjoiW8Wgw6zDrOC4geC4teC5icSjxIPEg8alw7bDtsWZ4bq94bq9XSIsIlRoYWlsYW5kIjoiW%2BG5ruG4qcSDxIPEg8Osw6zDrMaaxIPEg8SD4LiB4Li14LmJxoxdIiwie2NsYXNzaWZpY2F0aW9uc0NvdW50fSBjbGFzc2lmaWNhdGlvbnMiOiJbe2NsYXNzaWZpY2F0aW9uc0NvdW50fSDDp8aaxIPEg8SDxaHFocOsw6zDrMaSw6zDrMOsw6fEg8SDxIPhua%2FDrMOsw6zDtsO2w7bguIHguLXguYnFoV0ifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "en-PSEUDO-translations" */
          "./translations.json-en-PSEUDO-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJSZWNlbnQgc2VhcmNoZXMiOiJbxZjhur3hur3hur3Dp%2BG6veG6veG6veC4geC4teC5ieG5ryDFoeG6veG6veG6vcSDxIPEg8WZw6fhuKnhur3hur3hur3FoV0iLCJTYXZlZCBzZWFyY2hlcyI6IlvFoMSDxIPEg%2BG5veG6veG6veG6vcaMIMWh4bq94bq94bq9xIPEg8SDxZnDp%2BG4qeG6veG6veG6vcWhXSIsIlNlZSBhbGwgKHtjb3VudH0pIjoiW8Wg4bq94bq94bq94bq94bq94bq9IMSDxIPEg8aaxpogKHtjb3VudH0pIF0iLCJ7ZGlzcGxheUNvdW50TGFiZWx9IG5ldyI6Ilt7ZGlzcGxheUNvdW50TGFiZWx9IOC4geC4teC5ieG6veG6veG6vcW1XSIsIntmaXJzdEZhY2V0fSBpbiB7c2Vjb25kRmFjZXR9IjoiW3tmaXJzdEZhY2V0fSDDrMOsw6zguIHguLXguYkge3NlY29uZEZhY2V0fV0iLCJBbGwgam9icyI6IlvhuqzGmsaaIMS1w7bDtsO2w7bDtsOfxaFdIiwiQXVzdHJhbGlhIjoiW%2BG6rMeax5rFoeG5r8WZxIPEg8aaw6zDrMSDxINdIiwiSG9uZyBLb25nIjoiW%2BG4qMO2w7bDtuC4geC4teC5icSjIOG4sMO2w7bDtuC4geC4teC5icSjXSIsIkluZG9uZXNpYSI6IlvDj%2BC4geC4teC5icaMw7bDtuC4geC4teC5ieG6veG6vcWhw6zDrMSDxINdIiwiTWFsYXlzaWEiOiJb4bmCxIPEg8aaxIPEg8O9w73FocOsw6zEg8SDXSIsIk5ldyBaZWFsYW5kIjoiW8OR4bq94bq94bq9xbUgxrXhur3hur3hur3Eg8SDxIPGmsSDxIPEg%2BC4geC4teC5icaMXSIsIlBoaWxpcHBpbmVzIjoiW8ak4bipw6zDrMOsxprDrMOsw6zGpcalw6zDrMOs4LiB4Li14LmJ4bq94bq94bq9xaFdIiwiU2luZ2Fwb3JlIjoiW8Wgw6zDrOC4geC4teC5icSjxIPEg8alw7bDtsWZ4bq94bq9XSIsIlRoYWlsYW5kIjoiW%2BG5ruG4qcSDxIPEg8Osw6zDrMaaxIPEg8SD4LiB4Li14LmJxoxdIiwie2NsYXNzaWZpY2F0aW9uc0NvdW50fSBjbGFzc2lmaWNhdGlvbnMiOiJbe2NsYXNzaWZpY2F0aW9uc0NvdW50fSDDp8aaxIPEg8SDxaHFocOsw6zDrMaSw6zDrMOsw6fEg8SDxIPhua%2FDrMOsw6zDtsO2w7bguIHguLXguYnFoV0ifQ%3D%3D!"
        )
      )
      });
  
      export { translations as default };
    