import type { SearchParams } from '@seek/chalice-types';
import { useMelwaysInfo } from '@seek/melways-react';
import type { Country } from '@seek/melways-sites';
import { useTranslations } from '@vocab/react';

import { getClassificationName } from 'src/modules/refine-job-search';
import { useMelwaysCountry, useMelwaysZone } from 'src/utils/melwaysHelper';

import translations from '../.vocab';

export const useRecentSearchFormatter = (query: SearchParams) => {
  const { t } = useTranslations(translations);
  const country = useMelwaysCountry();
  const zone = useMelwaysZone();
  const { language: languageCode } = useMelwaysInfo();

  const locationDisplayDefault: Record<Country, string> = {
    au: t('Australia'),
    nz: t('New Zealand'),
    hk: t('Hong Kong'),
    th: t('Thailand'),
    id: t('Indonesia'),
    my: t('Malaysia'),
    ph: t('Philippines'),
    sg: t('Singapore'),
  };

  const classificationIds = query.classification?.split(',')?.sort() || [];

  const classifications = classificationIds
    .map((id) => getClassificationName(Number(id), zone, languageCode))
    .filter(Boolean);

  let classificationsDisplayStr = '';
  if (classifications.length === 1) {
    classificationsDisplayStr = classifications[0];
  } else if (classifications.length > 1) {
    classificationsDisplayStr = t('{classificationsCount} classifications', {
      classificationsCount: classifications.length.toString(),
    });
  }

  const firstFacet =
    query.keywords || classificationsDisplayStr || t('All jobs');
  let secondFacet =
    query.where || classificationsDisplayStr || locationDisplayDefault[country];

  if (firstFacet === classificationsDisplayStr) {
    secondFacet = query.where || locationDisplayDefault[country];
  }

  return t('{firstFacet} in {secondFacet}', { firstFacet, secondFacet });
};
