import { useTranslations } from '@vocab/react';
import { Autosuggest, Box, useResponsiveValue } from 'braid-design-system';
import {
  useCallback,
  useEffect,
  useState,
  forwardRef,
  type ComponentProps,
} from 'react';

import { isMobileUserAgent } from 'src/modules/chalice-user-agent/device-detect';
import useLocationSuggestions from 'src/modules/hooks/useLocationSuggestions';
import { useFeatureFlagRefineBarV2Experiment } from 'src/store/featureFlags/hooks';
import { useDispatch, useSelector } from 'src/store/react';
import { clearResults } from 'src/store/results';
import {
  updateWhereField as _updateWhereField,
  whereFieldFocused,
  type whereSuggestionSelected,
} from 'src/store/search';
import { selectWhereField } from 'src/store/selectors';
import { useMelwaysCountry } from 'src/utils/melwaysHelper';

import translations from './.vocab';

type WhereFieldProps = {
  onSuggestionSelected: (
    payload: Parameters<typeof whereSuggestionSelected>[0],
  ) => void;
  onInputBlur: () => void;
  onClear: () => void;
};

const WhereField = forwardRef<HTMLInputElement, WhereFieldProps>(
  ({ onSuggestionSelected, onInputBlur, onClear: onExternalClear }, ref) => {
    const { t } = useTranslations(translations);
    const dispatch = useDispatch();
    const whereField = useSelector(selectWhereField);
    const country = useMelwaysCountry();
    const [whereFieldLocalState, setKeywordFieldLocalState] =
      useState(whereField);
    const { getSuggestions, suggestions } = useLocationSuggestions();
    const { dynamicPillsV2, refineBarV2, stickySearchBar } =
      useFeatureFlagRefineBarV2Experiment();
    const isMobile =
      useResponsiveValue()({
        mobile: true,
        tablet: false,
      }) || isMobileUserAgent;

    const isFeatureFlagRefineBarV2Experiment =
      dynamicPillsV2 || refineBarV2 || stickySearchBar;

    useEffect(() => {
      if (whereField !== whereFieldLocalState) {
        setKeywordFieldLocalState(whereField);
      }
    }, [whereField, whereFieldLocalState]);

    useEffect(() => {
      if (
        isMobile &&
        isFeatureFlagRefineBarV2Experiment &&
        typeof ref !== 'function' &&
        ref?.current &&
        suggestions.some((s) => 'text' in s && s.text === whereFieldLocalState)
      ) {
        ref.current.blur();
      }
    }, [
      isFeatureFlagRefineBarV2Experiment,
      isMobile,
      ref,
      suggestions,
      whereFieldLocalState,
    ]);

    const updateWhereField = (text: string) => {
      setKeywordFieldLocalState(text);
      dispatch(_updateWhereField(text));
    };

    const onClear = () => {
      updateWhereField('');
      dispatch(clearResults());
      getSuggestions({ text: '' });
      onExternalClear();
    };

    const onFocus = useCallback(() => {
      dispatch(whereFieldFocused());
      getSuggestions({ text: whereFieldLocalState });
    }, [dispatch, getSuggestions, whereFieldLocalState]);

    const onChange: ComponentProps<typeof Autosuggest>['onChange'] = (
      params,
    ) => {
      if (params.value) {
        setKeywordFieldLocalState(params.text);
        onSuggestionSelected({
          suggestion: params.value,
          suggestionValue: params.text,
        });
      } else if (params.text !== whereFieldLocalState) {
        updateWhereField(params.text);
        getSuggestions({ text: params.text });
      }
    };

    return (
      <Box
        role="region"
        aria-label={t('Enter a location')}
        data-automation="whereFieldOptions"
      >
        <Autosuggest
          ref={ref}
          id="SearchBar__Where"
          name="where"
          label={t('Where')}
          value={{ text: whereFieldLocalState }}
          type="search"
          onBlur={onInputBlur}
          onClear={onClear}
          onChange={onChange}
          onFocus={onFocus}
          showMobileBackdrop
          scrollToTopOnMobile
          suggestions={suggestions}
          placeholder={
            country === 'nz'
              ? t('Enter suburb, town or city')
              : t('Enter suburb, city, or region')
          }
          data={{ 'hj-allow': true, automation: 'SearchBar__Where' }}
        />
      </Box>
    );
  },
);

export default WhereField;
