import { createContext, useContext, useEffect, useState } from 'react';
import { v4 as uuid } from 'uuid';

import type {
  RECOMMENDED_JOB_AI_VIEW_ORIGIN,
  RECOMMENDED_JOB_VIEW_ORIGIN,
} from './DashboardRecommendations/RecommendedJobCard';
import { useHomeRecommendations } from './hooks/useHomeRecommendations';

type SelectedJobInHomePage = {
  id: string;
  analytics: {
    position: number;
    origin:
      | 'saved-homepage'
      | typeof RECOMMENDED_JOB_VIEW_ORIGIN
      | typeof RECOMMENDED_JOB_AI_VIEW_ORIGIN;
    solHash?: string;
  };
};

const SignedInDashboardContext = createContext<
  ReturnType<typeof useSignedInDashboard>
>({} as ReturnType<typeof useSignedInDashboard>);

export const SignedInDashboardContextProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const signedInDashboardContextValue = useSignedInDashboard();

  return (
    <SignedInDashboardContext.Provider value={signedInDashboardContextValue}>
      {children}
    </SignedInDashboardContext.Provider>
  );
};

export const useSignedInDashboardContext = () =>
  useContext(SignedInDashboardContext);

const useSignedInDashboard = () => {
  const [selectedJob, setSelectedJob] =
    useState<SelectedJobInHomePage | null>();
  const [jobDetailsViewedCorrelationId] = useState(uuid());
  const recommendationsResult = useHomeRecommendations();
  const [shouldShowSkeleton, setShouldShowSkeleton] = useState(true);

  /**
   * Due to the potential mismatch of server and client values of the visitorId, this could
   * mean the client side shows the skeleton after the initial rendered data during ref-fetch.
   * We're showing the skeleton loader as the initial state both on the server and client.
   * This way, we prevent the flickering of the skeleton loader when the visitorId is not aligned.
   */
  useEffect(() => {
    setShouldShowSkeleton(false);
  }, []);
  recommendationsResult.loading =
    recommendationsResult.loading || shouldShowSkeleton;

  return {
    selectedJob,
    setSelectedJob,
    jobDetailsViewedCorrelationId,
    recommendationsResult,
  };
};
