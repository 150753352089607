import type { Job, Maybe, SeekDateTime } from 'src/graphql/graphql';

type CompetitiveJobResult = {
  __typename?: 'JobDetailsRecommendedJob';
  job?: Maybe<Job>;
};

const getMockDateTime = (timestamp: Date, label: string): SeekDateTime => ({
  dateTime: timestamp.toString(),
  dateTimeUtc: timestamp.toUTCString(),
  label,
  longAbsoluteLabel: timestamp.toLocaleTimeString(),
  longLabel: timestamp.toLocaleTimeString(),
  shortLabel: timestamp.toDateString(),
  shortAbsoluteLabel: timestamp.toDateString(),
  seekMaxPollLabel: '',
});

const competitiveJobs: Record<string, CompetitiveJobResult> = {
  '81577053': {
    __typename: 'JobDetailsRecommendedJob',
    job: {
      advertiser: {
        id: 'blah',
        name: 'Kingpin Products',
      },
      title: 'Order Entry / Customer Service',
      id: '81577053',
      location: { label: 'Bayside & South Eastern Suburbs, Melbourne VIC' },
      listedAt: getMockDateTime(new Date(Date.now()), '5 hours ago'),
      products: {
        displayTags: [],
        branding: {
          id: 'abc123',
          logo: {
            url: 'https://bx-branding-gateway.cloud.seek.com.au/da7a623d-ef56-618b-af58-78ee1b84ec63.1/serpLogo',
          },
        },
      },
    } as unknown as Job,
  },
  '82069439': {
    __typename: 'JobDetailsRecommendedJob',
    job: {
      advertiser: {
        id: 'blah',
        name: 'SEEK Limited',
      },
      title: 'Assistant Operations Manager (Squad Lead)',
      id: '82069439',
      location: { label: 'North Sydney, Sydney NSW' },
      listedAt: getMockDateTime(new Date(Date.now()), '2 days ago'),
      products: {
        displayTags: [],
        branding: {
          id: 'abc123',
          logo: {
            url: 'https://bx-branding-gateway.cloud.seek.com.au/f439f542-43b4-64b2-9f6a-024c0eae8293.1/serpLogo',
          },
        },
      },
    } as unknown as Job,
  },
  '81451531': {
    __typename: 'JobDetailsRecommendedJob',
    job: {
      advertiser: {
        id: 'blah',
        name: 'Private Advertiser',
      },
      title: 'Come Work in Tasmania in Sales',
      id: '81451531',
      location: { label: 'Melbourne VIC' },
      listedAt: getMockDateTime(new Date(Date.now()), '1 week ago'),
    } as unknown as Job,
  },
};

export const mockCompetitiveJobs: Record<string, CompetitiveJobResult[]> = {
  '29663971': [competitiveJobs['82069439'], competitiveJobs['81451531']],
  '29663948': [
    competitiveJobs['81577053'],
    competitiveJobs['82069439'],
    competitiveJobs['81451531'],
  ],
};
