import { KeywordAutoSuggest } from '@seek/discover-ui';
import { useHubble } from '@seek/hubble';
import { useTranslations } from '@vocab/react';
import { Box } from 'braid-design-system';
import {
  useCallback,
  useEffect,
  useRef,
  useState,
  type ComponentProps,
  type KeyboardEvent,
} from 'react';

import { useAnalyticsFacade } from 'src/modules/AnalyticsFacade';
import {
  useFeatureFlagRefineBarV2Experiment,
  useSavedAndRecentSearchesFeatureFlag,
} from 'src/store/featureFlags/hooks.ts';
import { useDispatch, useSelector } from 'src/store/react';
import { clearDynamicPills, clearResults } from 'src/store/results';
import {
  updateKeywordsField as _updateKeywordsField,
  updateBaseKeywordParam,
} from 'src/store/search';
import { selectBaseKeywords } from 'src/store/search/selectors';
import {
  selectAuthenticated,
  selectFeatureFlag,
  selectIsResultsLoading,
  selectIsSrp,
  selectKeywordField,
  selectSearchQuery,
} from 'src/store/selectors';
import { useMelwaysCountry } from 'src/utils/melwaysHelper';

import { DismissableTooltip } from '../../DismissableTooltip/DismissableTooltip';
import { useTooltipLocalStorage } from '../../DismissableTooltip/useTooltipLocalStorage';
import { getRefineSearchQuery } from '../../utils/utils';
import {
  SavedRecentSearches,
  type SavedRecentSearchesHandle,
} from '../SavedRecentSearches/SavedRecentSearches';

import translations from './.vocab';

export const KEYWORD_INPUT_FIELD_ID = 'keywords-input';

interface KeywordFieldProps {
  onSuggestionSelected: () => void;
  onInputBlur: () => void;
  onClear: () => void;
  onReturn?: () => void;
  isTooltipShown?: boolean;
}
const KeywordField = ({
  onSuggestionSelected,
  onInputBlur,
  onClear,
  onReturn,
  isTooltipShown = false,
}: KeywordFieldProps) => {
  const isLoading = useSelector(selectIsResultsLoading);
  const analyticsFacade = useAnalyticsFacade();
  const dispatch = useDispatch();

  const keywordsField = useSelector(selectKeywordField);
  const { dynamicPillsV2, refineBarV2 } = useFeatureFlagRefineBarV2Experiment();
  const query = getRefineSearchQuery(useSelector(selectSearchQuery));
  const country = useMelwaysCountry();
  const [keywordsFieldLocalState, setKeywordFieldLocalState] =
    useState(keywordsField);
  const hubble = useHubble();
  const visitorId = hubble.visitorId();
  const isKeywordAutosuggestV2Enabled = useSelector(
    selectFeatureFlag('keywordAutosuggestV2'),
  );
  const showDynamicPills = refineBarV2 || dynamicPillsV2;

  const baseKeywords = useSelector(selectBaseKeywords);
  const isSRP = useSelector(selectIsSrp);
  const [displaySavedAndRecentSearches, setDisplaySavedAndRecentSearches] =
    useState(false);
  const showSavedAndRecentSearchesInSearch =
    useSavedAndRecentSearchesFeatureFlag();
  const { isTooltipDismissed, dismissTooltip } = useTooltipLocalStorage(
    'savedRecentSearchesTooltipDismissed',
  );

  const [showTooltip, setShowTooltip] = useState(false);
  const [animateTooltip, setAnimateTooltip] = useState(false);

  useEffect(() => {
    setShowTooltip(isTooltipShown && !isTooltipDismissed());
    setAnimateTooltip(true);
  }, [isTooltipDismissed, isTooltipShown]);

  const handleCloseTooltip = () => {
    setShowTooltip(false);
    dismissTooltip();
  };

  useEffect(() => {
    if (!isSRP) {
      dispatch(updateBaseKeywordParam(''));
      dispatch(clearDynamicPills());
    } else if (keywordsField && !baseKeywords) {
      dispatch(updateBaseKeywordParam(keywordsField));
    }
  }, [baseKeywords, dispatch, isSRP, keywordsField]);

  useEffect(() => {
    if (keywordsField !== keywordsFieldLocalState) {
      setKeywordFieldLocalState(keywordsField);
    }
  }, [keywordsField, keywordsFieldLocalState]);

  const updateKeywordsField = useCallback(
    (text: string) => {
      setKeywordFieldLocalState(text);
      dispatch(_updateKeywordsField(text));

      if (showDynamicPills) {
        dispatch(updateBaseKeywordParam(text));
      }

      if (showSavedAndRecentSearchesInSearch) {
        setDisplaySavedAndRecentSearches(!text);
      }
    },
    [
      dispatch,
      showDynamicPills,
      setDisplaySavedAndRecentSearches,
      showSavedAndRecentSearchesInSearch,
    ],
  );

  const onKeywordsChange = useCallback(
    (
      ...args: Parameters<ComponentProps<typeof KeywordAutoSuggest>['onChange']>
    ) => {
      const [suggestion] = args;

      analyticsFacade.keywordChanged(
        suggestion.text,
        suggestion.isSuggestion,
        suggestion.previousText ?? '',
        suggestion.keywordListCount,
        suggestion.keywordRank,
        suggestion.suggestionMetadata,
      );
      updateKeywordsField(suggestion.text);

      if (suggestion.isSuggestion) {
        onSuggestionSelected();
      }
    },
    [analyticsFacade, onSuggestionSelected, updateKeywordsField],
  );

  const savedAndRecentSearchesInputCleared = useRef(false);
  const onKeywordsBlur = () => {
    if (showSavedAndRecentSearchesInSearch && displaySavedAndRecentSearches) {
      // We're adding a small timeout so we don't close the saved/recent menu automatically when the user clicks it
      setTimeout(() => {
        setDisplaySavedAndRecentSearches(false);
      }, 150);
    } else {
      const { keywords = '' } = query;

      if (keywordsField !== keywords) {
        updateKeywordsField(keywordsField);
        onInputBlur();
      }
    }
  };

  const onKeywordsClear = () => {
    dispatch(clearResults());
    updateKeywordsField('');
    savedAndRecentSearchesInputCleared.current = true;
    onClear();
  };

  const onKeywordsFocus = () => {
    if (showSavedAndRecentSearchesInSearch) {
      // If the user clicked into the saved/recent menu, close tooltip
      handleCloseTooltip();

      // When clicking the X clear button, Autosuggest immediately focuses the input so this is called before keywordsFieldLocalState is updated to the cleared state
      // The Saved/Recent menu should always reappear after clearing
      const displaySavedAndRecent =
        !keywordsFieldLocalState || savedAndRecentSearchesInputCleared.current;
      setDisplaySavedAndRecentSearches(displaySavedAndRecent);

      savedAndRecentSearchesInputCleared.current = false;
    }
  };

  const onKeyDown = (event: KeyboardEvent) => {
    if (showSavedAndRecentSearchesInSearch && displaySavedAndRecentSearches) {
      const targetKey = event.key;

      switch (targetKey) {
        case 'ArrowDown':
          savedRecentRef.current?.selectNext();
          event.preventDefault(); // Prevent the page from scrolling
          break;
        case 'ArrowUp':
          savedRecentRef.current?.selectPrevious();
          event.preventDefault(); // Prevent the page from scrolling
          break;
        case 'Enter':
          savedRecentRef.current?.selectItem();
          event.preventDefault(); // Prevent the submit event from firing
          setDisplaySavedAndRecentSearches(false);
          break;
        case 'Escape':
          setDisplaySavedAndRecentSearches(false);
          break;
      }
    } else if (onReturn && event.key === 'Enter') {
      event.stopPropagation();
      event.preventDefault();

      onReturn();
    }
  };

  const { t } = useTranslations(translations);

  const shouldShowSavedRecentTooltip =
    !isLoading && showSavedAndRecentSearchesInSearch && !isSRP && showTooltip;

  const authenticated = useSelector(selectAuthenticated);

  const messageDisplay = authenticated
    ? t('Click here to see your recent & saved searches')
    : t('Click here to see your recent searches');

  const savedRecentRef = useRef<SavedRecentSearchesHandle | null>(null);
  return (
    <Box
      role="region"
      aria-label={t('Enter Keywords')}
      data-automation="searchKeywordsField"
      onKeyDown={onKeyDown}
      position="relative"
    >
      <KeywordAutoSuggest
        keywordAutosuggestV2={isKeywordAutosuggestV2Enabled}
        visitorId={visitorId}
        keyword={{ text: keywordsFieldLocalState }}
        id={KEYWORD_INPUT_FIELD_ID}
        onChange={onKeywordsChange}
        onBlur={onKeywordsBlur}
        onClear={onKeywordsClear}
        onFocus={onKeywordsFocus}
        country={country}
        label={t('What')}
        placeholder={t('Enter Keywords')}
        reserveMessageSpace={false}
        showMobileBackdrop={true}
        name="keywords"
      />
      {showSavedAndRecentSearchesInSearch && displaySavedAndRecentSearches ? (
        <SavedRecentSearches ref={savedRecentRef} />
      ) : null}
      {shouldShowSavedRecentTooltip && (
        <DismissableTooltip
          handleClose={handleCloseTooltip}
          message={messageDisplay}
          animateTooltip={animateTooltip}
        />
      )}
    </Box>
  );
};

export default KeywordField;
