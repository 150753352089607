
      import { createLanguage, createTranslationFile } from '@vocab/webpack/web';
      
    const translations = createTranslationFile({
      "en": createLanguage(
        require.resolveWeak("./translations.json-en-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJqb2ItZGV0YWlscy10aXRsZSI6Intub3JtYWxpc2VkUm9sZVRpdGxlfSBKb2IgaW4ge2Jyb2FkZXJMb2NhdGlvbk5hbWV9IGF0IHtub3JtYWxpc2VkT3JnYW5pc2F0aW9uTmFtZX0gLSB7YnJhbmR9Iiwiam9iLWRldGFpbHMtdGl0bGUtbm8tb3JnYW5pc2F0aW9uIjoie25vcm1hbGlzZWRSb2xlVGl0bGV9IEpvYiBpbiB7YnJvYWRlckxvY2F0aW9uTmFtZX0gLSB7YnJhbmR9In0%3D!"),
        () => import(
          /* webpackChunkName: "en-translations" */
          "./translations.json-en-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJqb2ItZGV0YWlscy10aXRsZSI6Intub3JtYWxpc2VkUm9sZVRpdGxlfSBKb2IgaW4ge2Jyb2FkZXJMb2NhdGlvbk5hbWV9IGF0IHtub3JtYWxpc2VkT3JnYW5pc2F0aW9uTmFtZX0gLSB7YnJhbmR9Iiwiam9iLWRldGFpbHMtdGl0bGUtbm8tb3JnYW5pc2F0aW9uIjoie25vcm1hbGlzZWRSb2xlVGl0bGV9IEpvYiBpbiB7YnJvYWRlckxvY2F0aW9uTmFtZX0gLSB7YnJhbmR9In0%3D!"
        )
      ),
"en-AU": createLanguage(
        require.resolveWeak("./translations.json-en-AU-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJqb2ItZGV0YWlscy10aXRsZSI6Intub3JtYWxpc2VkUm9sZVRpdGxlfSBKb2IgaW4ge2Jyb2FkZXJMb2NhdGlvbk5hbWV9IGF0IHtub3JtYWxpc2VkT3JnYW5pc2F0aW9uTmFtZX0gLSB7YnJhbmR9Iiwiam9iLWRldGFpbHMtdGl0bGUtbm8tb3JnYW5pc2F0aW9uIjoie25vcm1hbGlzZWRSb2xlVGl0bGV9IEpvYiBpbiB7YnJvYWRlckxvY2F0aW9uTmFtZX0gLSB7YnJhbmR9In0%3D!"),
        () => import(
          /* webpackChunkName: "en-AU-translations" */
          "./translations.json-en-AU-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJqb2ItZGV0YWlscy10aXRsZSI6Intub3JtYWxpc2VkUm9sZVRpdGxlfSBKb2IgaW4ge2Jyb2FkZXJMb2NhdGlvbk5hbWV9IGF0IHtub3JtYWxpc2VkT3JnYW5pc2F0aW9uTmFtZX0gLSB7YnJhbmR9Iiwiam9iLWRldGFpbHMtdGl0bGUtbm8tb3JnYW5pc2F0aW9uIjoie25vcm1hbGlzZWRSb2xlVGl0bGV9IEpvYiBpbiB7YnJvYWRlckxvY2F0aW9uTmFtZX0gLSB7YnJhbmR9In0%3D!"
        )
      ),
"en-NZ": createLanguage(
        require.resolveWeak("./translations.json-en-NZ-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJqb2ItZGV0YWlscy10aXRsZSI6Intub3JtYWxpc2VkUm9sZVRpdGxlfSBKb2IgaW4ge2Jyb2FkZXJMb2NhdGlvbk5hbWV9IGF0IHtub3JtYWxpc2VkT3JnYW5pc2F0aW9uTmFtZX0gLSB7YnJhbmR9Iiwiam9iLWRldGFpbHMtdGl0bGUtbm8tb3JnYW5pc2F0aW9uIjoie25vcm1hbGlzZWRSb2xlVGl0bGV9IEpvYiBpbiB7YnJvYWRlckxvY2F0aW9uTmFtZX0gLSB7YnJhbmR9In0%3D!"),
        () => import(
          /* webpackChunkName: "en-NZ-translations" */
          "./translations.json-en-NZ-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJqb2ItZGV0YWlscy10aXRsZSI6Intub3JtYWxpc2VkUm9sZVRpdGxlfSBKb2IgaW4ge2Jyb2FkZXJMb2NhdGlvbk5hbWV9IGF0IHtub3JtYWxpc2VkT3JnYW5pc2F0aW9uTmFtZX0gLSB7YnJhbmR9Iiwiam9iLWRldGFpbHMtdGl0bGUtbm8tb3JnYW5pc2F0aW9uIjoie25vcm1hbGlzZWRSb2xlVGl0bGV9IEpvYiBpbiB7YnJvYWRlckxvY2F0aW9uTmFtZX0gLSB7YnJhbmR9In0%3D!"
        )
      ),
"en-HK": createLanguage(
        require.resolveWeak("./translations.json-en-HK-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJqb2ItZGV0YWlscy10aXRsZSI6Intub3JtYWxpc2VkUm9sZVRpdGxlfSBKb2IgaW4ge2Jyb2FkZXJMb2NhdGlvbk5hbWV9IGF0IHtub3JtYWxpc2VkT3JnYW5pc2F0aW9uTmFtZX0gLSB7YnJhbmR9Iiwiam9iLWRldGFpbHMtdGl0bGUtbm8tb3JnYW5pc2F0aW9uIjoie25vcm1hbGlzZWRSb2xlVGl0bGV9IEpvYiBpbiB7YnJvYWRlckxvY2F0aW9uTmFtZX0gLSB7YnJhbmR9In0%3D!"),
        () => import(
          /* webpackChunkName: "en-HK-translations" */
          "./translations.json-en-HK-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJqb2ItZGV0YWlscy10aXRsZSI6Intub3JtYWxpc2VkUm9sZVRpdGxlfSBKb2IgaW4ge2Jyb2FkZXJMb2NhdGlvbk5hbWV9IGF0IHtub3JtYWxpc2VkT3JnYW5pc2F0aW9uTmFtZX0gLSB7YnJhbmR9Iiwiam9iLWRldGFpbHMtdGl0bGUtbm8tb3JnYW5pc2F0aW9uIjoie25vcm1hbGlzZWRSb2xlVGl0bGV9IEpvYiBpbiB7YnJvYWRlckxvY2F0aW9uTmFtZX0gLSB7YnJhbmR9In0%3D!"
        )
      ),
"en-TH": createLanguage(
        require.resolveWeak("./translations.json-en-TH-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJqb2ItZGV0YWlscy10aXRsZSI6Intub3JtYWxpc2VkUm9sZVRpdGxlfSBKb2IgaW4ge2Jyb2FkZXJMb2NhdGlvbk5hbWV9IGF0IHtub3JtYWxpc2VkT3JnYW5pc2F0aW9uTmFtZX0gLSB7YnJhbmR9Iiwiam9iLWRldGFpbHMtdGl0bGUtbm8tb3JnYW5pc2F0aW9uIjoie25vcm1hbGlzZWRSb2xlVGl0bGV9IEpvYiBpbiB7YnJvYWRlckxvY2F0aW9uTmFtZX0gLSB7YnJhbmR9In0%3D!"),
        () => import(
          /* webpackChunkName: "en-TH-translations" */
          "./translations.json-en-TH-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJqb2ItZGV0YWlscy10aXRsZSI6Intub3JtYWxpc2VkUm9sZVRpdGxlfSBKb2IgaW4ge2Jyb2FkZXJMb2NhdGlvbk5hbWV9IGF0IHtub3JtYWxpc2VkT3JnYW5pc2F0aW9uTmFtZX0gLSB7YnJhbmR9Iiwiam9iLWRldGFpbHMtdGl0bGUtbm8tb3JnYW5pc2F0aW9uIjoie25vcm1hbGlzZWRSb2xlVGl0bGV9IEpvYiBpbiB7YnJvYWRlckxvY2F0aW9uTmFtZX0gLSB7YnJhbmR9In0%3D!"
        )
      ),
"en-ID": createLanguage(
        require.resolveWeak("./translations.json-en-ID-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJqb2ItZGV0YWlscy10aXRsZSI6Intub3JtYWxpc2VkUm9sZVRpdGxlfSBKb2IgaW4ge2Jyb2FkZXJMb2NhdGlvbk5hbWV9IGF0IHtub3JtYWxpc2VkT3JnYW5pc2F0aW9uTmFtZX0gLSB7YnJhbmR9Iiwiam9iLWRldGFpbHMtdGl0bGUtbm8tb3JnYW5pc2F0aW9uIjoie25vcm1hbGlzZWRSb2xlVGl0bGV9IEpvYiBpbiB7YnJvYWRlckxvY2F0aW9uTmFtZX0gLSB7YnJhbmR9In0%3D!"),
        () => import(
          /* webpackChunkName: "en-ID-translations" */
          "./translations.json-en-ID-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJqb2ItZGV0YWlscy10aXRsZSI6Intub3JtYWxpc2VkUm9sZVRpdGxlfSBKb2IgaW4ge2Jyb2FkZXJMb2NhdGlvbk5hbWV9IGF0IHtub3JtYWxpc2VkT3JnYW5pc2F0aW9uTmFtZX0gLSB7YnJhbmR9Iiwiam9iLWRldGFpbHMtdGl0bGUtbm8tb3JnYW5pc2F0aW9uIjoie25vcm1hbGlzZWRSb2xlVGl0bGV9IEpvYiBpbiB7YnJvYWRlckxvY2F0aW9uTmFtZX0gLSB7YnJhbmR9In0%3D!"
        )
      ),
"en-SG": createLanguage(
        require.resolveWeak("./translations.json-en-SG-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJqb2ItZGV0YWlscy10aXRsZSI6Intub3JtYWxpc2VkUm9sZVRpdGxlfSB8IHticm9hZGVyTG9jYXRpb25OYW1lfSB8IHtub3JtYWxpc2VkT3JnYW5pc2F0aW9uTmFtZX0gLSB7YnJhbmR9Iiwiam9iLWRldGFpbHMtdGl0bGUtbm8tb3JnYW5pc2F0aW9uIjoie25vcm1hbGlzZWRSb2xlVGl0bGV9IHwge2Jyb2FkZXJMb2NhdGlvbk5hbWV9IC0ge2JyYW5kfSJ9!"),
        () => import(
          /* webpackChunkName: "en-SG-translations" */
          "./translations.json-en-SG-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJqb2ItZGV0YWlscy10aXRsZSI6Intub3JtYWxpc2VkUm9sZVRpdGxlfSB8IHticm9hZGVyTG9jYXRpb25OYW1lfSB8IHtub3JtYWxpc2VkT3JnYW5pc2F0aW9uTmFtZX0gLSB7YnJhbmR9Iiwiam9iLWRldGFpbHMtdGl0bGUtbm8tb3JnYW5pc2F0aW9uIjoie25vcm1hbGlzZWRSb2xlVGl0bGV9IHwge2Jyb2FkZXJMb2NhdGlvbk5hbWV9IC0ge2JyYW5kfSJ9!"
        )
      ),
"en-MY": createLanguage(
        require.resolveWeak("./translations.json-en-MY-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJqb2ItZGV0YWlscy10aXRsZSI6Intub3JtYWxpc2VkUm9sZVRpdGxlfSBKb2IgaW4ge2Jyb2FkZXJMb2NhdGlvbk5hbWV9IGF0IHtub3JtYWxpc2VkT3JnYW5pc2F0aW9uTmFtZX0gLSB7YnJhbmR9Iiwiam9iLWRldGFpbHMtdGl0bGUtbm8tb3JnYW5pc2F0aW9uIjoie25vcm1hbGlzZWRSb2xlVGl0bGV9IEpvYiBpbiB7YnJvYWRlckxvY2F0aW9uTmFtZX0gLSB7YnJhbmR9In0%3D!"),
        () => import(
          /* webpackChunkName: "en-MY-translations" */
          "./translations.json-en-MY-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJqb2ItZGV0YWlscy10aXRsZSI6Intub3JtYWxpc2VkUm9sZVRpdGxlfSBKb2IgaW4ge2Jyb2FkZXJMb2NhdGlvbk5hbWV9IGF0IHtub3JtYWxpc2VkT3JnYW5pc2F0aW9uTmFtZX0gLSB7YnJhbmR9Iiwiam9iLWRldGFpbHMtdGl0bGUtbm8tb3JnYW5pc2F0aW9uIjoie25vcm1hbGlzZWRSb2xlVGl0bGV9IEpvYiBpbiB7YnJvYWRlckxvY2F0aW9uTmFtZX0gLSB7YnJhbmR9In0%3D!"
        )
      ),
"en-PH": createLanguage(
        require.resolveWeak("./translations.json-en-PH-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJqb2ItZGV0YWlscy10aXRsZSI6Intub3JtYWxpc2VkUm9sZVRpdGxlfSBKb2IgaW4ge2Jyb2FkZXJMb2NhdGlvbk5hbWV9IGF0IHtub3JtYWxpc2VkT3JnYW5pc2F0aW9uTmFtZX0gLSB7YnJhbmR9Iiwiam9iLWRldGFpbHMtdGl0bGUtbm8tb3JnYW5pc2F0aW9uIjoie25vcm1hbGlzZWRSb2xlVGl0bGV9IEpvYiBpbiB7YnJvYWRlckxvY2F0aW9uTmFtZX0gLSB7YnJhbmR9In0%3D!"),
        () => import(
          /* webpackChunkName: "en-PH-translations" */
          "./translations.json-en-PH-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJqb2ItZGV0YWlscy10aXRsZSI6Intub3JtYWxpc2VkUm9sZVRpdGxlfSBKb2IgaW4ge2Jyb2FkZXJMb2NhdGlvbk5hbWV9IGF0IHtub3JtYWxpc2VkT3JnYW5pc2F0aW9uTmFtZX0gLSB7YnJhbmR9Iiwiam9iLWRldGFpbHMtdGl0bGUtbm8tb3JnYW5pc2F0aW9uIjoie25vcm1hbGlzZWRSb2xlVGl0bGV9IEpvYiBpbiB7YnJvYWRlckxvY2F0aW9uTmFtZX0gLSB7YnJhbmR9In0%3D!"
        )
      ),
"id": createLanguage(
        require.resolveWeak("./translations.json-id-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJqb2ItZGV0YWlscy10aXRsZSI6Intub3JtYWxpc2VkUm9sZVRpdGxlfSBkaSB7YnJvYWRlckxvY2F0aW9uTmFtZX0gb2xlaCB7bm9ybWFsaXNlZE9yZ2FuaXNhdGlvbk5hbWV9IC0ge2JyYW5kfSIsImpvYi1kZXRhaWxzLXRpdGxlLW5vLW9yZ2FuaXNhdGlvbiI6Intub3JtYWxpc2VkUm9sZVRpdGxlfSBkaSB7YnJvYWRlckxvY2F0aW9uTmFtZX0gLSB7YnJhbmR9In0%3D!"),
        () => import(
          /* webpackChunkName: "id-translations" */
          "./translations.json-id-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJqb2ItZGV0YWlscy10aXRsZSI6Intub3JtYWxpc2VkUm9sZVRpdGxlfSBkaSB7YnJvYWRlckxvY2F0aW9uTmFtZX0gb2xlaCB7bm9ybWFsaXNlZE9yZ2FuaXNhdGlvbk5hbWV9IC0ge2JyYW5kfSIsImpvYi1kZXRhaWxzLXRpdGxlLW5vLW9yZ2FuaXNhdGlvbiI6Intub3JtYWxpc2VkUm9sZVRpdGxlfSBkaSB7YnJvYWRlckxvY2F0aW9uTmFtZX0gLSB7YnJhbmR9In0%3D!"
        )
      ),
"th": createLanguage(
        require.resolveWeak("./translations.json-th-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJqb2ItZGV0YWlscy10aXRsZSI6IuC4leC4s%2BC5geC4q%2BC4meC5iOC4h%2BC4h%2BC4suC4mSB7bm9ybWFsaXNlZFJvbGVUaXRsZX0g4LmD4LiZIHticm9hZGVyTG9jYXRpb25OYW1lfSDguJfguLXguYgge25vcm1hbGlzZWRPcmdhbmlzYXRpb25OYW1lfSAtIHticmFuZH0iLCJqb2ItZGV0YWlscy10aXRsZS1uby1vcmdhbmlzYXRpb24iOiLguJXguLPguYHguKvguJnguYjguIfguIfguLLguJkge25vcm1hbGlzZWRSb2xlVGl0bGV9IOC5g%2BC4mSB7YnJvYWRlckxvY2F0aW9uTmFtZX0gLSB7YnJhbmR9In0%3D!"),
        () => import(
          /* webpackChunkName: "th-translations" */
          "./translations.json-th-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJqb2ItZGV0YWlscy10aXRsZSI6IuC4leC4s%2BC5geC4q%2BC4meC5iOC4h%2BC4h%2BC4suC4mSB7bm9ybWFsaXNlZFJvbGVUaXRsZX0g4LmD4LiZIHticm9hZGVyTG9jYXRpb25OYW1lfSDguJfguLXguYgge25vcm1hbGlzZWRPcmdhbmlzYXRpb25OYW1lfSAtIHticmFuZH0iLCJqb2ItZGV0YWlscy10aXRsZS1uby1vcmdhbmlzYXRpb24iOiLguJXguLPguYHguKvguJnguYjguIfguIfguLLguJkge25vcm1hbGlzZWRSb2xlVGl0bGV9IOC5g%2BC4mSB7YnJvYWRlckxvY2F0aW9uTmFtZX0gLSB7YnJhbmR9In0%3D!"
        )
      ),
"id-ID": createLanguage(
        require.resolveWeak("./translations.json-id-ID-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJqb2ItZGV0YWlscy10aXRsZSI6Intub3JtYWxpc2VkUm9sZVRpdGxlfSBkaSB7YnJvYWRlckxvY2F0aW9uTmFtZX0gb2xlaCB7bm9ybWFsaXNlZE9yZ2FuaXNhdGlvbk5hbWV9IC0ge2JyYW5kfSIsImpvYi1kZXRhaWxzLXRpdGxlLW5vLW9yZ2FuaXNhdGlvbiI6Intub3JtYWxpc2VkUm9sZVRpdGxlfSBkaSB7YnJvYWRlckxvY2F0aW9uTmFtZX0gLSB7YnJhbmR9In0%3D!"),
        () => import(
          /* webpackChunkName: "id-ID-translations" */
          "./translations.json-id-ID-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJqb2ItZGV0YWlscy10aXRsZSI6Intub3JtYWxpc2VkUm9sZVRpdGxlfSBkaSB7YnJvYWRlckxvY2F0aW9uTmFtZX0gb2xlaCB7bm9ybWFsaXNlZE9yZ2FuaXNhdGlvbk5hbWV9IC0ge2JyYW5kfSIsImpvYi1kZXRhaWxzLXRpdGxlLW5vLW9yZ2FuaXNhdGlvbiI6Intub3JtYWxpc2VkUm9sZVRpdGxlfSBkaSB7YnJvYWRlckxvY2F0aW9uTmFtZX0gLSB7YnJhbmR9In0%3D!"
        )
      ),
"th-TH": createLanguage(
        require.resolveWeak("./translations.json-th-TH-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJqb2ItZGV0YWlscy10aXRsZSI6IuC4leC4s%2BC5geC4q%2BC4meC5iOC4h%2BC4h%2BC4suC4mSB7bm9ybWFsaXNlZFJvbGVUaXRsZX0g4LmD4LiZIHticm9hZGVyTG9jYXRpb25OYW1lfSDguJfguLXguYgge25vcm1hbGlzZWRPcmdhbmlzYXRpb25OYW1lfSAtIHticmFuZH0iLCJqb2ItZGV0YWlscy10aXRsZS1uby1vcmdhbmlzYXRpb24iOiLguJXguLPguYHguKvguJnguYjguIfguIfguLLguJkge25vcm1hbGlzZWRSb2xlVGl0bGV9IOC5g%2BC4mSB7YnJvYWRlckxvY2F0aW9uTmFtZX0gLSB7YnJhbmR9In0%3D!"),
        () => import(
          /* webpackChunkName: "th-TH-translations" */
          "./translations.json-th-TH-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJqb2ItZGV0YWlscy10aXRsZSI6IuC4leC4s%2BC5geC4q%2BC4meC5iOC4h%2BC4h%2BC4suC4mSB7bm9ybWFsaXNlZFJvbGVUaXRsZX0g4LmD4LiZIHticm9hZGVyTG9jYXRpb25OYW1lfSDguJfguLXguYgge25vcm1hbGlzZWRPcmdhbmlzYXRpb25OYW1lfSAtIHticmFuZH0iLCJqb2ItZGV0YWlscy10aXRsZS1uby1vcmdhbmlzYXRpb24iOiLguJXguLPguYHguKvguJnguYjguIfguIfguLLguJkge25vcm1hbGlzZWRSb2xlVGl0bGV9IOC5g%2BC4mSB7YnJvYWRlckxvY2F0aW9uTmFtZX0gLSB7YnJhbmR9In0%3D!"
        )
      ),
"en-PSEUDO": createLanguage(
        require.resolveWeak("./translations.json-en-PSEUDO-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJqb2ItZGV0YWlscy10aXRsZSI6Ilt7bm9ybWFsaXNlZFJvbGVUaXRsZX0gxLTDtsO2w7bDnyDDrMOsw6zguIHguLXguYkge2Jyb2FkZXJMb2NhdGlvbk5hbWV9IMSDxIPEg%2BG5ryB7bm9ybWFsaXNlZE9yZ2FuaXNhdGlvbk5hbWV9IC0ge2JyYW5kfV0iLCJqb2ItZGV0YWlscy10aXRsZS1uby1vcmdhbmlzYXRpb24iOiJbe25vcm1hbGlzZWRSb2xlVGl0bGV9IMS0w7bDtsO2w58gw6zDrMOs4LiB4Li14LmJIHticm9hZGVyTG9jYXRpb25OYW1lfSAtIHticmFuZH1dIn0%3D!"),
        () => import(
          /* webpackChunkName: "en-PSEUDO-translations" */
          "./translations.json-en-PSEUDO-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJqb2ItZGV0YWlscy10aXRsZSI6Ilt7bm9ybWFsaXNlZFJvbGVUaXRsZX0gxLTDtsO2w7bDnyDDrMOsw6zguIHguLXguYkge2Jyb2FkZXJMb2NhdGlvbk5hbWV9IMSDxIPEg%2BG5ryB7bm9ybWFsaXNlZE9yZ2FuaXNhdGlvbk5hbWV9IC0ge2JyYW5kfV0iLCJqb2ItZGV0YWlscy10aXRsZS1uby1vcmdhbmlzYXRpb24iOiJbe25vcm1hbGlzZWRSb2xlVGl0bGV9IMS0w7bDtsO2w58gw6zDrMOs4LiB4Li14LmJIHticm9hZGVyTG9jYXRpb25OYW1lfSAtIHticmFuZH1dIn0%3D!"
        )
      )
      });
  
      export { translations as default };
    