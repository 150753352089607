import {
  gql,
  type ApolloClient,
  type NormalizedCacheObject,
} from '@apollo/client';
import type { JobTag, Locale } from '@seek/chalice-types';

import { getRecommendationBadges } from 'src/components/HomePage/Dashboard/SignedInDashboard/SharedComponent/JobCard/hooks/getRecommendationBadges';
import type { RecommendedGlobalJobTagsFragmentFragment } from 'src/graphql/graphql';
import { logger } from 'src/modules/logger';

interface GetRecommendedBadgesForTrackingProps {
  apolloClient: ApolloClient<NormalizedCacheObject>;
  locale: Locale;
  jobId: string;
  isHomepageRecsBadgingStrongApplicantEnabled?: boolean;
  isHomepageRecsBadgingExpiringSoonEarlyApplicantEnabled?: boolean;
}
const getRecommendedBadgesForTracking = ({
  apolloClient,
  jobId,
  locale,
  isHomepageRecsBadgingStrongApplicantEnabled,
  isHomepageRecsBadgingExpiringSoonEarlyApplicantEnabled,
}: GetRecommendedBadgesForTrackingProps) => {
  try {
    const recommendedJob =
      apolloClient.cache.readFragment<RecommendedGlobalJobTagsFragmentFragment>(
        {
          id: `RecommendedGlobalJob:${jobId}`,
          fragment: gql(
            `fragment RecommendedGlobalJobTagsFragment on RecommendedGlobalJob {
          id
          isNew
          tags {
            type
            label(locale: "${locale}")
          }
        }`,
          ),
        },
      );
    const { analyticsBadges } = getRecommendationBadges(
      recommendedJob?.tags as JobTag[] | undefined,
      isHomepageRecsBadgingStrongApplicantEnabled,
      isHomepageRecsBadgingExpiringSoonEarlyApplicantEnabled,
    );
    return analyticsBadges;
  } catch (e) {
    if (e instanceof Error) {
      logger.error(
        { err: e, input: { id: jobId } },
        'Failed to build tracking data for recommended job badges',
      );
    }
    return [];
  }
};
export default getRecommendedBadgesForTracking;
