import { Badge, Box, Inline, Text } from 'braid-design-system';
import type { ComponentProps } from 'react';

interface JobBadgeCommonProps {
  label: string;
  data?: ComponentProps<typeof Badge>['data'];
}
interface JobBadgeWithoutIconProps extends JobBadgeCommonProps {
  tone: ComponentProps<typeof Badge>['tone'];
}

interface JobBadgeWithIconProps extends JobBadgeCommonProps {
  background: ComponentProps<typeof Box>['background'];
  icon: ComponentProps<typeof Text>['icon'];
}

export type JobBadgeProps = JobBadgeWithoutIconProps | JobBadgeWithIconProps;

const BadgeWithIcon = ({
  background,
  label,
  icon,
  data,
}: JobBadgeWithIconProps) => (
  <Box
    borderRadius="standard"
    background={background}
    paddingX="xsmall"
    paddingY="xxsmall"
    data={data}
  >
    <Text
      size="xsmall"
      weight="medium"
      icon={icon}
      data={{ testid: 'job-status-badge' }}
      maxLines={1}
    >
      {label}
    </Text>
  </Box>
);

const JobBadge = ({ label, data, ...restProps }: JobBadgeProps) => {
  const isHasIcon = 'icon' in restProps;
  if (isHasIcon) {
    const { icon, background } = restProps;
    return (
      <BadgeWithIcon
        background={background}
        label={label}
        icon={icon}
        data={data}
      />
    );
  }
  return (
    <Badge tone={restProps.tone} data={data}>
      {label}
    </Badge>
  );
};

export const JobBadges = ({ badges }: { badges: JobBadgeProps[] }) =>
  badges.length ? (
    <Inline space="xxsmall">
      {badges.map((props, index) => (
        <Box key={index} pointerEvents="none">
          <JobBadge {...props} />
        </Box>
      ))}
    </Inline>
  ) : null;
