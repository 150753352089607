import type { JobTag, JobTagType } from '@seek/chalice-types';
import { IconRecommended } from 'braid-design-system';

import type { JobBadgeProps } from '../components/JobBadges/JobBadges';
import type { JobLabelProps } from '../components/JobLabels';

export const getRecommendationBadges = (
  tags: JobTag[] | undefined,
  isHomepageRecsBadgingStrongApplicantEnabled?: boolean,
  isHomepageRecsBadgingExpiringSoonEarlyApplicantEnabled?: boolean,
) => {
  const badges: JobBadgeProps[] = [];
  const labels: Record<string, JobLabelProps> = {};
  const analyticsBadges: JobTagType[] = [];
  let viewedLabel = '';

  tags
    ?.filter((tag): tag is JobTag => Boolean(tag.label))
    .forEach((tag) => {
      switch (tag.type) {
        // New and Unseen are mututally exclusive, we don't have to account for both
        // https://api.jobapi.net/docs/mordor/tags
        case 'NEW':
        case 'UNSEEN':
          badges.push({
            label: tag.label,
            tone: 'positive',
            data: {
              automation: 'newBadge',
            },
          });
          analyticsBadges.push(tag.type);
          break;
        case 'EXPIRES_SOON':
          if (isHomepageRecsBadgingExpiringSoonEarlyApplicantEnabled) {
            labels[tag.type] = {
              label: tag.label,
              tone: 'critical',
              type: tag.type,
            };
            analyticsBadges.push(tag.type);
          }
          break;
        case 'EARLY_APPLICANT':
          if (isHomepageRecsBadgingExpiringSoonEarlyApplicantEnabled) {
            labels[tag.type] = {
              label: tag.label,
              tone: 'info',
              type: tag.type,
            };
          }
          break;
        case 'VIEWED':
          viewedLabel = tag.label;
          analyticsBadges.push(tag.type);
          break;
        case 'TOP_APPLICANT':
          if (isHomepageRecsBadgingStrongApplicantEnabled) {
            badges.push({
              label: tag.label,
              background: 'promoteLight',
              icon: (
                <IconRecommended
                  data={{ automation: 'topApplicantBadgeIcon' }}
                />
              ),
              data: {
                automation: 'topApplicantBadge',
              },
            });
            analyticsBadges.push(tag.type);
          }
          break;
      }
    });
  if (isHomepageRecsBadgingExpiringSoonEarlyApplicantEnabled) {
    if (labels.EXPIRES_SOON) {
      delete labels.EARLY_APPLICANT;
    } else if (labels.EARLY_APPLICANT) {
      analyticsBadges.push('EARLY_APPLICANT');
    }
  }
  return { badges, labels, viewedLabel, analyticsBadges };
};
