import { useTranslations } from '@vocab/react';
import { useCallback, useState } from 'react';

import translations from './.vocab';
import { SalaryRange } from './SalaryRange';
import { useSalaryDisplayLabel } from './useSalaryRangeDisplayLabel/useSalaryRangeDisplayLabel';

export const useSalaryRangeField = (
  handleSelection: (fieldId: string) => void,
) => {
  const { t } = useTranslations(translations);
  const { displayLabel, activeSalaryRangeFromValue, activeSalaryRangeToValue } =
    useSalaryDisplayLabel();
  const [activeSalary, setActiveSalary] = useState(displayLabel);

  const handleSalarySelection = useCallback(
    (value: string) => {
      setActiveSalary(value);
      handleSelection('SalaryRange');
    },
    [handleSelection],
  );

  // Filter out any falsy values & get the count of selected values
  const totalSelected = [
    activeSalaryRangeFromValue,
    activeSalaryRangeToValue,
  ].filter(Boolean).length;

  return {
    id: 'SalaryRange',
    ariaLabel: t('salary range'),
    summarySentence: undefined,
    label: displayLabel,
    filterComponent: () => (
      <SalaryRange handleSelection={handleSalarySelection} />
    ),
    isSelected:
      (Boolean(activeSalary) && Boolean(activeSalaryRangeFromValue)) ||
      Boolean(activeSalaryRangeToValue),
    totalSelected,
  };
};
