import { environment, seekCareerFeedGenAIAPIUrl } from 'src/config';
import type { RecommendedJobsChatContextInput } from 'src/graphql/graphql';
import { parseCookies } from 'src/modules/safe-cookies';
import {
  createAuthenticatedHttpClient,
  createUnauthenticatedHttpClient,
  withDevAuth,
} from 'src/modules/seek-api-request';

type SuggestionResponse = {
  label: string;
  nextPrompt: string;
};

type CreateSessionResponse = {
  sessionId: string;
  message: string;
  suggestions: SuggestionResponse[];
};

type CreateSessionRequestBody = {
  jobIds: string[];
};

type SendMessageResponse = {
  message: string;
  suggestions: SuggestionResponse[];
  conversationFinished: boolean;
  collectGeneralRequirements?: boolean;
  candidateChatContext?: RecommendedJobsChatContextInput;
};

const useDevAuth =
  environment === 'development' ||
  environment === 'dev' ||
  environment === 'dark-prod';

const buildHttpClient = () => {
  if (useDevAuth) {
    return createUnauthenticatedHttpClient({
      retryPolicy: { retries: 0 },
      defaultRequestConfig: {
        headers: {
          ...withDevAuth(parseCookies().AUTH_TOKEN),
        },
      },
    });
  }

  return createAuthenticatedHttpClient({
    retryPolicy: { retries: 0 },
  });
};

export const createSession = async (
  requestBody: CreateSessionRequestBody,
  useAltModel: boolean,
): Promise<CreateSessionResponse> => {
  const httpClient = buildHttpClient();

  const response = await httpClient.send({
    baseURL: seekCareerFeedGenAIAPIUrl,
    url: '/sessions',
    data: requestBody,
    method: 'POST',
    headers: {
      ...(useAltModel ? { homepage_genai_chat_alt_model: 'true' } : {}),
    },
  });

  return response.data;
};

export const sendMessage = async (
  sessionId: string,
  message: string,
  useAltModel: boolean,
  skip: boolean,
): Promise<SendMessageResponse> => {
  const response = await buildHttpClient().send({
    baseURL: seekCareerFeedGenAIAPIUrl,
    url: `/sessions/${sessionId}`,
    data: skip ? { skip: true } : { message },
    method: 'POST',
    headers: {
      ...(useAltModel ? { homepage_genai_chat_alt_model: 'true' } : {}),
    },
  });

  return response.data;
};

export const sendFeedback = async (
  sessionId: string,
  sentiment: boolean,
): Promise<SendMessageResponse> => {
  const response = await buildHttpClient().send({
    baseURL: seekCareerFeedGenAIAPIUrl,
    url: `/sessions/${sessionId}/feedback`,
    data: { sentiment },
    method: 'POST',
  });

  return response.data;
};
