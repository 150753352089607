import { useMelwaysInfo } from '@seek/melways-react';
import { useTranslations } from '@vocab/react';
import { Box } from 'braid-design-system';
import { useCallback, useEffect, useMemo, useState } from 'react';

import { getClassifications } from 'src/modules/refine-job-search';
import refineClassifications from 'src/modules/refine-job-search/refinements/classificationGraph';
import { useSelector } from 'src/store/react';
import { selectSearchQuery } from 'src/store/selectors';
import { useMelwaysZone } from 'src/utils/melwaysHelper';
import { truncateText } from 'src/utils/truncateText/truncateText.ts';

import type { RefineField } from '../RefineBarV2';

import translations from './.vocab';
import Classifications from './Classifications';

export const useClassificationsField = (
  handleSelection: (fieldId: string) => void,
  isCombinedFilter: boolean = false,
): RefineField => {
  const { t } = useTranslations(translations);
  const { language: languageCode } = useMelwaysInfo();
  const zone = useMelwaysZone();
  const searchQuery = useSelector(selectSearchQuery);
  const currentCounts = useSelector(
    (state) => state.search.refinements?.counts,
  );
  const [activeClassifications, setActiveClassifications] = useState<string[]>(
    [],
  );

  const cachedClassifications = useMemo(
    () => getClassifications(zone, languageCode),
    [zone, languageCode],
  );

  const { classification: classifications } = useMemo(
    () =>
      refineClassifications(cachedClassifications, searchQuery, currentCounts),
    [cachedClassifications, searchQuery, currentCounts],
  );
  const selectedClassifications = useMemo(
    () => classifications.filter((item) => item.isActive),
    [classifications],
  );

  useEffect(() => {
    const currentClassifications = classifications
      ? classifications.filter((c) => c.isActive).map((c) => c.value.toString())
      : [];
    setActiveClassifications(currentClassifications);
  }, [classifications]);

  const handleClassificationSelection = useCallback(
    (value: string) => {
      setActiveClassifications((prevActiveClassifications) => {
        if (prevActiveClassifications.includes(value)) {
          return prevActiveClassifications.filter((v) => v !== value);
        }
        return [...prevActiveClassifications, value];
      });
      handleSelection('Classification');
    },
    [handleSelection],
  );

  const totalSelected = activeClassifications.length;
  const isSelected = totalSelected > 0;
  const label = t('Classification');
  const truncatedText = useMemo(
    () => truncateText({ text: label, length: 8 }),
    [label],
  );

  const generateDisplayLabel = () => {
    const labels: Record<number, React.ReactNode | string> = {
      0: (
        <>
          <Box display={{ mobile: 'inline', desktop: 'none' }}>
            {truncatedText}
          </Box>
          <Box display={{ mobile: 'none', desktop: 'inline' }}>{label}</Box>
        </>
      ),
      1: selectedClassifications[0] ? selectedClassifications[0].label : '',
    };

    const activeSelections = activeClassifications.length;
    if (activeSelections > 1) {
      return (
        <>
          <Box display={{ mobile: 'inline', desktop: 'none' }}>
            {`${activeSelections} class`}
          </Box>
          <Box display={{ mobile: 'none', desktop: 'inline' }}>
            {`${activeSelections} ${t('classifications')}`}
          </Box>
        </>
      );
    }
    return labels[activeSelections];
  };

  return {
    id: 'Classification',
    ariaLabel: t('classifications'),
    label: generateDisplayLabel(),
    filterComponent: () => (
      <Classifications
        handleSelection={handleClassificationSelection}
        classifications={classifications}
        isCombinedFilter={isCombinedFilter}
      />
    ),
    isSelected,
    totalSelected,
  };
};
