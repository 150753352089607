import { useQuery } from '@apollo/client';
import { useMelwaysInfo } from '@seek/melways-react';
import { useMemo } from 'react';

import { useSignedInDashboardContext } from 'src/components/HomePage/Dashboard/SignedInDashboard/SignedInDashboardContextProvider';
import {
  JobDetailsPersonalisedDocument,
  SearchSavedAndAppliedJobsDocument,
} from 'src/graphql/graphql';
import { useTimezone } from 'src/hooks/useTimezone';
import { mapToJobDetailsTrackingInput } from 'src/store/jobdetails/utils';
import { useSelector } from 'src/store/react';
import {
  selectAuthenticated,
  selectJobDetailsResult,
  selectJobDetailsViewedCorrelationId,
  selectResultsJobIds,
  selectSessionId,
} from 'src/store/selectors';
import type { ApolloCacheType } from 'src/types/apollo';
import { useMelwaysLocale, useMelwaysZone } from 'src/utils/melwaysHelper';

export const useSavedJobsDataSERP = ({
  shouldSkip = false,
}: {
  shouldSkip?: boolean;
} = {}) => {
  // Search data for saved and applied jobs
  const jobIds = useSelector(selectResultsJobIds);
  const isAuthenticated = useSelector(selectAuthenticated);

  const searchSavedAndAppliedJob = useQuery(SearchSavedAndAppliedJobsDocument, {
    skip: !Boolean(jobIds?.length) || shouldSkip || !isAuthenticated,
    variables: { jobIds },
    ssr: false,
  });

  return searchSavedAndAppliedJob;
};

// The reason of this hook is to get the job id from either the store if the user is in the Job Details Page or Search page, or from the context if the user is in the Homepage signed in dashboard page.
const useCurrentJobDetailsIdForSavedJobsData = () => {
  const storeSelectedJob = useSelector(selectJobDetailsResult);
  const { selectedJob: signedInDashboardSelectedJob } =
    useSignedInDashboardContext();

  return storeSelectedJob?.job.id || signedInDashboardSelectedJob?.id;
};

export const useSavedJobsData = ({
  isJobDetailsView,
  shouldSkip,
}: {
  isJobDetailsView: boolean;
  shouldSkip?: boolean;
}) => {
  // Search data for saved and applied jobs
  const searchSavedAndAppliedJob = useSavedJobsDataSERP({ shouldSkip });
  const sessionId = useSelector(selectSessionId) ?? '';

  // Job details personalised data to indicate if job is saved
  const { language: languageCode } = useMelwaysInfo();
  const locale = useMelwaysLocale();
  const zone = useMelwaysZone();
  const currentJobDetailsId = useCurrentJobDetailsIdForSavedJobsData();
  const timezone = useTimezone();
  const jobDetailsViewedCorrelationId = useSelector(
    selectJobDetailsViewedCorrelationId,
  );

  const jobDetailsPersonalised = useQuery(JobDetailsPersonalisedDocument, {
    skip: !Boolean(currentJobDetailsId) || shouldSkip,
    variables: {
      id: currentJobDetailsId || '',
      languageCode,
      locale,
      timezone,
      zone,
      tracking: mapToJobDetailsTrackingInput({
        jobDetailsViewedCorrelationId,
        sessionId,
      }),
    },
    ssr: false,
  });

  return useMemo(
    () =>
      isJobDetailsView && currentJobDetailsId
        ? jobDetailsPersonalised
        : searchSavedAndAppliedJob,
    [
      isJobDetailsView,
      currentJobDetailsId,
      jobDetailsPersonalised,
      searchSavedAndAppliedJob,
    ],
  );
};

type updateSearchSavedAndAppliedJobsCache = {
  setSaved: (cache: ApolloCacheType) => void;
  setUnsaved: (cache: ApolloCacheType) => void;
};

const noop = () => {};

export const useUpdateSearchSavedAndAppliedJobsCache = () => {
  const jobIds = useSelector(selectResultsJobIds);
  return getUseUpdateSearchSavedAndAppliedJobsCache(jobIds);
};

export const getUseUpdateSearchSavedAndAppliedJobsCache =
  (jobIds: string[]) =>
  (jobId?: string): updateSearchSavedAndAppliedJobsCache => {
    if (!jobId) {
      return { setSaved: noop, setUnsaved: noop };
    }

    const getSearchSavedAndAppliedJobsCachedData = (cache: ApolloCacheType) =>
      cache.readQuery({
        query: SearchSavedAndAppliedJobsDocument,
        variables: { jobIds },
      });

    const updateCache =
      (type: 'add' | 'remove') => (cache: ApolloCacheType) => {
        const searchSavedAndAppliedJobsCachedData =
          getSearchSavedAndAppliedJobsCachedData(cache);

        if (
          searchSavedAndAppliedJobsCachedData &&
          searchSavedAndAppliedJobsCachedData.viewer
        ) {
          cache.writeQuery({
            query: SearchSavedAndAppliedJobsDocument,
            variables: { jobIds },
            data: {
              viewer: {
                __typename: 'Candidate',
                searchAppliedJobs:
                  searchSavedAndAppliedJobsCachedData.viewer.searchAppliedJobs,
                searchSavedJobs:
                  type === 'add'
                    ? [
                        ...searchSavedAndAppliedJobsCachedData.viewer
                          .searchSavedJobs,
                        {
                          __typename: 'SearchSavedJobPartial',
                          jobId,
                        },
                      ]
                    : searchSavedAndAppliedJobsCachedData.viewer.searchSavedJobs.filter(
                        (searchSavedJob) => searchSavedJob.jobId !== jobId,
                      ),
              },
            },
          });
        }
      };

    return { setSaved: updateCache('add'), setUnsaved: updateCache('remove') };
  };

export const useUpdateJobDetailsPersonalisedCache = () => {
  const { language: languageCode } = useMelwaysInfo();
  const locale = useMelwaysLocale();
  const zone = useMelwaysZone();
  const timezone = useTimezone();

  return (jobId?: string) => {
    if (!jobId) {
      return undefined;
    }

    const getJobDetailsPersonalisedCachedData = (cache: ApolloCacheType) =>
      cache.readQuery({
        query: JobDetailsPersonalisedDocument,
        variables: {
          id: jobId,
          languageCode,
          locale,
          timezone,
          zone,
        },
      });

    const updateCache =
      (type: 'add' | 'remove') => (cache: ApolloCacheType) => {
        const jobDetailsPersonalisedCachedData =
          getJobDetailsPersonalisedCachedData(cache);

        if (jobDetailsPersonalisedCachedData) {
          cache.writeQuery({
            query: JobDetailsPersonalisedDocument,
            variables: {
              id: jobId,
              languageCode,
              locale,
              timezone,
              zone,
            },
            data: {
              jobDetails: {
                personalised: {
                  ...jobDetailsPersonalisedCachedData?.jobDetails?.personalised,
                  isSaved: type === 'add',
                },
                __typename: 'JobDetails',
              },
            },
          });
        }
      };

    return { setSaved: updateCache('add'), setUnsave: updateCache('remove') };
  };
};
