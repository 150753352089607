import { isProduction } from '..';
import type { AppConfig } from '../types';

export default {
  zone: 'asia-1',
  defaultLocale: 'en-HK',
  availableLocales: ['en-HK'],
  timedBanners: {
    scamBanner: {
      displayDates: {
        startDate: '2024-12-17T00:00:00.000Z',
        endDate: '2025-01-01T00:00:00.000Z',
      },
    },
  },
  zoneFeatures: {
    NUDGE_ENABLED: false,
    HOMEPAGE_BANNER_TYPE: 'multiple-asia',
    HOMEPAGE_LHS_BANNER_TYPE: 'GENERIC_ASIA_BANNER',
    HOMEPAGE_RHS_BANNER_TYPE: 'GENERIC_ASIA_BANNER',
    SEARCH_PAGE_SIZE: 32,
    SHOW_FLOATING_SAVE_SEARCH: false,
    SHOW_MARKETING_AD_SPLIT_VIEW: true,
    AUTO_SELECT_SPLIT_VIEW_FIRST_JOB: false,
    LMIS_ENABLED: true,
    BEHAVIOURAL_CUES_FILTERS_ENABLED: false,
    LOGGED_OUT_RECS: false,
    REMOTE_SEARCH_FILTER: false,
    REMOTE_SEARCH_FILTER_NEW_LABEL: false,
    KEYWORD_AUTOSUGGEST_V2: true,
    NEW_JOB_CARD_DENSITY: false,
    SERP_JOBCARD_INFO_DENSITY_1: false,
    MATCHED_QUALITIES: false,
    ENTRY_LEVEL_BADGE: true,
  },
  BRANCH_IO_KEY: isProduction
    ? 'key_live_dgOh8Yali0X6D5LPpX679mjkuFnVPBnB'
    : 'key_test_baKe5Wbdl33XD3LQi6260idbvzfPPAfV',
  GPT_ACCOUNT_ID: '23205461125',
} as AppConfig;
