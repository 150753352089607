import type { JobViewType } from '@seek/seek-jobs-analytics';
import { useCallback } from 'react';

import {
  useUpdateHomeSavedJobsCache,
  type SavedJob,
} from 'src/components/HomePage/Dashboard/SignedInDashboard/DashboardSavedJobs/useSavedJobs';
import {
  isBrowserAnalyticsFacade,
  useAnalyticsFacade,
} from 'src/modules/AnalyticsFacade';

import { useSolHash } from '../useSolHash';

import { useSaveJobMutation } from './utils/useSaveJobMutation';
import type { UseToggleSaveJobProps } from './utils/useToggleSavedJob';

interface UseSaveJobHomeRecsProps
  extends Pick<UseToggleSaveJobProps, 'solMetadataReference'> {
  job: SavedJob;
  isSaved?: boolean;
  analytics: {
    jobViewOriginQuery: string;
    jobListingPosition: number;
    jobViewType: JobViewType;
    linkPosition: string;
  };
}

export const useSaveJobHomeRecs = ({
  job,
  solMetadataReference,
  isSaved,
  analytics,
}: UseSaveJobHomeRecsProps) => {
  const updateHomeSavedJobsCache = useUpdateHomeSavedJobsCache(job);
  const analyticsFacade = useAnalyticsFacade();
  const { jobViewOriginQuery, jobListingPosition, jobViewType, linkPosition } =
    analytics;
  const [, solMetadata] = useSolHash({
    id: job.id,
    solMetadataReference,
  });

  const { create: createSavedJob, delete: deleteSavedJob } = useSaveJobMutation(
    {
      view: 'recommendationsList',
    },
    {
      create: {
        context: {
          disableEvictCache: true,
        },
        update: (cache) => {
          updateHomeSavedJobsCache.addSavedJob(cache);
        },
      },
      delete: {
        context: {
          disableEvictCache: true,
        },
        update: (cache) => {
          updateHomeSavedJobsCache.removeSavedJob(cache);
        },
      },
    },
  );

  const toggleSavedJob = useCallback(() => {
    if (isSaved) {
      deleteSavedJob(job.id);

      if (isBrowserAnalyticsFacade(analyticsFacade)) {
        analyticsFacade.saveJobRemoved(
          { solMetadata },
          {
            jobId: job.id,
            jobViewOriginQuery,
            jobListingPosition,
            jobViewType,
            linkPosition,
          },
        );
      }
    } else {
      createSavedJob(job.id);

      if (isBrowserAnalyticsFacade(analyticsFacade)) {
        analyticsFacade.saveJobCreated(
          { solMetadata },
          {
            jobId: job.id,
            jobViewOriginQuery,
            jobListingPosition,
            jobViewType,
            linkPosition,
          },
        );
      }
    }
  }, [
    analyticsFacade,
    createSavedJob,
    deleteSavedJob,
    isSaved,
    job.id,
    jobListingPosition,
    jobViewOriginQuery,
    jobViewType,
    linkPosition,
    solMetadata,
  ]);

  return { toggleSavedJob };
};
