import type { UrlLocation } from '@seek/chalice-types';
import { stringify } from 'query-string';
import { type MouseEvent, useCallback, useMemo } from 'react';

import { signInPath, signInRegUrlPrefix } from 'src/config';
import { useSelector } from 'src/store/react';
import { selectLocation } from 'src/store/selectors';

function getReturnUrl(location: UrlLocation) {
  const { pathname, query } = location;
  const queryString = stringify(query || {});
  return `${pathname || ''}${queryString ? `?${queryString}` : ''}`;
}

export const useGetSignInRedirect = (): ((
  destination?: UrlLocation,
) => [
  string,
  (evt?: MouseEvent<HTMLAnchorElement | HTMLButtonElement>) => void,
]) => {
  const currentLocation = useSelector(selectLocation);

  const navigate = useCallback(
    (signInUrl: string) =>
      (evt?: MouseEvent<HTMLAnchorElement | HTMLButtonElement>) => {
        if (evt) {
          evt.preventDefault();
        }
        window.location.href = signInUrl;
      },
    [],
  );

  return useCallback(
    (destination?: UrlLocation) => {
      const returnUrl = getReturnUrl(destination ?? currentLocation);
      const signInUrl = `${signInRegUrlPrefix}${signInPath}?returnUrl=${encodeURIComponent(
        returnUrl,
      )}`;
      return [signInUrl, navigate(signInUrl)];
    },
    [currentLocation, navigate],
  );
};

export const useSignInRedirect: ReturnType<typeof useGetSignInRedirect> = (
  destination,
) => {
  const getSignInRedirect = useGetSignInRedirect();
  return useMemo(
    () => getSignInRedirect(destination),
    [destination, getSignInRedirect],
  );
};
