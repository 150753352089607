import type { ChaliceStore } from '../types';

import type { TFeatureFlagNames } from './types';

export const selectFeatureFlag =
  (flagName: TFeatureFlagNames) => (state: ChaliceStore) =>
    Boolean(state.featureFlags[flagName]);

export const selectFeatureFlagOverrides = (state: ChaliceStore) =>
  state.featureFlags.overrides;

export const selectSearchTag = (state: ChaliceStore) => state.search.query.tags;
