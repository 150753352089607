import { useTranslations } from '@vocab/react';

import translations from './.vocab';
interface JobDetailsHeadTitleProps {
  brand: string;
  normalisedRoleTitle: string;
  broaderLocationName: string;
  normalisedOrganisationName: string;
}

export const useJobDetailsHeadTitle = ({
  brand,
  normalisedRoleTitle,
  broaderLocationName,
  normalisedOrganisationName,
}: JobDetailsHeadTitleProps): string => {
  const { t } = useTranslations(translations);

  if (!normalisedOrganisationName) {
    return t('job-details-title-no-organisation', {
      normalisedRoleTitle,
      broaderLocationName,
      brand,
    });
  }

  return t('job-details-title', {
    normalisedOrganisationName,
    normalisedRoleTitle,
    broaderLocationName,
    brand,
  });
};
