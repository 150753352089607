/** This file was generated by `pnpm generate-work-arrangements` **/
import type { WorkArrangements } from 'src/config/types';

export const workArrangements: WorkArrangements = {
  'en-AU': [
    { id: '1', label: 'On-site' },
    { id: '2', label: 'Hybrid' },
    { id: '3', label: 'Remote' },
  ],
  'en-HK': [
    { id: '1', label: 'On-site' },
    { id: '2', label: 'Hybrid' },
    { id: '3', label: 'Remote' },
  ],
  'en-ID': [
    { id: '1', label: 'On-site' },
    { id: '2', label: 'Hybrid' },
    { id: '3', label: 'Remote' },
  ],
  'en-MY': [
    { id: '1', label: 'On-site' },
    { id: '2', label: 'Hybrid' },
    { id: '3', label: 'Remote' },
  ],
  'en-NZ': [
    { id: '1', label: 'On-site' },
    { id: '2', label: 'Hybrid' },
    { id: '3', label: 'Remote' },
  ],
  'en-PH': [
    { id: '1', label: 'On-site' },
    { id: '2', label: 'Hybrid' },
    { id: '3', label: 'Remote' },
  ],
  'en-SG': [
    { id: '1', label: 'On-site' },
    { id: '2', label: 'Hybrid' },
    { id: '3', label: 'Remote' },
  ],
  'en-TH': [
    { id: '1', label: 'On-site' },
    { id: '2', label: 'Hybrid' },
    { id: '3', label: 'Remote' },
  ],
  'th-TH': [
    { id: '1', label: 'ในสถานที่' },
    { id: '2', label: 'ไฮบริด' },
    { id: '3', label: 'ระยะไกล' },
  ],
  'id-ID': [
    { id: '1', label: 'Kantor' },
    { id: '2', label: 'Hibrid' },
    { id: '3', label: 'Jarak jauh' },
  ],
  'zh-HK': [
    { id: '1', label: 'On-site' },
    { id: '2', label: 'Hybrid' },
    { id: '3', label: 'Remote' },
  ],
};
