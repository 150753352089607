import { isHomepagePath } from '@seek/seek-jobs-seo';
import { useTranslations } from '@vocab/react';

import { jobDetailsPageRegex } from 'src/modules/routes-regexp';
import { useSelector } from 'src/store/react';
import {
  selectJobDetailsResult,
  selectPoeaCountryPicker,
} from 'src/store/selectors';

import type {
  ReplaceableKeyValues,
  TitleType,
} from '../../../../server/utils/titleOverride/type';
import { useReplaceableKeyValues } from '../../replaceableKeyValues';
import { useSearchParams } from '../../search-results-page/search-params';
import { generateTranslationTypeKey } from '../../search-results-page/utils';

import translations from './.vocab';

export const useDescriptiveTitle = (pathname: string): string => {
  const { t } = useTranslations(translations);
  const isPoeaCountryPicker = useSelector(selectPoeaCountryPicker);
  const jobDetail = useSelector(selectJobDetailsResult);

  const {
    country,
    month,
    fullMonth,
    year,
    brand,
    classDescription,
    commaFormattedClassDescription,
    jobCount,
    keywords,
    locationDescription,
    shortLocationName,
    workArrangementDescription,
    workTypeDescription,
    pageNumberText,
    fallBackTitle,
  } = useReplaceableKeyValues({ mode: 'title' });
  const searchParams = useSearchParams();

  if (!pathname || isHomepagePath(pathname)) {
    return '';
  }
  if (jobDetailsPageRegex.test(pathname)) {
    return jobDetail?.job.title ?? '';
  }

  const jobcountLabel = parseInt(jobCount, 10)
    ? new Intl.NumberFormat().format(parseInt(jobCount, 10)).toString()
    : '';
  const isCompanySearch = Boolean(searchParams?.companysearch);
  const replaceableKeyValues: ReplaceableKeyValues = {
    brand,
    country,
    classDescription: classDescription.toLowerCase(),
    commaFormattedClassDescription,
    fallBackTitle,
    keywords: isCompanySearch ? keywords : keywords.toLowerCase(),
    locationDescription,
    month,
    fullMonth,
    workTypeDescription,
    workArrangementDescription,
    year,
    pageNumberText,
    jobCount: jobcountLabel,
    shortLocationName,
  };

  const getSearchTitle = (k: TitleType): string => {
    switch (k) {
      case 'classification':
        return t('classification-title', replaceableKeyValues);
      case 'classification-location':
        return t('classification-location-title', replaceableKeyValues);
      case 'classification-location-worktype':
        return t(
          'classification-location-worktype-title',
          replaceableKeyValues,
        );
      case 'classification-worktype':
        return t('classification-worktype-title', replaceableKeyValues);
      case 'company':
        return t('company-title', replaceableKeyValues);
      case 'company-location':
        return t('company-location-title', replaceableKeyValues);
      case 'company-location-worktype':
        return t('company-location-worktype-title', replaceableKeyValues);
      case 'company-worktype':
        return t('company-worktype-title', replaceableKeyValues);
      case 'keywords':
        return t('keywords-title', replaceableKeyValues);
      case 'keywords-location':
        return t('keywords-location-title', replaceableKeyValues);
      case 'keywords-location-worktype':
        return t('keywords-location-worktype-title', replaceableKeyValues);
      case 'keywords-worktype':
        return t('keywords-worktype-title', replaceableKeyValues);
      case 'location':
        return t('location-title', replaceableKeyValues);
      case 'location-worktype':
        return t('location-worktype-title', replaceableKeyValues);
      case 'worktype':
        return t('worktype-title', {
          ...replaceableKeyValues,
          workTypeDescription: workTypeDescription.toLowerCase(),
        });
      case 'workarrangement':
        return t('workarrangement-title', {
          ...replaceableKeyValues,
          workArrangementDescription: workArrangementDescription.toLowerCase(),
        });
      case 'classification-workarrangement':
        return t('classification-workarrangement-title', replaceableKeyValues);
      case 'classification-location-workarrangement':
        return t(
          'classification-location-workarrangement-title',
          replaceableKeyValues,
        );
      case 'classification-location-workarrangement-worktype':
        return t(
          'classification-location-workarrangement-worktype-title',
          replaceableKeyValues,
        );
      case 'classification-workarrangement-worktype':
        return t(
          'classification-workarrangement-worktype-title',
          replaceableKeyValues,
        );
      case 'company-workarrangement':
        return t('company-workarrangement-title', replaceableKeyValues);
      case 'company-location-workarrangement':
        return t(
          'company-location-workarrangement-title',
          replaceableKeyValues,
        );
      case 'company-location-workarrangement-worktype':
        return t(
          'company-location-workarrangement-worktype-title',
          replaceableKeyValues,
        );
      case 'company-workarrangement-worktype':
        return t(
          'company-workarrangement-worktype-title',
          replaceableKeyValues,
        );
      case 'keywords-workarrangement':
        return t('keywords-workarrangement-title', replaceableKeyValues);
      case 'keywords-location-workarrangement':
        return t(
          'keywords-location-workarrangement-title',
          replaceableKeyValues,
        );
      case 'keywords-location-workarrangement-worktype':
        return t(
          'keywords-location-workarrangement-worktype-title',
          replaceableKeyValues,
        );
      case 'keywords-workarrangement-worktype':
        return t(
          'keywords-workarrangement-worktype-title',
          replaceableKeyValues,
        );
      case 'location-workarrangement':
        return t('location-workarrangement-title', replaceableKeyValues);
      case 'location-workarrangement-worktype':
        return t(
          'location-workarrangement-worktype-title',
          replaceableKeyValues,
        );
      case 'workarrangement-worktype':
        return t('workarrangement-worktype-title', replaceableKeyValues);
      default:
        return '';
    }
  };

  const FALLBACK_TITLE_KEY = 'fallBackTitle';

  const key: string = generateTranslationTypeKey({
    searchParams,
    classDescription,
    fallbackKey: FALLBACK_TITLE_KEY,
    isPoeaCountryPicker,
  });

  return getSearchTitle(key as TitleType);
};
