import { useMutation, type MutationHookOptions } from '@apollo/client';
import { useMemo } from 'react';

import { useUpdateRecommendedGlobalJobCache } from 'src/components/HomePage/Dashboard/SignedInDashboard/hooks/useHomeRecommendations';
import {
  CreateSavedJobDocument,
  DeleteSavedJobDocument,
  type CreateSavedJobMutation,
  type CreateSavedJobMutationVariables,
  type DeleteSavedJobMutation,
  type DeleteSavedJobMutationVariables,
} from 'src/graphql/graphql';
import type { UseGetToggleSaveJobProps } from 'src/hooks/useSaveJob/utils/useToggleSavedJob';
import { useSelector } from 'src/store/react';
import { selectSessionId } from 'src/store/selectors';
import { useMelwaysLocale, useMelwaysZone } from 'src/utils/melwaysHelper';

import {
  useUpdateJobDetailsPersonalisedCache,
  useUpdateSearchSavedAndAppliedJobsCache,
} from './useSavedJobsData';

export interface UseSaveJobMutationBaseProps {
  create?: MutationHookOptions<
    CreateSavedJobMutation,
    CreateSavedJobMutationVariables
  >;
  delete?: MutationHookOptions<
    DeleteSavedJobMutation,
    DeleteSavedJobMutationVariables
  >;
}

const useSaveJobMutationBase = (options?: UseSaveJobMutationBaseProps) => {
  const updateSearchSavedAndAppliedJobsCache =
    useUpdateSearchSavedAndAppliedJobsCache();
  const updateJobDetailsPersonalisedCache =
    useUpdateJobDetailsPersonalisedCache();
  const updateRecommendedGlobalJobCache = useUpdateRecommendedGlobalJobCache();
  const [createSavedJob] = useMutation(CreateSavedJobDocument, {
    ...options?.create,
    update: (cache, result, updateOptions) => {
      const jobId = updateOptions.variables?.input.id;
      updateSearchSavedAndAppliedJobsCache(jobId)?.setSaved(cache);
      updateJobDetailsPersonalisedCache(jobId)?.setSaved(cache);
      updateRecommendedGlobalJobCache(jobId)?.setSaved(cache);
      options?.create?.update?.(cache, result, updateOptions);
    },
  });

  const [deleteSavedJob] = useMutation(DeleteSavedJobDocument, {
    ...options?.delete,
    update: (cache, result, updateOptions) => {
      const jobId = updateOptions.variables?.input.id;
      updateSearchSavedAndAppliedJobsCache(jobId)?.setUnsaved(cache);
      updateJobDetailsPersonalisedCache(jobId)?.setUnsave(cache);
      updateRecommendedGlobalJobCache(jobId)?.setUnsave(cache);
      options?.delete?.update?.(cache, result, updateOptions);
    },
  });

  return { create: createSavedJob, delete: deleteSavedJob };
};

export const useSaveJobMutation = (
  {
    jobTracking,
    view,
  }: {
    jobTracking?: string;
    view: UseGetToggleSaveJobProps['view'];
  },
  options?: UseSaveJobMutationBaseProps,
) => {
  const sessionId = useSelector(selectSessionId);
  const locale = useMelwaysLocale();
  const zone = useMelwaysZone();

  const { create: createMutation, delete: deleteMutation } =
    useSaveJobMutationBase(options);

  const eventCaptureData = useMemo(
    () => ({
      channel: 'web',
      view,
      serpTracking: jobTracking,
      eventCaptureSessionId: sessionId ?? '',
    }),
    [jobTracking, sessionId, view],
  );

  const createSavedJob = (jobId: string) =>
    createMutation({
      variables: {
        input: {
          id: jobId,
          zone,
          eventCaptureData,
        },
        locale,
      },
    });

  const deleteSavedJob = (jobId: string) =>
    deleteMutation({
      variables: {
        input: {
          id: jobId,
          eventCaptureData,
        },
        locale,
      },
    });

  return { create: createSavedJob, delete: deleteSavedJob };
};
