import type { JobViewType } from '@seek/seek-jobs-analytics';
import { useTranslations } from '@vocab/react';
import { useCallback, useMemo } from 'react';

import { useSignedInDashboardContext } from 'src/components/HomePage/Dashboard/SignedInDashboard/SignedInDashboardContextProvider';
import type {
  JobDetailsPersonalisedQuery,
  SearchSavedAndAppliedJobsQuery,
} from 'src/graphql/graphql';
import { useCurrentPage } from 'src/hooks/useCurrentPage/useCurrentPage';
import { getJobStatusFlags } from 'src/hooks/useSaveJob/utils/jobStatus';
import {
  useGetSignInRedirect,
  type useSignInRedirect,
} from 'src/hooks/useSaveJob/utils/useSignInRedirect';
import { useGetSolHash, type useSolHash } from 'src/hooks/useSolHash';
import {
  isBrowserAnalyticsFacade,
  useAnalyticsFacade,
} from 'src/modules/AnalyticsFacade';
import { useSelector } from 'src/store/react';
import {
  selectAuthenticated,
  selectLocation,
  selectSelectedJobId,
} from 'src/store/selectors';
import { updateA11yAnnouncement } from 'src/utils/a11y/announce';
import { getViewJobOriginRef } from 'src/utils/eventCapture/eventCaptureUtils';
import { getParam } from 'src/utils/urlParams';

import translations from './.vocab';
import {
  useSaveJobMutation,
  type UseSaveJobMutationBaseProps,
} from './useSaveJobMutation';

export interface UseGetToggleSaveJobProps {
  view:
    | 'jobDetails'
    | 'serp'
    | 'recommendationsList'
    | 'competitivePlacement'
    | 'unknown';
  linkPosition: 'job details' | 'listing';
  currentPageSection?: 'competitive recommended jobs';
  jobViewType?: JobViewType;
  jobTracking?: string;
  solMetadataReference?: string;
  savedJobsData?: SearchSavedAndAppliedJobsQuery | JobDetailsPersonalisedQuery;
  forceSave?: boolean;
  savedJobMutation?: UseSaveJobMutationBaseProps;
}

const isSearchSavedAndAppliedJobsData = (
  data:
    | SearchSavedAndAppliedJobsQuery
    | JobDetailsPersonalisedQuery
    | undefined,
): data is SearchSavedAndAppliedJobsQuery =>
  Boolean(data && data.hasOwnProperty('viewer'));

export const useGetToggleSavedJob = ({
  forceSave,
  jobTracking,
  linkPosition,
  currentPageSection,
  jobViewType,
  savedJobsData,
  solMetadataReference,
  view,
  savedJobMutation,
}: UseGetToggleSaveJobProps) => {
  const { t } = useTranslations(translations);
  const analyticsFacade = useAnalyticsFacade();
  const isAuthenticated = useSelector(selectAuthenticated);
  const currentLocation = useSelector(selectLocation);
  const selectedJobId = useSelector(selectSelectedJobId);
  const currentPage = useCurrentPage();
  const jobViewTypeValue = currentPage === 'home' ? 'drawer' : jobViewType;
  const { selectedJob } = useSignedInDashboardContext();

  const getSignInRedirect = useGetSignInRedirect();

  const getSolHash = useGetSolHash();

  const { getIsApplied, getIsSaved } = useMemo(() => {
    const isSearchSavedAndAppliedType =
      isSearchSavedAndAppliedJobsData(savedJobsData);

    return {
      getIsApplied: (jobId: string) => {
        if (isSearchSavedAndAppliedType) {
          return getJobStatusFlags({
            jobId,
            savedAndAppliedJobs: savedJobsData,
          }).isApplied;
        }

        return Boolean(
          savedJobsData?.jobDetails?.personalised?.appliedDateTime
            ?.shortAbsoluteLabel,
        );
      },
      getIsSaved: (jobId: string) => {
        if (isSearchSavedAndAppliedType) {
          return getJobStatusFlags({
            jobId,
            savedAndAppliedJobs: savedJobsData,
          }).isSaved;
        }

        return Boolean(savedJobsData?.jobDetails?.personalised?.isSaved);
      },
    };
  }, [savedJobsData]);

  const { create: createSavedJob, delete: deleteSavedJob } = useSaveJobMutation(
    { jobTracking, view },
    {
      create: {
        ...savedJobMutation?.create,
        onCompleted: (data) => {
          if (data.createSavedJob2.__typename === 'CreateSavedJobSuccess') {
            updateA11yAnnouncement(t('Job saved'));
          } else {
            updateA11yAnnouncement(data.createSavedJob2.errors[0].message);
          }
        },
      },
      delete: {
        ...savedJobMutation?.delete,
        onCompleted: (data) => {
          if (data.deleteSavedJob2.__typename === 'DeleteSavedJobSuccess') {
            updateA11yAnnouncement(t('Job unsaved'));
          } else {
            updateA11yAnnouncement(data.deleteSavedJob2.errors[0].message);
          }
        },
      },
    },
  );

  const { jobListingPosition, jobViewOriginQuery } = useMemo(() => {
    if (selectedJob) {
      return {
        jobListingPosition: selectedJob.analytics.position,
        jobViewOriginQuery: selectedJob.analytics.origin,
      };
    }
    const pos = getParam('pos');
    const ref = getParam('ref');
    return {
      jobListingPosition: pos ? Number(pos) : undefined,
      jobViewOriginQuery: ref || undefined,
    };
  }, [selectedJob]);

  // this function is used by the save button on the job details page and job card
  const toggleSavedJob = useCallback(
    async ({
      jobId,
      redirectToSignIn,
      isSaved,
      solMetadata,
    }: {
      jobId: string;
      redirectToSignIn: ReturnType<typeof useSignInRedirect>[1];
      solMetadata: ReturnType<typeof useSolHash>[1];
      isSaved: boolean;
    }) => {
      const viewJobOriginRef = getViewJobOriginRef();
      if (!isAuthenticated) {
        analyticsFacade.saveJobClicked({
          jobId,
          linkPosition,
          jobViewOriginQuery: viewJobOriginRef,
        });
        redirectToSignIn();
        return;
      }
      if (isSaved && !forceSave) {
        await deleteSavedJob(jobId);

        if (isBrowserAnalyticsFacade(analyticsFacade)) {
          analyticsFacade.saveJobRemoved(
            { solMetadata },
            {
              currentPageSection,
              jobId,
              linkPosition,
              jobListingPosition,
              jobViewOriginQuery,
              jobViewType: jobViewTypeValue,
            },
          );
        }
      } else {
        await createSavedJob(jobId);

        if (isBrowserAnalyticsFacade(analyticsFacade)) {
          analyticsFacade.saveJobCreated(
            { solMetadata },
            {
              currentPageSection,
              jobId,
              linkPosition,
              jobListingPosition,
              jobViewOriginQuery,
              jobViewType: jobViewTypeValue,
            },
          );
        }
      }
    },
    [
      analyticsFacade,
      createSavedJob,
      deleteSavedJob,
      forceSave,
      isAuthenticated,
      jobListingPosition,
      jobViewOriginQuery,
      jobViewTypeValue,
      linkPosition,
      currentPageSection,
    ],
  );

  return useCallback(
    (jobId: string) => {
      const postSignInLocation = {
        ...currentLocation,
        query: {
          ...(currentLocation.query || {}),
          savejob: jobId,
          jobId: selectedJobId,
        },
      };
      const [, redirectToSignIn] = getSignInRedirect(postSignInLocation);

      const [, solMetadata] = getSolHash({
        id: jobId,
        solMetadataReference,
      });

      const isApplied = getIsApplied(jobId);
      const isSaved = getIsSaved(jobId);

      return {
        toggleSavedJob: () =>
          toggleSavedJob({
            jobId,
            redirectToSignIn,
            isSaved,
            solMetadata,
          }),
        isApplied,
        isSaved,
      };
    },
    [
      currentLocation,
      getIsApplied,
      getIsSaved,
      getSignInRedirect,
      getSolHash,
      selectedJobId,
      solMetadataReference,
      toggleSavedJob,
    ],
  );
};

export interface UseToggleSaveJobProps extends UseGetToggleSaveJobProps {
  jobId: string;
}

export const useToggleSavedJob = ({
  jobId,
  ...restProps
}: UseToggleSaveJobProps) => {
  const getToggleSavedJob = useGetToggleSavedJob(restProps);
  return useMemo(() => getToggleSavedJob(jobId), [getToggleSavedJob, jobId]);
};
