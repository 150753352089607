import type { JobTag } from '@seek/chalice-types';
import type { JobBadges } from '@seek/data-tracking-event-catalogue';
import type { useJobDetailsProps } from '@seek/job-details-view';

import type { UrgentJobDisplayTag } from 'src/graphql/graphql';
// tags on Job Card
export const mapJobTagsType = (jobTags?: JobTag[]) =>
  jobTags?.map((j) => j.type) ?? [];

// tags on JDV
const urgentTagTypename: NonNullable<UrgentJobDisplayTag['__typename']> =
  'UrgentJobDisplayTag';
const tagTypeToAnalyticsValueMap: Record<string, JobBadges[number]> = {
  [urgentTagTypename]: 'URGENT',
};

type DisplayTagsWithTypename = NonNullable<
  Parameters<typeof useJobDetailsProps>[0]['jobDetails']['job']['products']
>['displayTags'];

export const mapDisplayTagsType = (
  displayTags?: DisplayTagsWithTypename,
): JobBadges =>
  displayTags
    ?.map(({ __typename }) =>
      __typename ? tagTypeToAnalyticsValueMap[__typename] : null,
    )
    ?.filter((tag): tag is JobBadges[number] => tag !== null) ?? [];

export const mergeTags = ({
  jobCardTags = [],
  jdvTags = [],
}: {
  jobCardTags?: JobBadges;
  jdvTags?: JobBadges;
}): JobBadges => [...new Set([...jobCardTags, ...jdvTags])];
