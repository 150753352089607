import type { Dispatch } from 'redux';

import { createAuthenticatedHttpClient } from 'src/modules/seek-api-request';
import { isAuthenticated } from 'src/modules/seek-jobs-api-client/apis/candidate';
import type { RequestHeaders } from 'src/server/server-render';
import { updateEventCaptureTestTags, updateTestHeaders } from 'src/store/user';

import { forceSeekTestUser } from '../../config';

const testHeaderKeys = [
  'user-test-record',
  'seek-test',
  'seek-test-scope',
  'user-test-scope',
] as const;
export type TestHeaderKeys = (typeof testHeaderKeys)[number];

export type TestHeaders = Partial<Record<TestHeaderKeys, string | undefined>>;

export type TestTags = Partial<{
  testRecord: boolean;
  testScope: string;
}>;

export const extractTestHeaders = (
  requestHeaders: RequestHeaders,
): TestHeaders =>
  Object.entries(requestHeaders).reduce((acc, [headerKey, headerValue]) => {
    if (testHeaderKeys.includes(headerKey as any)) {
      return {
        ...acc,
        [headerKey]: headerValue,
      };
    }
    return acc;
  }, {} as TestHeaders);

export const isTestAgent = (testHeaders: TestHeaders) =>
  testHeaders['seek-test'] === 'true' ||
  testHeaders['user-test-record'] === 'true';

export const getTestScope = (testHeaders: TestHeaders) =>
  testHeaders['user-test-scope'] || testHeaders['seek-test-scope'] || '';

export const getTestTags = (testHeaders: TestHeaders): TestTags | undefined =>
  isTestAgent(testHeaders)
    ? { testRecord: true, testScope: getTestScope(testHeaders) }
    : undefined;

export const updateUserTestData = async (
  dispatch: Dispatch,
  hostname: string | undefined,
) => {
  const authenticated = await isAuthenticated();

  try {
    if (authenticated && hostname) {
      // Authenticated test user in production or staging

      const userTestData =
        await createAuthenticatedHttpClient().getTestUserContext(hostname);

      if (userTestData && userTestData.testRecord) {
        const testHeaders = {
          'user-test-record': String(userTestData.testRecord),
          ...(userTestData.testScope
            ? { 'user-test-scope': userTestData.testScope }
            : {}),
        };

        const testTags = getTestTags(testHeaders);

        return Promise.all([
          dispatch(updateTestHeaders(testHeaders)),
          dispatch(updateEventCaptureTestTags(testTags)),
        ]);
      }
    } else if (window?.SEEK_TEST || (authenticated && forceSeekTestUser)) {
      // Unauthenticated test traffic OR forcing authenticated test user on non-prod/staging domain

      const testHeaders = {
        'seek-test': 'true',
        'seek-test-scope': '',
      };

      return Promise.all([
        dispatch(updateTestHeaders(testHeaders)),
        dispatch(updateEventCaptureTestTags(getTestTags(testHeaders))),
      ]);
    }

    return Promise.all([
      dispatch(updateTestHeaders({})),
      dispatch(updateEventCaptureTestTags()),
    ]);
  } catch {
    return Promise.all([
      dispatch(updateTestHeaders({})),
      dispatch(updateEventCaptureTestTags()),
    ]);
  }
};
