import { useEffect } from 'react';

export const useIntercom = () => {
  useEffect(() => {
    if (typeof window?.Intercom === 'function')
      // eslint-disable-next-line new-cap
      window.Intercom('update', { hide_default_launcher: false });

    return () => {
      if (typeof window?.Intercom === 'function')
        // eslint-disable-next-line new-cap
        window.Intercom('update', { hide_default_launcher: true });
    };
  }, []);
};
