import { memo, type ComponentProps } from 'react';

import { useSaveJobHomeRecs } from 'src/hooks/useSaveJob/useSaveJobHomeRecs';
import useSolMetadataReference from 'src/modules/hooks/useSolMetadataReference';
import SolImpressionTracker from 'src/modules/solTracker/SolImpressionTracker';
import {
  useIsHomepageRecsBadgingExpiringSoonEarlyApplicantEnabled,
  useIsHomepageRecsBadgingStrongApplicantEnabled,
} from 'src/store/featureFlags/hooks';

import { useCareerFeedAIAssistant } from '../CareerFeedAIAssistant/hooks/useCareerFeedAIAssistant';
import JobCard from '../SharedComponent/JobCard/JobCard';
import { getRecommendationBadges } from '../SharedComponent/JobCard/hooks/getRecommendationBadges';

export const RECOMMENDED_JOB_VIEW_ORIGIN = 'recom-homepage';
export const RECOMMENDED_JOB_AI_VIEW_ORIGIN = 'recom-homepage-genai';

const convertToSavedJob = (
  jobInfo: ComponentProps<typeof JobCard>['jobInfo'],
) => ({
  id: jobInfo.id,
  title: jobInfo.title,
  advertiser: {
    name: jobInfo.advertiserName,
  },
  salary: jobInfo.salaryLabel
    ? {
        currencyLabel: jobInfo.salaryCurrencyLabel ?? null,
        label: jobInfo.salaryLabel,
      }
    : null,
  location: {
    label: jobInfo.locationLabel,
  },
  listedAt: jobInfo.listingDateLabel
    ? {
        label: jobInfo.listingDateLabel,
      }
    : null,
  products: jobInfo.srcLogo
    ? {
        branding: {
          logo: {
            url: jobInfo.srcLogo,
          },
        },
      }
    : null,
});

const JobCardWithTracking = (
  props: Omit<ComponentProps<typeof JobCard>, 'analytics'> & {
    position: number;
  },
) => {
  const isHomepageRecsBadgingStrongApplicantEnabled =
    useIsHomepageRecsBadgingStrongApplicantEnabled();
  const isHomepageRecsBadgingExpiringSoonEarlyApplicantEnabled =
    useIsHomepageRecsBadgingExpiringSoonEarlyApplicantEnabled();

  const { persistedCandidateChatContext } = useCareerFeedAIAssistant();

  const solMetadataReference = useSolMetadataReference({
    jobId: props.jobInfo.id,
    isFeaturedOrDisplayKeyType: 'loggedInHomeRecs',
  });

  const { toggleSavedJob } = useSaveJobHomeRecs({
    isSaved: props.jobInfo.isSaved,
    job: convertToSavedJob(props.jobInfo),
    solMetadataReference,
    analytics: {
      jobViewOriginQuery: persistedCandidateChatContext
        ? RECOMMENDED_JOB_AI_VIEW_ORIGIN
        : RECOMMENDED_JOB_VIEW_ORIGIN,
      jobListingPosition: props.position,
      jobViewType: 'job card',
      linkPosition: `${props.position}`,
    },
  });

  const onSaveJobButtonClick = toggleSavedJob;
  const { analyticsBadges } = getRecommendationBadges(
    props.jobInfo.tags,
    isHomepageRecsBadgingStrongApplicantEnabled,
    isHomepageRecsBadgingExpiringSoonEarlyApplicantEnabled,
  );

  return (
    <SolImpressionTracker
      jobId={props.jobInfo.id}
      solMetadata={props.jobInfo.solMetadata}
      jobTags={analyticsBadges}
    >
      <JobCard
        {...props}
        analytics={{
          linkName: 'dashboard-recommended-job-click',
          linkSection: 'Recommended Jobs',
          viewOrigin: persistedCandidateChatContext
            ? RECOMMENDED_JOB_AI_VIEW_ORIGIN
            : RECOMMENDED_JOB_VIEW_ORIGIN,
          position: props.position,
          solHash: solMetadataReference,
        }}
        onSaveJobButtonClick={onSaveJobButtonClick}
      />
    </SolImpressionTracker>
  );
};

export const RecommendedJobCard = memo(JobCardWithTracking);
