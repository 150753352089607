
      import { createLanguage, createTranslationFile } from '@vocab/webpack/web';
      
    const translations = createTranslationFile({
      "en": createLanguage(
        require.resolveWeak("./translations.json-en-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXaGF0IjoiV2hhdCIsIkVudGVyIEtleXdvcmRzIjoiRW50ZXIgS2V5d29yZHMiLCJDbGljayBoZXJlIHRvIHNlZSB5b3VyIHJlY2VudCAmIHNhdmVkIHNlYXJjaGVzIjoiQ2xpY2sgaGVyZSB0byBzZWUgeW91ciByZWNlbnQgJiBzYXZlZCBzZWFyY2hlcyIsIkNsaWNrIGhlcmUgdG8gc2VlIHlvdXIgcmVjZW50IHNlYXJjaGVzIjoiQ2xpY2sgaGVyZSB0byBzZWUgeW91ciByZWNlbnQgc2VhcmNoZXMifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "en-translations" */
          "./translations.json-en-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXaGF0IjoiV2hhdCIsIkVudGVyIEtleXdvcmRzIjoiRW50ZXIgS2V5d29yZHMiLCJDbGljayBoZXJlIHRvIHNlZSB5b3VyIHJlY2VudCAmIHNhdmVkIHNlYXJjaGVzIjoiQ2xpY2sgaGVyZSB0byBzZWUgeW91ciByZWNlbnQgJiBzYXZlZCBzZWFyY2hlcyIsIkNsaWNrIGhlcmUgdG8gc2VlIHlvdXIgcmVjZW50IHNlYXJjaGVzIjoiQ2xpY2sgaGVyZSB0byBzZWUgeW91ciByZWNlbnQgc2VhcmNoZXMifQ%3D%3D!"
        )
      ),
"en-AU": createLanguage(
        require.resolveWeak("./translations.json-en-AU-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXaGF0IjoiV2hhdCIsIkVudGVyIEtleXdvcmRzIjoiRW50ZXIgS2V5d29yZHMiLCJDbGljayBoZXJlIHRvIHNlZSB5b3VyIHJlY2VudCAmIHNhdmVkIHNlYXJjaGVzIjoiQ2xpY2sgaGVyZSB0byBzZWUgeW91ciByZWNlbnQgJiBzYXZlZCBzZWFyY2hlcyIsIkNsaWNrIGhlcmUgdG8gc2VlIHlvdXIgcmVjZW50IHNlYXJjaGVzIjoiQ2xpY2sgaGVyZSB0byBzZWUgeW91ciByZWNlbnQgc2VhcmNoZXMifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "en-AU-translations" */
          "./translations.json-en-AU-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXaGF0IjoiV2hhdCIsIkVudGVyIEtleXdvcmRzIjoiRW50ZXIgS2V5d29yZHMiLCJDbGljayBoZXJlIHRvIHNlZSB5b3VyIHJlY2VudCAmIHNhdmVkIHNlYXJjaGVzIjoiQ2xpY2sgaGVyZSB0byBzZWUgeW91ciByZWNlbnQgJiBzYXZlZCBzZWFyY2hlcyIsIkNsaWNrIGhlcmUgdG8gc2VlIHlvdXIgcmVjZW50IHNlYXJjaGVzIjoiQ2xpY2sgaGVyZSB0byBzZWUgeW91ciByZWNlbnQgc2VhcmNoZXMifQ%3D%3D!"
        )
      ),
"en-NZ": createLanguage(
        require.resolveWeak("./translations.json-en-NZ-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXaGF0IjoiV2hhdCIsIkVudGVyIEtleXdvcmRzIjoiRW50ZXIgS2V5d29yZHMvS3VwdW1hdHVhIiwiQ2xpY2sgaGVyZSB0byBzZWUgeW91ciByZWNlbnQgJiBzYXZlZCBzZWFyY2hlcyI6IkNsaWNrIGhlcmUgdG8gc2VlIHlvdXIgcmVjZW50ICYgc2F2ZWQgc2VhcmNoZXMiLCJDbGljayBoZXJlIHRvIHNlZSB5b3VyIHJlY2VudCBzZWFyY2hlcyI6IkNsaWNrIGhlcmUgdG8gc2VlIHlvdXIgcmVjZW50IHNlYXJjaGVzIn0%3D!"),
        () => import(
          /* webpackChunkName: "en-NZ-translations" */
          "./translations.json-en-NZ-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXaGF0IjoiV2hhdCIsIkVudGVyIEtleXdvcmRzIjoiRW50ZXIgS2V5d29yZHMvS3VwdW1hdHVhIiwiQ2xpY2sgaGVyZSB0byBzZWUgeW91ciByZWNlbnQgJiBzYXZlZCBzZWFyY2hlcyI6IkNsaWNrIGhlcmUgdG8gc2VlIHlvdXIgcmVjZW50ICYgc2F2ZWQgc2VhcmNoZXMiLCJDbGljayBoZXJlIHRvIHNlZSB5b3VyIHJlY2VudCBzZWFyY2hlcyI6IkNsaWNrIGhlcmUgdG8gc2VlIHlvdXIgcmVjZW50IHNlYXJjaGVzIn0%3D!"
        )
      ),
"en-HK": createLanguage(
        require.resolveWeak("./translations.json-en-HK-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXaGF0IjoiV2hhdCIsIkVudGVyIEtleXdvcmRzIjoiRW50ZXIgS2V5d29yZHMiLCJDbGljayBoZXJlIHRvIHNlZSB5b3VyIHJlY2VudCAmIHNhdmVkIHNlYXJjaGVzIjoiQ2xpY2sgaGVyZSB0byBzZWUgeW91ciByZWNlbnQgJiBzYXZlZCBzZWFyY2hlcyIsIkNsaWNrIGhlcmUgdG8gc2VlIHlvdXIgcmVjZW50IHNlYXJjaGVzIjoiQ2xpY2sgaGVyZSB0byBzZWUgeW91ciByZWNlbnQgc2VhcmNoZXMifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "en-HK-translations" */
          "./translations.json-en-HK-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXaGF0IjoiV2hhdCIsIkVudGVyIEtleXdvcmRzIjoiRW50ZXIgS2V5d29yZHMiLCJDbGljayBoZXJlIHRvIHNlZSB5b3VyIHJlY2VudCAmIHNhdmVkIHNlYXJjaGVzIjoiQ2xpY2sgaGVyZSB0byBzZWUgeW91ciByZWNlbnQgJiBzYXZlZCBzZWFyY2hlcyIsIkNsaWNrIGhlcmUgdG8gc2VlIHlvdXIgcmVjZW50IHNlYXJjaGVzIjoiQ2xpY2sgaGVyZSB0byBzZWUgeW91ciByZWNlbnQgc2VhcmNoZXMifQ%3D%3D!"
        )
      ),
"en-TH": createLanguage(
        require.resolveWeak("./translations.json-en-TH-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXaGF0IjoiV2hhdCIsIkVudGVyIEtleXdvcmRzIjoiRW50ZXIgS2V5d29yZHMiLCJDbGljayBoZXJlIHRvIHNlZSB5b3VyIHJlY2VudCAmIHNhdmVkIHNlYXJjaGVzIjoiQ2xpY2sgaGVyZSB0byBzZWUgeW91ciByZWNlbnQgJiBzYXZlZCBzZWFyY2hlcyIsIkNsaWNrIGhlcmUgdG8gc2VlIHlvdXIgcmVjZW50IHNlYXJjaGVzIjoiQ2xpY2sgaGVyZSB0byBzZWUgeW91ciByZWNlbnQgc2VhcmNoZXMifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "en-TH-translations" */
          "./translations.json-en-TH-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXaGF0IjoiV2hhdCIsIkVudGVyIEtleXdvcmRzIjoiRW50ZXIgS2V5d29yZHMiLCJDbGljayBoZXJlIHRvIHNlZSB5b3VyIHJlY2VudCAmIHNhdmVkIHNlYXJjaGVzIjoiQ2xpY2sgaGVyZSB0byBzZWUgeW91ciByZWNlbnQgJiBzYXZlZCBzZWFyY2hlcyIsIkNsaWNrIGhlcmUgdG8gc2VlIHlvdXIgcmVjZW50IHNlYXJjaGVzIjoiQ2xpY2sgaGVyZSB0byBzZWUgeW91ciByZWNlbnQgc2VhcmNoZXMifQ%3D%3D!"
        )
      ),
"en-ID": createLanguage(
        require.resolveWeak("./translations.json-en-ID-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXaGF0IjoiV2hhdCIsIkVudGVyIEtleXdvcmRzIjoiRW50ZXIgS2V5d29yZHMiLCJDbGljayBoZXJlIHRvIHNlZSB5b3VyIHJlY2VudCAmIHNhdmVkIHNlYXJjaGVzIjoiQ2xpY2sgaGVyZSB0byBzZWUgeW91ciByZWNlbnQgJiBzYXZlZCBzZWFyY2hlcyIsIkNsaWNrIGhlcmUgdG8gc2VlIHlvdXIgcmVjZW50IHNlYXJjaGVzIjoiQ2xpY2sgaGVyZSB0byBzZWUgeW91ciByZWNlbnQgc2VhcmNoZXMifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "en-ID-translations" */
          "./translations.json-en-ID-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXaGF0IjoiV2hhdCIsIkVudGVyIEtleXdvcmRzIjoiRW50ZXIgS2V5d29yZHMiLCJDbGljayBoZXJlIHRvIHNlZSB5b3VyIHJlY2VudCAmIHNhdmVkIHNlYXJjaGVzIjoiQ2xpY2sgaGVyZSB0byBzZWUgeW91ciByZWNlbnQgJiBzYXZlZCBzZWFyY2hlcyIsIkNsaWNrIGhlcmUgdG8gc2VlIHlvdXIgcmVjZW50IHNlYXJjaGVzIjoiQ2xpY2sgaGVyZSB0byBzZWUgeW91ciByZWNlbnQgc2VhcmNoZXMifQ%3D%3D!"
        )
      ),
"en-SG": createLanguage(
        require.resolveWeak("./translations.json-en-SG-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXaGF0IjoiV2hhdCIsIkVudGVyIEtleXdvcmRzIjoiRW50ZXIgS2V5d29yZHMiLCJDbGljayBoZXJlIHRvIHNlZSB5b3VyIHJlY2VudCAmIHNhdmVkIHNlYXJjaGVzIjoiQ2xpY2sgaGVyZSB0byBzZWUgeW91ciByZWNlbnQgJiBzYXZlZCBzZWFyY2hlcyIsIkNsaWNrIGhlcmUgdG8gc2VlIHlvdXIgcmVjZW50IHNlYXJjaGVzIjoiQ2xpY2sgaGVyZSB0byBzZWUgeW91ciByZWNlbnQgc2VhcmNoZXMifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "en-SG-translations" */
          "./translations.json-en-SG-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXaGF0IjoiV2hhdCIsIkVudGVyIEtleXdvcmRzIjoiRW50ZXIgS2V5d29yZHMiLCJDbGljayBoZXJlIHRvIHNlZSB5b3VyIHJlY2VudCAmIHNhdmVkIHNlYXJjaGVzIjoiQ2xpY2sgaGVyZSB0byBzZWUgeW91ciByZWNlbnQgJiBzYXZlZCBzZWFyY2hlcyIsIkNsaWNrIGhlcmUgdG8gc2VlIHlvdXIgcmVjZW50IHNlYXJjaGVzIjoiQ2xpY2sgaGVyZSB0byBzZWUgeW91ciByZWNlbnQgc2VhcmNoZXMifQ%3D%3D!"
        )
      ),
"en-MY": createLanguage(
        require.resolveWeak("./translations.json-en-MY-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXaGF0IjoiV2hhdCIsIkVudGVyIEtleXdvcmRzIjoiRW50ZXIgS2V5d29yZHMiLCJDbGljayBoZXJlIHRvIHNlZSB5b3VyIHJlY2VudCAmIHNhdmVkIHNlYXJjaGVzIjoiQ2xpY2sgaGVyZSB0byBzZWUgeW91ciByZWNlbnQgJiBzYXZlZCBzZWFyY2hlcyIsIkNsaWNrIGhlcmUgdG8gc2VlIHlvdXIgcmVjZW50IHNlYXJjaGVzIjoiQ2xpY2sgaGVyZSB0byBzZWUgeW91ciByZWNlbnQgc2VhcmNoZXMifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "en-MY-translations" */
          "./translations.json-en-MY-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXaGF0IjoiV2hhdCIsIkVudGVyIEtleXdvcmRzIjoiRW50ZXIgS2V5d29yZHMiLCJDbGljayBoZXJlIHRvIHNlZSB5b3VyIHJlY2VudCAmIHNhdmVkIHNlYXJjaGVzIjoiQ2xpY2sgaGVyZSB0byBzZWUgeW91ciByZWNlbnQgJiBzYXZlZCBzZWFyY2hlcyIsIkNsaWNrIGhlcmUgdG8gc2VlIHlvdXIgcmVjZW50IHNlYXJjaGVzIjoiQ2xpY2sgaGVyZSB0byBzZWUgeW91ciByZWNlbnQgc2VhcmNoZXMifQ%3D%3D!"
        )
      ),
"en-PH": createLanguage(
        require.resolveWeak("./translations.json-en-PH-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXaGF0IjoiV2hhdCIsIkVudGVyIEtleXdvcmRzIjoiRW50ZXIgS2V5d29yZHMiLCJDbGljayBoZXJlIHRvIHNlZSB5b3VyIHJlY2VudCAmIHNhdmVkIHNlYXJjaGVzIjoiQ2xpY2sgaGVyZSB0byBzZWUgeW91ciByZWNlbnQgJiBzYXZlZCBzZWFyY2hlcyIsIkNsaWNrIGhlcmUgdG8gc2VlIHlvdXIgcmVjZW50IHNlYXJjaGVzIjoiQ2xpY2sgaGVyZSB0byBzZWUgeW91ciByZWNlbnQgc2VhcmNoZXMifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "en-PH-translations" */
          "./translations.json-en-PH-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXaGF0IjoiV2hhdCIsIkVudGVyIEtleXdvcmRzIjoiRW50ZXIgS2V5d29yZHMiLCJDbGljayBoZXJlIHRvIHNlZSB5b3VyIHJlY2VudCAmIHNhdmVkIHNlYXJjaGVzIjoiQ2xpY2sgaGVyZSB0byBzZWUgeW91ciByZWNlbnQgJiBzYXZlZCBzZWFyY2hlcyIsIkNsaWNrIGhlcmUgdG8gc2VlIHlvdXIgcmVjZW50IHNlYXJjaGVzIjoiQ2xpY2sgaGVyZSB0byBzZWUgeW91ciByZWNlbnQgc2VhcmNoZXMifQ%3D%3D!"
        )
      ),
"id": createLanguage(
        require.resolveWeak("./translations.json-id-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXaGF0IjoiQXBhIiwiRW50ZXIgS2V5d29yZHMiOiJNYXN1a2thbiBrYXRhIGt1bmNpIiwiQ2xpY2sgaGVyZSB0byBzZWUgeW91ciByZWNlbnQgJiBzYXZlZCBzZWFyY2hlcyI6IktsaWsgdW50dWsgbGloYXQgcGVuY2FyaWFuIHRlcmJhcnUgJiB0ZXJzaW1wYW4iLCJDbGljayBoZXJlIHRvIHNlZSB5b3VyIHJlY2VudCBzZWFyY2hlcyI6IktsaWsgdW50dWsgbGloYXQgcGVuY2FyaWFuIHRlcmJhcnUifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "id-translations" */
          "./translations.json-id-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXaGF0IjoiQXBhIiwiRW50ZXIgS2V5d29yZHMiOiJNYXN1a2thbiBrYXRhIGt1bmNpIiwiQ2xpY2sgaGVyZSB0byBzZWUgeW91ciByZWNlbnQgJiBzYXZlZCBzZWFyY2hlcyI6IktsaWsgdW50dWsgbGloYXQgcGVuY2FyaWFuIHRlcmJhcnUgJiB0ZXJzaW1wYW4iLCJDbGljayBoZXJlIHRvIHNlZSB5b3VyIHJlY2VudCBzZWFyY2hlcyI6IktsaWsgdW50dWsgbGloYXQgcGVuY2FyaWFuIHRlcmJhcnUifQ%3D%3D!"
        )
      ),
"th": createLanguage(
        require.resolveWeak("./translations.json-th-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXaGF0Ijoi4LiE4LmJ4LiZ4Lir4Liy4LiH4Liy4LiZIiwiRW50ZXIgS2V5d29yZHMiOiLguKPguLDguJrguLjguITguLXguKLguYzguYDguKfguLTguKPguYzguJQiLCJDbGljayBoZXJlIHRvIHNlZSB5b3VyIHJlY2VudCAmIHNhdmVkIHNlYXJjaGVzIjoi4LiE4Lil4Li04LiB4LiX4Li14LmI4LiZ4Li14LmI4LmA4Lie4Li34LmI4Lit4LiU4Li54LiB4Liy4Lij4LiE4LmJ4LiZ4Lir4Liy4Lil4LmI4Liy4Liq4Li44LiU4LmB4Lil4Liw4LiB4Liy4Lij4LiE4LmJ4LiZ4Lir4Liy4LiE4Li44LiT4LiX4Li14LmI4Lia4Lix4LiZ4LiX4Li24LiB4LmE4Lin4LmJIiwiQ2xpY2sgaGVyZSB0byBzZWUgeW91ciByZWNlbnQgc2VhcmNoZXMiOiLguITguKXguLTguIHguJfguLXguYjguJnguLXguYjguYDguJ7guLfguYjguK3guJTguLnguIHguLLguKPguITguYnguJnguKvguLLguKXguYjguLLguKrguLjguJTguILguK3guIfguITguLjguJMifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "th-translations" */
          "./translations.json-th-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXaGF0Ijoi4LiE4LmJ4LiZ4Lir4Liy4LiH4Liy4LiZIiwiRW50ZXIgS2V5d29yZHMiOiLguKPguLDguJrguLjguITguLXguKLguYzguYDguKfguLTguKPguYzguJQiLCJDbGljayBoZXJlIHRvIHNlZSB5b3VyIHJlY2VudCAmIHNhdmVkIHNlYXJjaGVzIjoi4LiE4Lil4Li04LiB4LiX4Li14LmI4LiZ4Li14LmI4LmA4Lie4Li34LmI4Lit4LiU4Li54LiB4Liy4Lij4LiE4LmJ4LiZ4Lir4Liy4Lil4LmI4Liy4Liq4Li44LiU4LmB4Lil4Liw4LiB4Liy4Lij4LiE4LmJ4LiZ4Lir4Liy4LiE4Li44LiT4LiX4Li14LmI4Lia4Lix4LiZ4LiX4Li24LiB4LmE4Lin4LmJIiwiQ2xpY2sgaGVyZSB0byBzZWUgeW91ciByZWNlbnQgc2VhcmNoZXMiOiLguITguKXguLTguIHguJfguLXguYjguJnguLXguYjguYDguJ7guLfguYjguK3guJTguLnguIHguLLguKPguITguYnguJnguKvguLLguKXguYjguLLguKrguLjguJTguILguK3guIfguITguLjguJMifQ%3D%3D!"
        )
      ),
"id-ID": createLanguage(
        require.resolveWeak("./translations.json-id-ID-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXaGF0IjoiQXBhIiwiRW50ZXIgS2V5d29yZHMiOiJNYXN1a2thbiBrYXRhIGt1bmNpIiwiQ2xpY2sgaGVyZSB0byBzZWUgeW91ciByZWNlbnQgJiBzYXZlZCBzZWFyY2hlcyI6IktsaWsgdW50dWsgbGloYXQgcGVuY2FyaWFuIHRlcmJhcnUgJiB0ZXJzaW1wYW4iLCJDbGljayBoZXJlIHRvIHNlZSB5b3VyIHJlY2VudCBzZWFyY2hlcyI6IktsaWsgdW50dWsgbGloYXQgcGVuY2FyaWFuIHRlcmJhcnUifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "id-ID-translations" */
          "./translations.json-id-ID-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXaGF0IjoiQXBhIiwiRW50ZXIgS2V5d29yZHMiOiJNYXN1a2thbiBrYXRhIGt1bmNpIiwiQ2xpY2sgaGVyZSB0byBzZWUgeW91ciByZWNlbnQgJiBzYXZlZCBzZWFyY2hlcyI6IktsaWsgdW50dWsgbGloYXQgcGVuY2FyaWFuIHRlcmJhcnUgJiB0ZXJzaW1wYW4iLCJDbGljayBoZXJlIHRvIHNlZSB5b3VyIHJlY2VudCBzZWFyY2hlcyI6IktsaWsgdW50dWsgbGloYXQgcGVuY2FyaWFuIHRlcmJhcnUifQ%3D%3D!"
        )
      ),
"th-TH": createLanguage(
        require.resolveWeak("./translations.json-th-TH-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXaGF0Ijoi4LiE4LmJ4LiZ4Lir4Liy4LiH4Liy4LiZIiwiRW50ZXIgS2V5d29yZHMiOiLguKPguLDguJrguLjguITguLXguKLguYzguYDguKfguLTguKPguYzguJQiLCJDbGljayBoZXJlIHRvIHNlZSB5b3VyIHJlY2VudCAmIHNhdmVkIHNlYXJjaGVzIjoi4LiE4Lil4Li04LiB4LiX4Li14LmI4LiZ4Li14LmI4LmA4Lie4Li34LmI4Lit4LiU4Li54LiB4Liy4Lij4LiE4LmJ4LiZ4Lir4Liy4Lil4LmI4Liy4Liq4Li44LiU4LmB4Lil4Liw4LiB4Liy4Lij4LiE4LmJ4LiZ4Lir4Liy4LiE4Li44LiT4LiX4Li14LmI4Lia4Lix4LiZ4LiX4Li24LiB4LmE4Lin4LmJIiwiQ2xpY2sgaGVyZSB0byBzZWUgeW91ciByZWNlbnQgc2VhcmNoZXMiOiLguITguKXguLTguIHguJfguLXguYjguJnguLXguYjguYDguJ7guLfguYjguK3guJTguLnguIHguLLguKPguITguYnguJnguKvguLLguKXguYjguLLguKrguLjguJTguILguK3guIfguITguLjguJMifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "th-TH-translations" */
          "./translations.json-th-TH-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXaGF0Ijoi4LiE4LmJ4LiZ4Lir4Liy4LiH4Liy4LiZIiwiRW50ZXIgS2V5d29yZHMiOiLguKPguLDguJrguLjguITguLXguKLguYzguYDguKfguLTguKPguYzguJQiLCJDbGljayBoZXJlIHRvIHNlZSB5b3VyIHJlY2VudCAmIHNhdmVkIHNlYXJjaGVzIjoi4LiE4Lil4Li04LiB4LiX4Li14LmI4LiZ4Li14LmI4LmA4Lie4Li34LmI4Lit4LiU4Li54LiB4Liy4Lij4LiE4LmJ4LiZ4Lir4Liy4Lil4LmI4Liy4Liq4Li44LiU4LmB4Lil4Liw4LiB4Liy4Lij4LiE4LmJ4LiZ4Lir4Liy4LiE4Li44LiT4LiX4Li14LmI4Lia4Lix4LiZ4LiX4Li24LiB4LmE4Lin4LmJIiwiQ2xpY2sgaGVyZSB0byBzZWUgeW91ciByZWNlbnQgc2VhcmNoZXMiOiLguITguKXguLTguIHguJfguLXguYjguJnguLXguYjguYDguJ7guLfguYjguK3guJTguLnguIHguLLguKPguITguYnguJnguKvguLLguKXguYjguLLguKrguLjguJTguILguK3guIfguITguLjguJMifQ%3D%3D!"
        )
      ),
"en-PSEUDO": createLanguage(
        require.resolveWeak("./translations.json-en-PSEUDO-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXaGF0IjoiW8W04bipxIPEg8SD4bmvXSIsIkVudGVyIEtleXdvcmRzIjoiW8OL4LiB4Li14LmJ4bmv4bq94bq94bq9xZkg4biw4bq94bq94bq9w73DvcO9xbXDtsO2w7bFmcaMxaFdIiwiQ2xpY2sgaGVyZSB0byBzZWUgeW91ciByZWNlbnQgJiBzYXZlZCBzZWFyY2hlcyI6IlvDh8aaw6zDrMOsw6fEtyDhuKnhur3hur3hur3FmeG6veG6veG6vSDhua%2FDtsO2w7YgxaHhur3hur3hur3hur3hur3hur0gw73DvcO9w7bDtsO2x5rHmseaxZkgxZnhur3hur3hur3Dp%2BG6veG6veG6veC4geC4teC5ieG5ryAmIMWhxIPEg8SD4bm94bq94bq94bq9xowgxaHhur3hur3hur3Eg8SDxIPFmcOn4bip4bq94bq94bq9xaFdIiwiQ2xpY2sgaGVyZSB0byBzZWUgeW91ciByZWNlbnQgc2VhcmNoZXMiOiJbw4fGmsOsw6zDrMOnxLcg4bip4bq94bq94bq9xZnhur3hur3hur0g4bmvw7bDtsO2IMWh4bq94bq94bq94bq94bq94bq9IMO9w73DvcO2w7bDtseax5rHmsWZIMWZ4bq94bq94bq9w6fhur3hur3hur3guIHguLXguYnhua8gxaHhur3hur3hur3Eg8SDxIPFmcOn4bip4bq94bq94bq9xaFdIn0%3D!"),
        () => import(
          /* webpackChunkName: "en-PSEUDO-translations" */
          "./translations.json-en-PSEUDO-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXaGF0IjoiW8W04bipxIPEg8SD4bmvXSIsIkVudGVyIEtleXdvcmRzIjoiW8OL4LiB4Li14LmJ4bmv4bq94bq94bq9xZkg4biw4bq94bq94bq9w73DvcO9xbXDtsO2w7bFmcaMxaFdIiwiQ2xpY2sgaGVyZSB0byBzZWUgeW91ciByZWNlbnQgJiBzYXZlZCBzZWFyY2hlcyI6IlvDh8aaw6zDrMOsw6fEtyDhuKnhur3hur3hur3FmeG6veG6veG6vSDhua%2FDtsO2w7YgxaHhur3hur3hur3hur3hur3hur0gw73DvcO9w7bDtsO2x5rHmseaxZkgxZnhur3hur3hur3Dp%2BG6veG6veG6veC4geC4teC5ieG5ryAmIMWhxIPEg8SD4bm94bq94bq94bq9xowgxaHhur3hur3hur3Eg8SDxIPFmcOn4bip4bq94bq94bq9xaFdIiwiQ2xpY2sgaGVyZSB0byBzZWUgeW91ciByZWNlbnQgc2VhcmNoZXMiOiJbw4fGmsOsw6zDrMOnxLcg4bip4bq94bq94bq9xZnhur3hur3hur0g4bmvw7bDtsO2IMWh4bq94bq94bq94bq94bq94bq9IMO9w73DvcO2w7bDtseax5rHmsWZIMWZ4bq94bq94bq9w6fhur3hur3hur3guIHguLXguYnhua8gxaHhur3hur3hur3Eg8SDxIPFmcOn4bip4bq94bq94bq9xaFdIn0%3D!"
        )
      )
      });
  
      export { translations as default };
    