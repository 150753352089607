import { assignInlineVars } from '@vanilla-extract/dynamic';
import { useTranslations } from '@vocab/react';
import {
  Box,
  HiddenVisually,
  IconChevron,
  Text,
  useResponsiveValue,
} from 'braid-design-system';
import {
  useCallback,
  useRef,
  useState,
  type FormEvent,
  type MouseEvent,
  useEffect,
  useMemo,
  type ReactNode,
} from 'react';

import { useFeatureFlagRefineBarV2Experiment } from 'src/store/featureFlags/hooks.ts';
import { useDispatch, useSelector } from 'src/store/react';
import { selectRefinement } from 'src/store/search';
import {
  selectFeatureFlag,
  selectIsFiltersExpanded,
  selectIsHomePage,
} from 'src/store/selectors';

import { DynamicPillV2 } from '../DynamicPillV2/DynamicPillV2';

import translations from './.vocab';
import { useClassificationsField } from './Classifications/useClassificationsField';
import { useCombinedFields } from './CombinedFields/useCombinedFields';
import { useDateListedField } from './DateListed/useDateListedField';
import MoreOptions from './MoreOptions/MoreOptions';
import { useSalaryRangeField } from './SalaryRange/useSalaryRangeField';
import { Status } from './Status/Status';
import { useWorkArrangementsField } from './WorkArrangements/useWorkArrangementsField';
import { useWorkTypesField } from './WorkTypes/useWorkTypesField';

import * as styles from './RefineBarV2.css';

interface RefineBarProps {
  closeFilterOnScroll?: boolean;
  activeFieldId: string | null;
  setActiveFieldId: React.Dispatch<
    React.SetStateAction<RefineBarProps['activeFieldId']>
  >;
  updateActiveField: (fieldId: string, { left, width }: DOMRect) => void;
  panelPos: number;
  isSearchBarMinimised?: boolean;
}

export interface RefineField {
  id: string;
  ariaLabel: string;
  filterComponent: () => JSX.Element;
  label?: React.ReactNode | string;
  summarySentence?: string;
  status?: { label: string };
  isSelected: boolean;
  totalSelected?: number;
}

export const RefineBarV2 = (props: RefineBarProps) => {
  const {
    closeFilterOnScroll = false,
    activeFieldId,
    setActiveFieldId,
    updateActiveField,
    panelPos,
    isSearchBarMinimised,
  } = props;
  const { t } = useTranslations(translations);
  const dispatch = useDispatch();
  const containerRef = useRef<HTMLElement>(null);
  const isHomePage = useSelector(selectIsHomePage);
  const isMobile = useResponsiveValue()({
    mobile: true,
    tablet: false,
  });

  const [isMouseInPopUp, setMouseInPopup] = useState(false);
  const [trackPageScroll, setTrackPageScroll] = useState(false);

  const closeFilter = useCallback(() => {
    if (isMouseInPopUp) {
      // Only keep the pop up open if the user's cursor is hovering over it
      return;
    }

    setActiveFieldId(null);

    if (trackPageScroll) {
      setTrackPageScroll(false);
    }
  }, [trackPageScroll, isMouseInPopUp, setActiveFieldId]);

  useEffect(() => {
    // When closeFilterOnScroll, close the open pop up when the user scrolls on the page
    if (!closeFilterOnScroll) {
      return;
    }

    if (trackPageScroll) {
      window.addEventListener('scroll', closeFilter);
    }

    return () => {
      window.removeEventListener('scroll', closeFilter);
    };
  }, [trackPageScroll, closeFilterOnScroll, closeFilter]);

  const handleSelection = useCallback(
    (type: string) => {
      // only 'DateListed' dropdown will be closed
      // after selection for Desktop view only & not in combined fields filter
      const notValidTypes = ['DateListed'];

      if (notValidTypes.includes(type) && !isSearchBarMinimised && !isMobile) {
        setActiveFieldId(null);
        return;
      }

      dispatch(selectRefinement());
    },
    [dispatch, setActiveFieldId, isMobile, isSearchBarMinimised],
  );

  const remoteSearchFilterFeatureToggle = useSelector(
    selectFeatureFlag('remoteSearchFilter'),
  );

  const { dynamicPillsV2, refineBarV2 } = useFeatureFlagRefineBarV2Experiment();

  const workArrangementsField = useWorkArrangementsField(handleSelection);
  const salaryRangeField = useSalaryRangeField(handleSelection);
  const classificationsField = useClassificationsField(handleSelection);

  const workTypesField = useWorkTypesField(handleSelection);
  const dateListedField = useDateListedField(handleSelection);
  const combinedFields = useCombinedFields(handleSelection);

  const fields: RefineField[] = useMemo(() => {
    const separatedFields = [
      workTypesField,
      ...(remoteSearchFilterFeatureToggle ? [workArrangementsField] : []),
      salaryRangeField,
      classificationsField,
      dateListedField,
    ];

    if (isMobile && isHomePage) {
      return [];
    } else if (isMobile && (dynamicPillsV2 || refineBarV2)) {
      return [workTypesField, classificationsField, combinedFields];
    } else if (isSearchBarMinimised && refineBarV2) {
      return [combinedFields];
    }
    return separatedFields;
  }, [
    workTypesField,
    remoteSearchFilterFeatureToggle,
    workArrangementsField,
    salaryRangeField,
    classificationsField,
    dateListedField,
    isMobile,
    isHomePage,
    dynamicPillsV2,
    refineBarV2,
    isSearchBarMinimised,
    combinedFields,
  ]);

  // `fields` array shown above is recreated whenever a field object changes.
  // `memoisedFieldComponents` will detects these changes in the `fields` array and updates accordingly.
  const memoisedFieldComponents = useMemo(
    () =>
      fields.reduce<Record<string, ReactNode>>((acc, field) => {
        acc[field.id] = field.filterComponent();
        return acc;
      }, {}),
    [fields],
  );

  const isAnyFieldActive = fields.some(({ id }) => id === activeFieldId);
  const fieldName = 'RefineBar__Toggle';
  const filtersExpanded = useSelector(selectIsFiltersExpanded);

  return (
    <>
      <Box
        display={{
          mobile: 'none',
          tablet: isHomePage && !filtersExpanded ? 'flex' : 'none',
        }}
        justifyContent="flexEnd"
        alignItems="flexEnd"
        flexDirection="column"
        width="full"
        paddingY={styles.summaryLabelVerticalPadding}
        aria-hidden={filtersExpanded}
      >
        <MoreOptions />
      </Box>
      <Box
        component="aside"
        role="navigation"
        display={{
          mobile: !isHomePage || filtersExpanded ? 'flex' : 'none',
          tablet: !isHomePage || filtersExpanded ? 'flex' : 'none',
        }}
        aria-hidden={!filtersExpanded}
        aria-labelledby="refine-your-search"
        className={{
          [styles.root]: true,
          [styles.rootIsExpanded]: filtersExpanded,
        }}
        data-automation="refineBar"
        style={assignInlineVars({
          [styles.panelPosLeft]: `${panelPos}px`,
        })}
      >
        <HiddenVisually>
          <h1 id="refine-your-search">{t('Refine your search')}</h1>
        </HiddenVisually>

        {/* Filter pills */}
        <Box
          className={{
            // TODO: Styling for dynamic pills
            // [styles.summary]: !dynamicPillsV2,
            [styles.summaryIsExpanded]: filtersExpanded,
          }}
        >
          <Box
            ref={containerRef}
            position="relative"
            display="flex"
            alignItems="center"
            className={styles.pillsContainer}
          >
            {fields.map((field, index) => {
              const isActiveOrSelected =
                activeFieldId === field.id || field.isSelected;
              return (
                <Box position="relative" key={field.id}>
                  <Box
                    component="input"
                    id={`RefineBar--${field.id}`}
                    className={styles.toggle}
                    name={fieldName}
                    type="radio"
                    role="button"
                    aria-expanded={filtersExpanded}
                    aria-label={t('refine by {field}', {
                      field: field.ariaLabel,
                    })}
                    data-automation={`toggle${field.id}Button`}
                    checked={activeFieldId === field.id}
                    onChange={(event: FormEvent<HTMLInputElement>) => {
                      updateActiveField(
                        field.id,
                        event.currentTarget.getBoundingClientRect(),
                      );
                    }}
                  />

                  <Box
                    component="label"
                    className={{
                      [styles.summaryLabel]: true,
                      [styles.summaryLabelIsNotRefining]:
                        isAnyFieldActive && activeFieldId !== field.id,
                      [styles.pillButton]: true,
                    }}
                    marginRight={
                      index < fields.length - 1 ? 'xxsmall' : undefined
                    }
                    htmlFor={`RefineBar--${field.id}`}
                    onClick={(event: MouseEvent<HTMLInputElement>) => {
                      updateActiveField(
                        field.id,
                        event.currentTarget.getBoundingClientRect(),
                      );

                      if (closeFilterOnScroll) {
                        setTrackPageScroll(true);
                      }
                    }}
                    data-automation={`toggle${field.id}Panel`}
                    background={
                      isActiveOrSelected ? 'customLight' : 'customDark'
                    }
                  >
                    <Box
                      component="span"
                      position="absolute"
                      borderRadius="full"
                      inset={0}
                      opacity={isActiveOrSelected ? undefined : 0}
                      transition="fast"
                      background={isActiveOrSelected ? 'surface' : 'body'}
                    />
                    <HiddenVisually>
                      {t('Show {field} refinements.', {
                        field: field.ariaLabel,
                      })}
                    </HiddenVisually>
                    <Box display="flex" position="relative" alignItems="center">
                      <Text
                        size={isMobile ? 'small' : undefined}
                        tone={isActiveOrSelected ? 'neutral' : undefined}
                        maxLines={1}
                        baseline={false}
                      >
                        {field.summarySentence && (
                          <span aria-hidden>{`${field.summarySentence} `}</span>
                        )}
                        {field.label}
                      </Text>
                      <Box className={styles.pillIcon}>
                        <Text
                          baseline={false}
                          size="xsmall"
                          icon={
                            <IconChevron
                              direction={
                                activeFieldId === field.id ? 'up' : 'down'
                              }
                            />
                          }
                        />
                      </Box>
                    </Box>
                    {field.status && (
                      <Box className={styles.status}>
                        <Status
                          label={field.status.label}
                          isActive={activeFieldId === field.id}
                          isSelected={field.isSelected}
                        />
                      </Box>
                    )}
                  </Box>
                </Box>
              );
            })}

            {/* Must follow individual refinement toggles to layer on top */}
            {/* Overlay to close filter panel */}
            <Box
              component="label"
              display={isAnyFieldActive ? 'block' : 'none'}
              position="absolute"
              inset={0}
              zIndex={1}
              htmlFor="RefineBar--Done"
              onClick={() => setActiveFieldId(null)}
              data-automation="refineBarToggleClose"
            >
              <HiddenVisually>{t('Hide refinements')}</HiddenVisually>
            </Box>
          </Box>
        </Box>

        {/* Must follow the refine summary to layer on top */}
        <Box
          component="input"
          id="RefineBar--Done"
          opacity={0}
          position="absolute"
          cursor="pointer"
          type="radio"
          name={fieldName}
          onChange={() => setActiveFieldId(null)}
        />
        <Box
          component="label"
          position="fixed"
          inset={0}
          zIndex={1}
          display={isAnyFieldActive ? 'block' : 'none'}
          htmlFor="RefineBar--Done"
          data-automation="refineBarClickOutClose"
          onClick={() => {
            setActiveFieldId(null);

            if (closeFilterOnScroll) {
              setTrackPageScroll(false);
            }
          }}
        >
          <HiddenVisually>{t('Hide refinements')}</HiddenVisually>
        </Box>

        {/* Filter pop ups */}
        {/* Must follow the click outside done label to layer on top */}
        {fields.map((field) => {
          const isScrollableField =
            field.id === 'Classification' || field.id === 'CombinedFields';
          const fieldComponent = memoisedFieldComponents[field.id];
          return (
            <Box
              key={field.id}
              background="surface"
              boxShadow="medium"
              display={activeFieldId === field.id ? 'block' : 'none'}
              id={`${field.id}Panel`}
              className={
                isScrollableField
                  ? styles.refinementPanel
                  : styles.refinementPanelNotScrollable
              }
              onMouseEnter={() => {
                setMouseInPopup(true);
              }}
              onMouseLeave={() => {
                setMouseInPopup(false);
              }}
            >
              <Box
                position="absolute"
                inset={0}
                pointerEvents="none"
                borderRadius="xlarge"
                boxShadow="borderNeutralLight"
              />
              {fieldComponent}
            </Box>
          );
        })}
      </Box>

      {refineBarV2 || dynamicPillsV2 ? <DynamicPillV2 /> : null}
    </>
  );
};
