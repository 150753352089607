import { KeywordAutoSuggest } from '@seek/discover-ui';
import { useHubble } from '@seek/hubble';
import { useTranslations } from '@vocab/react';
import { Box, IconSearch } from 'braid-design-system';
import {
  useCallback,
  useEffect,
  useRef,
  useState,
  type ComponentProps,
  type KeyboardEvent,
} from 'react';

import {
  SavedRecentSearches,
  type SavedRecentSearchesHandle,
} from 'src/components/Search/SearchBar/SavedRecentSearches/SavedRecentSearches';
import { getRefineSearchQuery } from 'src/components/Search/utils/utils';
import { useAnalyticsFacade } from 'src/modules/AnalyticsFacade';
import { useSavedAndRecentSearchesFeatureFlag } from 'src/store/featureFlags/hooks';
import { useDispatch, useSelector } from 'src/store/react';
import { clearResultsMobileView } from 'src/store/results';
import {
  updateBaseKeywordParam,
  updateKeywordsField as _updateKeywordsField,
} from 'src/store/search';
import {
  selectBaseKeywords,
  selectSearchQuery,
} from 'src/store/search/selectors';
import {
  selectFeatureFlag,
  selectIsSrp,
  selectKeywordField,
} from 'src/store/selectors';
import { useMelwaysCountry } from 'src/utils/melwaysHelper';

interface KeywordFieldProps {
  onSuggestionSelected: () => void;
  onInputBlur: () => void;
  onClear: () => void;
}

export const KEYWORD_INPUT_FIELD_ID = 'keywords-input';

import translations from './.vocab';

export const MobileKeywordField = ({
  onSuggestionSelected,
  onInputBlur,
  onClear,
}: KeywordFieldProps) => {
  const { t } = useTranslations(translations);
  const analyticsFacade = useAnalyticsFacade();
  const dispatch = useDispatch();
  const keywordsField = useSelector(selectKeywordField);
  const country = useMelwaysCountry();
  const [keywordsFieldLocalState, setKeywordFieldLocalState] =
    useState(keywordsField);
  const baseKeywords = useSelector(selectBaseKeywords);
  const isSRP = useSelector(selectIsSrp);
  const query = getRefineSearchQuery(useSelector(selectSearchQuery));
  const [displaySavedAndRecentSearches, setDisplaySavedAndRecentSearches] =
    useState(false);
  const showSavedAndRecentSearchesInSearch =
    useSavedAndRecentSearchesFeatureFlag();

  const hubble = useHubble();

  const visitorId = hubble.visitorId();
  const isKeywordAutosuggestV2Enabled = useSelector(
    selectFeatureFlag('keywordAutosuggestV2'),
  );

  const shouldBlurOnFocusRef = useRef(false);
  const shouldBlurOnSuggestionSelectedRef = useRef(false);
  const keywordFieldRef = useRef<HTMLInputElement | null>(null);

  useEffect(() => {
    if (!isSRP) {
      dispatch(updateBaseKeywordParam(''));
    } else if (keywordsField && !baseKeywords) {
      dispatch(updateBaseKeywordParam(keywordsField));
    }
  }, [baseKeywords, dispatch, isSRP, keywordsField]);

  useEffect(() => {
    if (keywordsField !== keywordsFieldLocalState) {
      setKeywordFieldLocalState(keywordsField);
    }
  }, [keywordsField, keywordsFieldLocalState]);

  const updateKeywordsField = useCallback(
    (text: string) => {
      setKeywordFieldLocalState(text);
      dispatch(_updateKeywordsField(text));

      dispatch(updateBaseKeywordParam(text));

      if (showSavedAndRecentSearchesInSearch) {
        setDisplaySavedAndRecentSearches(!text);
      }
    },
    [dispatch, showSavedAndRecentSearchesInSearch],
  );

  const onKeywordsChange = useCallback(
    (
      ...args: Parameters<ComponentProps<typeof KeywordAutoSuggest>['onChange']>
    ) => {
      const [suggestion] = args;
      analyticsFacade.keywordChanged(
        suggestion.text,
        suggestion.isSuggestion,
        suggestion.previousText ?? '',
        suggestion.keywordListCount,
        suggestion.keywordRank,
        suggestion.suggestionMetadata,
      );
      updateKeywordsField(suggestion.text);

      if (suggestion.isSuggestion) {
        onSuggestionSelected();

        if (keywordFieldRef.current) {
          shouldBlurOnSuggestionSelectedRef.current = true;
          keywordFieldRef.current.blur();
        }
      }
    },
    [analyticsFacade, onSuggestionSelected, updateKeywordsField],
  );

  const onKeywordsBlur = () => {
    if (showSavedAndRecentSearchesInSearch && displaySavedAndRecentSearches) {
      // We're adding a small timeout so we don't close the saved/recent menu automatically when the user clicks it
      setTimeout(() => {
        setDisplaySavedAndRecentSearches(false);
      }, 150);
    } else {
      const { keywords = '' } = query;

      if (
        keywordsField !== keywords &&
        !shouldBlurOnSuggestionSelectedRef.current
      ) {
        updateKeywordsField(keywordsField);
        onInputBlur();
      }
    }

    if (shouldBlurOnSuggestionSelectedRef.current) {
      shouldBlurOnSuggestionSelectedRef.current = false;
    }
  };

  const onKeywordsFocus = () => {
    if (showSavedAndRecentSearchesInSearch) {
      setDisplaySavedAndRecentSearches(!keywordsFieldLocalState);
    }

    if (keywordFieldRef.current && shouldBlurOnFocusRef.current) {
      keywordFieldRef.current.blur();
      shouldBlurOnFocusRef.current = false;
    }
  };

  const onKeywordsClear = () => {
    shouldBlurOnFocusRef.current = true;
    updateKeywordsField('');
    onInputBlur();
    dispatch(clearResultsMobileView());
    onClear();
  };

  const onKeyDown = (event: KeyboardEvent) => {
    if (showSavedAndRecentSearchesInSearch && displaySavedAndRecentSearches) {
      const targetKey = event.key;

      switch (targetKey) {
        case 'ArrowDown':
          savedRecentRef.current?.selectNext();
          event.preventDefault(); // Prevent the page from scrolling
          break;
        case 'ArrowUp':
          savedRecentRef.current?.selectPrevious();
          event.preventDefault(); // Prevent the page from scrolling
          break;
        case 'Enter':
          savedRecentRef.current?.selectItem();
          event.preventDefault(); // Prevent the submit event from firing
          setDisplaySavedAndRecentSearches(false);
          break;
        case 'Escape':
          setDisplaySavedAndRecentSearches(false);
          break;
      }
    }
  };

  const savedRecentRef = useRef<SavedRecentSearchesHandle | null>(null);
  return (
    <Box position="relative" onKeyDown={onKeyDown}>
      <KeywordAutoSuggest
        ref={keywordFieldRef}
        keywordAutosuggestV2={isKeywordAutosuggestV2Enabled}
        visitorId={visitorId}
        keyword={{ text: keywordsFieldLocalState }}
        id={KEYWORD_INPUT_FIELD_ID}
        onChange={onKeywordsChange}
        onBlur={onKeywordsBlur}
        onClear={onKeywordsClear}
        onFocus={onKeywordsFocus}
        country={country}
        label=""
        reserveMessageSpace={false}
        showMobileBackdrop={true}
        name="keywords"
        icon={<IconSearch />}
        scrollToTopOnMobile={false}
        placeholder={t('Enter Keywords')}
      />

      {showSavedAndRecentSearchesInSearch && displaySavedAndRecentSearches ? (
        <SavedRecentSearches ref={savedRecentRef} />
      ) : null}
    </Box>
  );
};
