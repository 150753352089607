import type { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import type { Maybe } from '@seek/ca-graphql-schema/types';

import {
  JobDetailsDocument,
  JobDetailsPersonalisedDocument,
  type JobDetailsPersonalisedQueryVariables,
  type JobDetailsQueryVariables,
} from 'src/graphql/graphql';
import { logger } from 'src/modules/logger';
import {
  JobDetailsGraphQLError,
  PageNotFoundError,
} from 'src/utils/customErrors';

import type { AnalyticsFacade } from '../../modules/AnalyticsFacade';
import {
  GET_LMIS_ERROR,
  GET_LMIS_SUCCESS,
  pageKeys,
  RESET_JDP_LMIS,
} from '../lmis/types';
import { SUBMIT_SEARCH } from '../search/types';
import type { TypedAction, TypedThunkAction } from '../types';

import {
  CIS_IMPRESSION,
  CLEAR_JOB_DETAILS,
  JOB_DETAILS_FETCH_BEGIN,
  JOB_DETAILS_FETCH_FAILURE,
  JOB_DETAILS_FETCH_SUCCESS,
  JOB_DETAILS_PAGE_LOADED,
  JOB_DETAILS_PERSONALISED_FETCH_SUCCESS,
  JOB_DETAILS_SET_CORRELATION_ID,
  RESET_JOB_DETAILS,
  type Analytics$CISImpression,
  type CisImpressionAction,
  type ClearJobDetailsAction,
  type JobDetails,
  type JobDetailsPageLoadedAction,
  type JobDetailsSetCorrelationIdAction,
  type JobDetailsState,
  type PersonalisedJobDetails,
} from './types';
import { mapToJobDetailsTrackingInput } from './utils';

interface FetchCommonArgs {
  apolloClient: ApolloClient<NormalizedCacheObject>;
  xRealIp: string | undefined;
}

interface FetchUnifiedJobArgs
  extends FetchCommonArgs,
    Pick<
      JobDetailsQueryVariables,
      'jobId' | 'sessionId' | 'zone' | 'locale' | 'languageCode' | 'countryCode'
    > {
  analyticsFacade: AnalyticsFacade;
  shouldThrowError?: boolean;
}

interface FetchUnifiedJobPersonalisedArgs
  extends FetchCommonArgs,
    Pick<
      JobDetailsPersonalisedQueryVariables,
      'id' | 'languageCode' | 'locale' | 'zone'
    > {
  sessionId?: string;
}

export const initialState = {
  fraudReport: {},
  jobPending: false,
  result: null,
  pageLoadedCount: 0,
  personalised: null,
  xRealIp: undefined,
};

export default function reducer(
  state: JobDetailsState = initialState,
  action: TypedAction,
): JobDetailsState {
  switch (action.type) {
    case SUBMIT_SEARCH: {
      return {
        ...state,
        personalised: null,
        result: null,
      };
    }

    case JOB_DETAILS_PAGE_LOADED: {
      const { pageLoadedCount } = state;
      return {
        ...state,
        pageLoadedCount: pageLoadedCount + 1,
      };
    }

    case JOB_DETAILS_FETCH_FAILURE: {
      const { error } = action.payload;
      return {
        ...state,
        jobPending: false,
        error: Boolean(error),
      };
    }

    case JOB_DETAILS_FETCH_BEGIN: {
      return {
        ...state,
        jobPending: true,
      };
    }

    case JOB_DETAILS_FETCH_SUCCESS: {
      const { result, xRealIp } = action.payload;

      return {
        ...state,
        fraudReport: {},
        jobPending: false,
        error: false,
        result,
        xRealIp,
      };
    }

    case JOB_DETAILS_PERSONALISED_FETCH_SUCCESS: {
      const { personalised, xRealIp } = action.payload;
      return {
        ...state,
        personalised,
        xRealIp,
      };
    }

    case RESET_JOB_DETAILS: {
      return {
        ...state,
        jobPending: false,
        personalised: null,
        result: null,
      };
    }

    case CLEAR_JOB_DETAILS: {
      return {
        ...state,
        result: null,
      };
    }

    case JOB_DETAILS_SET_CORRELATION_ID: {
      return {
        ...state,
        jobDetailsViewedCorrelationId: action.payload,
      };
    }

    default: {
      return state;
    }
  }
}

export const clearJobDetails = (): ClearJobDetailsAction => ({
  type: CLEAR_JOB_DETAILS,
});

export const fetchUnifiedJob =
  ({
    analyticsFacade,
    apolloClient,
    jobId,
    sessionId = '',
    zone = 'anz-1',
    locale,
    languageCode,
    shouldThrowError = true,
    countryCode,
    xRealIp,
  }: FetchUnifiedJobArgs): TypedThunkAction =>
  (dispatch, getState) => {
    dispatch({ type: RESET_JOB_DETAILS });
    dispatch({ type: RESET_JDP_LMIS });
    dispatch({ type: JOB_DETAILS_FETCH_BEGIN });
    const state = getState();
    const {
      jobdetails: { jobDetailsViewedCorrelationId },
      user: { timezone },
    } = state;

    return apolloClient
      .query({
        query: JobDetailsDocument,
        variables: {
          jobId,
          jobDetailsViewedCorrelationId: jobDetailsViewedCorrelationId || '',
          sessionId,
          zone,
          locale,
          languageCode,
          countryCode,
          timezone,
        },
        errorPolicy: 'all',
      })
      .then(({ data, errors }) => {
        const jobDetails = data?.jobDetails as unknown as Maybe<JobDetails>;

        if (!jobDetails && errors) {
          logger.error(
            { err: errors[0], input: { jobId, zone, locale } },
            'JobDetails GraphQL Error',
          );
          throw new JobDetailsGraphQLError({
            message: 'Failed to load job details',
            customData: { errors },
          });
        }

        if (errors) {
          logger.error(
            { err: errors[0], input: { jobId, zone, locale } },
            'JobDetails GraphQL Partial Failure',
          );
        }

        if (!jobDetails) {
          throw new PageNotFoundError('No JobDetails found');
        }

        if (jobDetails) {
          analyticsFacade.jobDetailsFetched(jobDetails);

          dispatch({
            type: JOB_DETAILS_FETCH_SUCCESS,
            payload: {
              result: jobDetails,
              xRealIp,
            },
          });

          return dispatch({
            type: GET_LMIS_SUCCESS,
            payload: {
              content: jobDetails?.learningInsights?.content,
              lmisSnippet: jobDetails?.learningInsights?.analytics,
              key: pageKeys.JDP,
            },
          });
        }

        return dispatch({ type: RESET_JOB_DETAILS });
      })
      .catch((error: Error) => {
        dispatch({
          type: JOB_DETAILS_FETCH_FAILURE,
          payload: { error },
        });
        dispatch({
          type: GET_LMIS_ERROR,
          payload: {
            error,
            key: pageKeys.JDP,
          },
        });
        logger.error(error, 'JobDetails Fetch Error');
        // Will need to `throw error` for Single Job Detail Page only
        if (shouldThrowError) {
          throw error;
        }
      });
  };

export const fetchUnifiedJobPersonalised =
  ({
    apolloClient,
    id: jobId,
    sessionId = '',
    languageCode,
    locale,
    zone,
    xRealIp,
  }: FetchUnifiedJobPersonalisedArgs): TypedThunkAction =>
  (dispatch, getState) => {
    const {
      jobdetails: { jobDetailsViewedCorrelationId },
      user: { timezone },
    } = getState();
    const trackingInput = mapToJobDetailsTrackingInput({
      jobDetailsViewedCorrelationId,
      sessionId,
    });

    return apolloClient
      .query({
        query: JobDetailsPersonalisedDocument,
        variables: {
          id: jobId,
          tracking: trackingInput,
          languageCode,
          locale,
          timezone,
          zone,
        },
        fetchPolicy: 'network-only',
      })
      .then(({ data: { jobDetails } }) => {
        const personalised = jobDetails?.personalised as PersonalisedJobDetails;

        if (personalised) {
          dispatch({
            type: JOB_DETAILS_PERSONALISED_FETCH_SUCCESS,
            payload: {
              personalised,
              jobDetailsViewedCorrelationId,
              xRealIp,
            },
          });
        }
      })
      .catch((error: Error) => {
        logger.error(
          {
            err: error,
            input: {
              id: jobId,
              tracking: trackingInput,
            },
          },
          'ChaliceJobDetailsPersonalised',
        );
      });
  };

export const jobDetailsPageLoaded = (): JobDetailsPageLoadedAction => ({
  type: JOB_DETAILS_PAGE_LOADED,
});

export const setJobDetailsCorrelationId = (
  id: string,
): JobDetailsSetCorrelationIdAction => ({
  type: JOB_DETAILS_SET_CORRELATION_ID,
  payload: id,
});

export const cisImpression = (
  impression: Analytics$CISImpression,
): CisImpressionAction => ({
  type: CIS_IMPRESSION,
  payload: impression,
});
