import { ApolloProvider } from '@apollo/client';
import { Hubble, HubbleProvider } from '@seek/hubble';
import { MelwaysProvider } from '@seek/melways-react';
import { metrics } from '@seek/metrics-js';
import { createAnalytics } from '@seek/seek-jobs-analytics';
import { parse } from 'cookie';
import { hydrateRoot } from 'react-dom/client';
import { HelmetProvider } from 'react-helmet-async';
import { Provider } from 'react-redux';
import {
  createBrowserRouter,
  matchRoutes,
  type RouteObject,
} from 'react-router';
import { RouterProvider } from 'react-router/dom';

import { createRouteLoaderFunction } from 'src/client/createRouteLoaderFunction';
import { urlLocationToRouterRequest } from 'src/client/locationTransforms';
import {
  appName,
  environment,
  isProduction,
  siteSection,
  solTrackingEndpoint,
  version,
} from 'src/config';
import { AppConfigProviderWrapper } from 'src/config/AppConfigProviderWrapper';
import {
  AnalyticsContext,
  BrowserAnalyticsFacade,
  UniversalAnalyticsFacade,
} from 'src/modules/AnalyticsFacade';
import { getClient } from 'src/modules/graphql';
import { setHubbleLoginId } from 'src/modules/hubble';
import createStore from 'src/store/createStore';
import { useSelector } from 'src/store/react';
import { selectPathname } from 'src/store/selectors';
import { getSearchParams } from 'src/utils/urlParams';

import getCountryFromZone from './config/utils/getCountryFromZone';
import { MetricsContextProvider } from './modules/MetricsTimer/MetricsTimerContext';
import appRoutesConfig from './routes';
import createTestHeaderApolloLink from './utils/createTestHeaderApolloLink';
import {
  getTestScope,
  isTestAgent,
} from './utils/productionTesting/productionTesting';

const appConfig = window.SEEK_APP_CONFIG;

const { brand, zone, site, language } = appConfig;

const MelwaysProviderWrapper = ({
  children,
}: {
  children: React.ReactNode;
}) => (
  <MelwaysProvider.Client site={site} path={useSelector(selectPathname)}>
    {children}
  </MelwaysProvider.Client>
);

export default (element: Element | null): void => {
  const dataLayer = window.SK_DL || {};
  const serverState = window.SEEK_REDUX_DATA;
  const store = createStore({
    ...serverState,
    location: {
      ...serverState?.location,
      // This is a workaround to ensure that the hash is stored in the store on the client side ..
      // .. since the server side, which initialises the store, does not have access to the hash.
      hash: window.location.hash,
    },
    user: {
      ...serverState?.user,
      userAgent: window.navigator.userAgent,
    },
  });
  const state = store.getState();
  const country = getCountryFromZone(zone);

  const testHeaderApolloLink = createTestHeaderApolloLink(store);
  const apolloClient = getClient({
    brand,
    country,
    links: testHeaderApolloLink ? [testHeaderApolloLink] : [],
    cacheData: window.SEEK_APOLLO_DATA,
    cookies: parse(document.cookie),
    queryParams: getSearchParams(),
  });

  const testHeaders = state.user.testHeaders;
  const isTestUser = isTestAgent(testHeaders);

  const hubble = new Hubble({
    appName,
    appVersion: version,
    brand,
    country,
    isProduction,
    endpoint: `${solTrackingEndpoint}/v1/events`,
    experience: 'candidate',
    ...(isTestUser
      ? {
          testRecord: 'true',
          testScope: getTestScope(testHeaders),
        }
      : {}),
  });

  const visitorId = hubble.visitorId();

  const analytics = createAnalytics(
    {
      environment,
      useMock: isTestUser,
    },
    {
      ...dataLayer,
      brand,
      siteCountry: country.toLowerCase() ?? '',
      siteLanguage: language,
      siteSection,
      solVisitorId: visitorId,
      zone,
    },
  );

  const analyticsFacade = new BrowserAnalyticsFacade({
    hubble,
    universalFacade: new UniversalAnalyticsFacade({
      seekJobsAnalytics: analytics,
    }),
  });

  setHubbleLoginId(hubble, store);

  if (window.SK_DL) {
    delete window.SK_DL;
  }

  metrics.addGlobalTags([
    `logged-in:${state.user.authenticated ? 'true' : 'false'}`,
    `serverAuthenticated:${state.user.serverAuthenticated ? 'true' : 'false'}`,
  ]);

  function renderApp(appRoutes: RouteObject[]): void {
    hydrateRoot(
      element!,
      <MetricsContextProvider>
        <Provider store={store} serverState={window.SEEK_REDUX_DATA}>
          <MelwaysProviderWrapper>
            <AppConfigProviderWrapper config={appConfig}>
              <AnalyticsContext.Provider value={analyticsFacade}>
                <ApolloProvider client={apolloClient}>
                  <HubbleProvider hubble={hubble}>
                    <HelmetProvider>
                      <RouterProvider router={createBrowserRouter(appRoutes)} />
                    </HelmetProvider>
                  </HubbleProvider>
                </ApolloProvider>
              </AnalyticsContext.Provider>
            </AppConfigProviderWrapper>
          </MelwaysProviderWrapper>
        </Provider>
      </MetricsContextProvider>,
    );
  }

  const loaderFunction = createRouteLoaderFunction({
    analyticsFacade,
    apolloClient,
    store,
    visitorId,
    hubble,
  });

  const routes = appRoutesConfig(site, loaderFunction);

  const { location } = state;
  const request = urlLocationToRouterRequest(location);
  const [matchedRoute] = matchRoutes(routes, location.pathname) || [];
  loaderFunction({
    request,
    params: matchedRoute.params ?? {},
    context: { initialLoad: true },
  });

  renderApp(routes);
};
