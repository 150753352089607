// Port over from srp-react
import { useEffect, useReducer, useRef, type Reducer } from 'react';

import useIsomorphicLayoutEffect from 'src/modules/useIsomorphicLayoutEffect';

interface State {
  status: 'idle' | 'starting' | 'animating';
  targetDimension?: number;
}
type Action =
  | {
      type: 'start';
      targetDimension: number;
    }
  | { type: 'started'; targetDimension: number }
  | { type: 'done' };

const initialState: State = { status: 'idle' };
const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case 'start': {
      return {
        status: 'starting',
        targetDimension: action.targetDimension,
      };
    }
    case 'started': {
      return {
        status: 'animating',
        targetDimension: action.targetDimension,
      };
    }
    case 'done': {
      return { status: 'idle' };
    }
    default: {
      return state;
    }
  }
};

export const ANIMATION_TIME_IN_MS = 400;

export interface UseAnimatedWrapperProps {
  changeKeys: readonly unknown[];
  changeDependsOn?: 'parent' | 'child';
  changeDimension: 'height' | 'width';
}

const useAnimatedWrapper = ({
  changeKeys,
  changeDependsOn,
  changeDimension,
}: UseAnimatedWrapperProps) => {
  const [{ status, targetDimension }, dispatch] = useReducer<
    Reducer<State, Action>
  >(reducer, initialState);
  const parentRef = useRef<HTMLDivElement>(null);
  const childRef = useRef<HTMLDivElement>(null);

  const cleanupTimeoutRef = useRef<NodeJS.Timeout | null>();

  const previousChildDimension =
    childRef.current?.getBoundingClientRect()[changeDimension];

  const cleanupAfterTransition = () => {
    cleanupTimeoutRef.current = null;
    dispatch({ type: 'done' });
  };

  // useLayoutEffect allows the below style updates to happen before the component is printed to the screen
  useIsomorphicLayoutEffect(() => {
    const parentElement = parentRef.current;
    const childElement = childRef.current;
    const newTargetElement =
      changeDependsOn === 'parent' ? parentElement : childElement;

    if (
      !parentElement ||
      !childElement ||
      !newTargetElement ||
      typeof previousChildDimension !== 'number'
    ) {
      return;
    }
    if (status === 'animating') {
      if (
        targetDimension ===
        childElement.getBoundingClientRect()[changeDimension]
      ) {
        // Already animating to the correct dimension
        return;
      }
      if (cleanupTimeoutRef.current) {
        clearTimeout(cleanupTimeoutRef.current);
        cleanupTimeoutRef.current = null;
      }
      // A new dimension has occured after animation
      requestAnimationFrame(() => {
        const newTargetDimension =
          newTargetElement.getBoundingClientRect()[changeDimension];

        dispatch({
          type: 'started',
          targetDimension: newTargetDimension,
        });
      });
    }

    if (status === 'idle') {
      if (
        previousChildDimension ===
        childElement.getBoundingClientRect()[changeDimension]
      ) {
        // No dimension difference to animate
        return;
      }
      dispatch({ type: 'start', targetDimension: previousChildDimension });

      requestAnimationFrame(() => {
        // Once the starting dimension has been printed, start animating to the new dimension
        const newTargetDimension =
          newTargetElement.getBoundingClientRect()[changeDimension];

        dispatch({
          type: 'started',
          targetDimension: newTargetDimension,
        });
      });
    }
    // Layout checks should only occur when parent consumer tells it to. Ignoring exhaustive props
  }, changeKeys);

  useEffect(() => {
    if (status === 'animating') {
      if (cleanupTimeoutRef.current) {
        clearTimeout(cleanupTimeoutRef.current);
        cleanupTimeoutRef.current = null;
      }
      // Use setTimeout to check for transition end as transitionend is not available in all supported browsers
      cleanupTimeoutRef.current = setTimeout(
        cleanupAfterTransition,
        ANIMATION_TIME_IN_MS + 100,
      );
    }
  }, [status]);

  const style = status !== 'idle' ? `${targetDimension}px` : 'auto';

  return { style, parentRef, childRef, status };
};

export default useAnimatedWrapper;
