import { Box, Stack } from 'braid-design-system';
import type { DebouncedFuncLeading } from 'lodash';
import { motion } from 'motion/react';

import { useSelector } from 'src/store/react';
import type { whereSuggestionSelected } from 'src/store/search';
import { selectIsHomePage, selectIsSrp } from 'src/store/selectors';

import { ANIMATION_DURATION } from '../../constants';
import { ExpandedSearchBar } from '../ExpandedSearchBar/ExpandedSearchBar';
import { SearchRefinementBar } from '../SearchRefinementBar/SearchRefinementBar';

import { MobileKeywordField } from './components/MobileKeywordField';
import { MobileWhereField } from './components/MobileWhereField';

export const MobileSearchBar = ({
  onWhereSuggestionSelected,
  onWhereInputBlur,
  debouncedSearch,
}: {
  onWhereSuggestionSelected: (
    payload: Parameters<typeof whereSuggestionSelected>[0],
  ) => void;
  onWhereInputBlur: () => void;
  debouncedSearch: DebouncedFuncLeading<(nextPathName?: string) => void>;
}) => {
  const isHomePage = useSelector(selectIsHomePage);
  const isSRP = useSelector(selectIsSrp);

  return (
    <Box data={{ automation: 'mobileSearchBar' }}>
      {isHomePage && (
        <ExpandedSearchBar
          onWhereSuggestionSelected={onWhereSuggestionSelected}
          onWhereInputBlur={onWhereInputBlur}
          debouncedSearch={debouncedSearch}
        />
      )}

      {isSRP && (
        <motion.div
          layout="position"
          layoutId="mobileSearchBar"
          key="mobile-searchBar2"
          transition={{
            duration: ANIMATION_DURATION,
            ease: 'linear',
          }}
          initial={{
            opacity: 1,
          }}
          animate={{
            opacity: 1,
          }}
          exit={{
            opacity: 0,
          }}
        >
          <Stack space="xsmall">
            <MobileKeywordField
              onSuggestionSelected={debouncedSearch}
              onInputBlur={debouncedSearch}
              onClear={debouncedSearch}
            />

            <motion.div
              layout="position"
              layoutId="mobileWhereField"
              key="mobile-whereField2"
              transition={{
                duration: ANIMATION_DURATION,
                ease: 'linear',
              }}
              initial={{
                opacity: 0,
              }}
              animate={{
                opacity: 1,
              }}
              exit={{
                opacity: 0,
              }}
            >
              <MobileWhereField
                onSuggestionSelected={onWhereSuggestionSelected}
                onWhereInputBlur={onWhereInputBlur}
                onClear={debouncedSearch}
              />
            </motion.div>
            <motion.div
              layout="position"
              layoutId="mobileRefineBar"
              key="mobile-refineBar2"
              transition={{
                duration: ANIMATION_DURATION,
                ease: 'linear',
              }}
              initial={{
                opacity: 1,
              }}
              animate={{
                opacity: 1,
              }}
              exit={{
                opacity: 0,
              }}
            >
              <SearchRefinementBar />
            </motion.div>
          </Stack>
        </motion.div>
      )}
    </Box>
  );
};
