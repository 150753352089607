import type { SalaryType } from '@seek/chalice-types';
import { useTranslations } from '@vocab/react';

import { getRefineSearchQuery } from 'src/components/Search/utils/utils';
import { useSearchSalaryRanges } from 'src/hooks/useSearchSalaryRanges';
import { useSelector } from 'src/store/react';
import { selectSearchQuery } from 'src/store/selectors';

import translations from '../.vocab';

import { getDisplayLabel } from './helper/getDisplayLabel';

export const useSalaryDisplayLabel = () => {
  const { t } = useTranslations(translations);
  const searchQuery = getRefineSearchQuery(useSelector(selectSearchQuery));
  const { searchSalaryRanges: data } = useSearchSalaryRanges();

  const defaultFrequency = data.find(
    (item) => item.frequency.isDefault,
  )?.frequency;
  const selectedSalaryFrequencyType =
    searchQuery.salarytype ?? (defaultFrequency?.type as SalaryType);
  const selectedSalaryFrequencyRanges = data.find(
    ({ frequency: { type } }) => type === selectedSalaryFrequencyType,
  )?.range;

  const [selectedSalaryRangeFromValue, selectedSalaryRangeToValue] =
    searchQuery.salaryrange?.split('-') ?? [];

  const selectedSalaryRangeFromIndex =
    selectedSalaryFrequencyRanges?.findIndex(
      ({ value }) => value === selectedSalaryRangeFromValue,
    ) ?? -1;
  const selectedSalaryRangeToIndex =
    selectedSalaryFrequencyRanges?.findIndex(
      ({ value }) => value === selectedSalaryRangeToValue,
    ) ?? -1;

  const defaultSalaryRangeFrom = selectedSalaryFrequencyRanges?.[0];
  const defaultSalaryRangeTo =
    selectedSalaryFrequencyRanges?.[selectedSalaryFrequencyRanges.length - 1];

  const activeSalaryRangeFromValue =
    selectedSalaryRangeFromValue > '0' ? selectedSalaryRangeFromValue : null;
  const activeSalaryRangeToValue =
    selectedSalaryRangeToValue > '0' ? selectedSalaryRangeToValue : null;

  const fromLabel =
    selectedSalaryFrequencyRanges?.[selectedSalaryRangeFromIndex]?.longLabel ??
    defaultSalaryRangeFrom?.longLabel;
  const toLabel =
    selectedSalaryFrequencyRanges?.[selectedSalaryRangeToIndex]?.longLabel ??
    defaultSalaryRangeTo?.longLabel;

  const displayLabel = getDisplayLabel({
    defaultDisplayLabel: t('Pay'),
    activeSalaryRangeFromValue,
    activeSalaryRangeToValue,
    fromLabel,
    toLabel,
  });

  return {
    displayLabel,
    activeSalaryRangeFromValue,
    activeSalaryRangeToValue,
  };
};
