import { set as setLocalStorage, get as getLocalStorage } from 'local-storage';

const TOOLTIP_STORAGE_KEY = 'tooltipDismissals';

export const useTooltipLocalStorage = (tooltipKey: string) => {
  const gellAllTooltips = () =>
    JSON.parse(getLocalStorage(TOOLTIP_STORAGE_KEY));

  const dismissTooltip = () => {
    setLocalStorage(
      TOOLTIP_STORAGE_KEY,
      JSON.stringify({ ...gellAllTooltips(), [tooltipKey]: true }),
    );
  };

  const isTooltipDismissed = () => gellAllTooltips()?.[tooltipKey] ?? null;

  return {
    isTooltipDismissed,
    dismissTooltip,
  };
};
